import { Alert, Box, Button, FormControl, Grid, InputAdornment, IconButton, TextField, Typography } from '@mui/material'
import React from 'react'
import MiniDrawer from '../../../components/drawer'
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdminPanelSettings, CurrencyRupee, SearchOutlined, HighlightOffRounded, HelpRounded, HelpOutlineRounded } from '@mui/icons-material';
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom';
import { countrylist } from "../../../components/Dropdown_constant"
import axios from 'axios'
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { header, headerformdata } from "../../../components/axiousheader"


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

// ----modal-----
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >                    
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function AddCompany() {
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    const [error, setError] = React.useState({
        companyname: "", ownershipDetails: "", firstname: "", industry: "", website: "",
        country: "", streetaddress1: "", streetaddress2: "", city: "", state: "", zipcode: "", phone: "",
        jobtitle: "", mobile: "", email: "", password: "",
    })
    const [ownership, setOwnership] = React.useState([])
    const [industry, setIndustry] = React.useState([])
    const [company, setCompany] = React.useState({
        companyname: "", parentcompany: "", industry: "", annualrevenue: "", employee: "", companynumber: "", website: "",
        country: "", streetaddress1: "", streetaddress2: "", city: "", state: "", zipcode: "", phone: "", fax: "", firstname: "", lastname: "",
        userrole: "Main Admin", jobtitle: "", adminphone: "", mobile: "", corpoateId: "", email: ""
    })
    let companyname, companyvalue;
    const handleCompanyDetails = (e) => {
        companyname = e.target.name;
        companyvalue = e.target.value;
        setCompany({ ...company, [companyname]: companyvalue })
        setError({ ...error, [companyname]: '' })
    }
    const [ownershipDetails, setOwnershipDetails] = React.useState('')
    const [values, setValues] = React.useState({
        showPassword: false,
        showRePassword: false
    });
    const [ownershipOrder, setOwnershipOrder] = React.useState({ type: -1, column: 'createdAt' })
    const [industryOrder, setindustryOrder] = React.useState({ type: -1, column: 'createdAt' })

    const handleClickShowRePassword = () => {
        setValues({
            ...values,
            showRePassword: !values.showRePassword,
        });
    };
    const auth = JSON.parse(localStorage.getItem("userData"))
    React.useEffect(() => {
        GetOwnerships()
        getIndustries()
        getSortOrder()
    }, [ownershipOrder.type, industryOrder.type])

    const validation = () => {
        if (!company.companyname) {
            setError({ companyname: "Company name can not be empty" })
            enqueueSnackbar("Company name can not be empty", { variant: 'error' })
            return false
        }
        if (!ownershipDetails) {
            setError({ ownershipDetails: "Please select ownership type" })
            enqueueSnackbar("Please select ownership type", { variant: 'error' })
            return false
        }

        if (!company.industry) {
            setError({ industry: "Please select industry type" })
            enqueueSnackbar("Please select industry type", { variant: 'error' })
            return false
        }
        if (!company.country) {
            setError({ country: "Please select country" })
            enqueueSnackbar("Please select country", { variant: 'error' })
            return false
        }
        if (!company.streetaddress1) {
            setError({ streetaddress1: "Address1 can not be empty" })
            enqueueSnackbar("Address1 can not be empty", { variant: 'error' })
            return false
        }
        if (!company.streetaddress2) {
            setError({ streetaddress2: "Address2 can not be empty" })
            enqueueSnackbar("Address2 can not be empty", { variant: 'error' })
            return false
        }
        if (!company.city) {
            setError({ city: "City can not be empty" })
            enqueueSnackbar("City can not be empty", { variant: 'error' })
            return false
        }
        if (!company.state) {
            setError({ state: "State can not be empty" })
            enqueueSnackbar("State can not be empty", { variant: 'error' })
            return false
        }
        if (!company.zipcode) {
            setError({ zipcode: "Zipcode can not be empty" })
            enqueueSnackbar("Zipcode can not be empty", { variant: 'error' })
            return false
        }
        if (!company.phone) {
            setError({ phone: "Phone number can not be empty" })
            enqueueSnackbar("Phone number can not be empty", { variant: 'error' })
            return false
        }
        else if (!company.firstname) {
            setError({ firstname: "First name can not be empty" })
            enqueueSnackbar("First name can not be empty", { variant: 'error' })
            return false
        }
        if (!company.jobtitle) {
            setError({ jobtitle: "Jobtitle can not be empty" })
            enqueueSnackbar("Jobtitle can not be empty", { variant: 'error' })
            return false
        }
        if (!company.mobile) {
            setError({ mobile: "Mobile number can not be empty" })
            enqueueSnackbar("Mobile number can not be empty", { variant: 'error' })
            return false
        }
        if (company.website) {
            var pattern = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
            var validwebsite = pattern.test(company.website)
            if (!validwebsite) {
                setError({ website: "Please enter valid URL" })
                enqueueSnackbar("Please enter valid URL", { variant: 'error' })
                return false
            }
        }

        if (!company.email) {
            setError({ email: "Email can not be empty" })
            enqueueSnackbar("Email can not be empty", { variant: 'error' })
            return false
        }
        if (company.email) {
            const pattern = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")
            var valid = pattern.test(company.email)
            if (!valid) {
                setError({ email: "Please enter valid Email" })
                enqueueSnackbar("Please enter valid Email", { variant: 'error' })
                return false
            }
        }
        // if (!company.password) {
        //     setError({ password: "Password cannot be empty" })
        //     enqueueSnackbar("Password cannot be empty", { variant: 'error' })
        //     return false
        // }
        return true
    }

    const [addmodel, setAddmodel] = React.useState(false)

    const openAddmodel = () => {
        var validator = validation();
        if (validator) {
            setAddmodel(true)
        }
    }

    console.log(company.companyname,'company.companyname')
    const addCompany = async () => {
        // var validator = validation();
        // if (validator) {
        var companyData = {
            companyname: company.companyname,
            parentcompany: company.parentcompany,
            ownershiptype: ownershipDetails.ownership_type,
            ownershipname: ownershipDetails.ownership_name,
            industry: company.industry,
            annualrevenue: company.annualrevenue,
            Employees: company.employee,
            comapanynumber: company.companynumber,
            website: company.website,
            firstname: company.firstname,
            lastname: company.lastname,
            jobtitle: company.jobtitle,
            adminphone: company.adminphone,
            mobile: company.mobile,
            country: company.country,
            streetaddress1: company.streetaddress1,
            streetaddress2: company.streetaddress2,
            city: company.city,
            state: company.state,
            zipcode: company.zipcode,
            phone: company.phone,
            fax: company.fax,
            corporateid: company.corpoateId,
            email: company.email,
            password: company.password,
            userrole: company.userrole,
            role:company.userrole,
            superAdminId: auth && auth.company_id,
            role_id: 2,
            addComplaints:true,
            viewComplaintsOne:true,
            viewcomplaint: true,
            deleteComplaints:true,
            addUserMangement:true,
            viewUserMangement:true,
            deleteUserMangement:true,
            Dashboard:true,
            ViewNotification:true,
            Settings:true
            



        }

        axios.post(process.env.REACT_APP_BASE_URL + `companies/addcompany`, companyData, ).then((res) => {            
            setCompany({
                companyname: "", parentcompany: "", industry: "", annualrevenue: "", employee: "", companynumber: "", website: "",
                country: "", streetaddress1: "", streetaddress2: "", city: "", state: "", zipcode: "", phone: "", fax: "", firstname: "", lastname: "",
                userrole: "Admin", jobtitle: "", adminphone: "", mobile: "", corpoateId: "", email: "", password: "",
            })
            setOwnershipDetails('')
            setAddmodel(false)
            enqueueSnackbar("Company added successfully", { variant: 'success' })
        }).catch((err) => {
            console.log(err);
            enqueueSnackbar(err.response.data.message, { variant: 'error' })
        })
        // }
    }
    const [saveusermodel, setsaveUser] = React.useState(false)
    const saveadduser = () => {
        var validator = validation();
        if (validator) {
            setsaveUser(true)
        }
    }
    const AddUser = () => {
        var companyData = {
            companyname: company.companyname,
            parentcompany: company.parentcompany,
            ownershiptype: ownershipDetails.ownership_type,
            ownershipname: ownershipDetails.ownership_name,
            industry: company.industry,
            annualrevenue: company.annualrevenue,
            Employees: company.employee,
            comapanynumber: company.companynumber,
            website: company.website,
            firstname: company.firstname,
            lastname: company.lastname,
            jobtitle: company.jobtitle,
            adminphone: company.adminphone,
            mobile: company.mobile,
            country: company.country,
            streetaddress1: company.streetaddress1,
            streetaddress2: company.streetaddress2,
            city: company.city,
            state: company.state,
            zipcode: company.zipcode,
            phone: company.phone,
            fax: company.fax,
            corporateid: company.corpoateId,
            email: company.email,
            password: company.password,
            userrole: company.userrole,
            superAdminId: auth && auth.company_id,
            role_id: 2
        }
        axios.post(process.env.REACT_APP_BASE_URL + `companies/addcompany`, companyData, ).then((res) => {
            setCompany({
                companyname: "", parentcompany: "", industry: "", annualrevenue: "", employee: "", companynumber: "", website: "",
                country: "", streetaddress1: "", streetaddress2: "", city: "", state: "", zipcode: "", phone: "", fax: "", firstname: "", lastname: "",
                userrole: "Main Admin", jobtitle: "", adminphone: "", mobile: "", corpoateId: "", email: "", password: ""
            })
            setOwnershipDetails('')
            enqueueSnackbar("Company added successfully", { variant: 'success' })
            setsaveUser(false)
            navigate('/Adduser', { state: { result: res.data.addCompany } });
        }).catch((err) => {
            console.log(err);
            enqueueSnackbar(err.response.data.message, { variant: 'error' })
        })
        // }
    };
    const body = auth && auth.role_id == 1 ? [auth.company_id] : [auth.superAdminId, auth.company_id]
    const GetOwnerships = () => {
        axios.post(process.env.REACT_APP_BASE_URL + `ownership/ownershipdropdown?sortColumn=${ownershipOrder.column}&sortType=${ownershipOrder.type}`, body, ).then((res) => {
            setOwnership(res.data.ownership)
        }).catch((err) => console.log(err))
    }
    const getIndustries = () => {
        axios.post(process.env.REACT_APP_BASE_URL + `industry/industrydropdown?sortColumn=${industryOrder.column}&sortType=${industryOrder.type}`, body, ).then((res) => {
 
            setIndustry(res.data.industry)
        }).catch((err) => console.log(err))
    }
    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };
    const handleOwnership = (e) => {
        setOwnershipDetails(e.target.value)
    }

    const getSortOrder = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `order/GetOrder/${auth.company_id}`, ).then((res) => {
            setOwnershipOrder({ type: res.data.order.ownership_order, column: res.data.order.ownership_name })
            setindustryOrder({ type: res.data.order.industry_order, column: res.data.order.industry_name })
        }).catch((err) => console.log(err))
    }   

    console.log(industry,"industryindustry");

    return (
        <Box className='Addcompany'>
            <MiniDrawer />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading'>Add Company</Typography>
                    <Box className='heading-btns'>
                        <Button variant="contained" className='addbtn mr-10' onClick={openAddmodel}>Save</Button>
                        <Button variant="contained" className='addbtn' onClick={() => saveadduser()} > Save + Add Users</Button>
                    </Box>
                </Box>
                <Box className=' whiteContainer'>
                    <Box className='content p-20' autoComplete="off" >
                        <Grid container rowSpacing={5} columnSpacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Typography component='h6' className='formTitle'>Company Information</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Company Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='companyname'
                                                value={company.companyname}
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                placeholder='Company Name'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.companyname && <span style={{ color: "red", fontSize: 12 }}>{error.companyname}</span>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Parent Company:</Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='parentcompany'
                                                value={company.parentcompany}
                                                className='textfield'
                                                placeholder='Parent Company'
                                                onChange={handleCompanyDetails}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth size="small">
                                            <Typography component='div' className='label'>Ownership:<Typography component='span' className='star'>*</Typography></Typography>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                name='ownership'
                                                value={company.ownership}
                                                onChange={handleOwnership}
                                                variant='outlined'
                                                className='select'
                                                MenuProps={MenuProps}
                                            >
                                                {ownership && ownership.map((item, i) => (
                                                    item.isdelete==false &&
                                                    <MenuItem value={item} className='menuitems'>{item.ownership_name}</MenuItem>
                                                ))}
                                            </Select>
                                            {error.ownershipDetails && <span style={{ color: "red", fontSize: 12 }}>{error.ownershipDetails}</span>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Industry:<Typography component='span' className='star'>*</Typography></Typography>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                value={company.industry}
                                                name='industry'
                                                onChange={handleCompanyDetails}
                                                variant='outlined'
                                                className='select'
                                                MenuProps={MenuProps}
                                            >
                                                {industry && industry.map((item, i) => (
                                                    item.isdelete==false &&
                                                    <MenuItem value={item.industry_type} className='menuitems'>{item.industry_type}</MenuItem>
                                                ))}
                                            </Select>
                                            {error.industry && <span style={{ color: "red", fontSize: 12 }}>{error.industry}</span>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Annual Revenue:</Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                value={company.annualrevenue}
                                                name='annualrevenue'
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                // placeholder='Revenue'
                                                onInput={(e) => onlyNumbers(e)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start" className='fz-14 mr-5'>
                                                        <CurrencyRupee className='fz-12 ' style={{ marginLeft: 5 }} />
                                                    </InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Employees:</Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='employee'
                                                value={company.employee}
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                placeholder='# of Employees'
                                                onInput={(e) => onlyNumbers(e)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Company Number:</Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='companynumber'
                                                value={company.companynumber}
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                placeholder='Company Number'
                                                onInput={(e) => onlyNumbers(e)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Website:</Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='website'
                                                value={company.website}
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                placeholder='Website'

                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.website && <span style={{ color: "red", fontSize: 12 }}>{error.website}</span>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Typography component='h6' className='formTitle'>Main Admin Contact</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>First Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='firstname'
                                                value={company.firstname}
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                placeholder='First Name'

                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.firstname && <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Last Name: </Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='lastname'
                                                value={company.lastname}
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                placeholder='Last Name'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth size="small">
                                            <Typography component='div' className='label'>User Role:</Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='userrole'
                                                className='textfield'
                                                placeholder='User Role'
                                                value={'Main Admin'}
                                                disabled
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Job Title:<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='jobtitle'
                                                value={company.jobtitle}
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                placeholder='Job Title'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.jobtitle && <span style={{ color: "red", fontSize: 12 }}>{error.jobtitle}</span>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Mobile:<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='mobile'
                                                onInput={(e) => onlyNumbers(e)}
                                                inputProps={{ maxLength: 10 }}
                                                value={company.mobile}
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                placeholder='Mobile'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.mobile && <span style={{ color: "red", fontSize: 12 }}>{error.mobile}</span>}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Typography component='h6' className='formTitle'>Address Information</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>

                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Street Address 1:<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='streetaddress1'
                                                value={company.streetaddress1}
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                placeholder='Address 1'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.streetaddress1 && <span style={{ color: "red", fontSize: 12 }}>{error.streetaddress1}</span>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth size="small">
                                            <Typography component='div' className='label'>Street Address 2:<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='streetaddress2'
                                                value={company.streetaddress2}
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                placeholder='Address 2'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.streetaddress2 && <span style={{ color: "red", fontSize: 12 }}>{error.streetaddress2}</span>}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>City:<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='city'
                                                value={company.city}
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                placeholder='City'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.city && <span style={{ color: "red", fontSize: 12 }}>{error.city}</span>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>State:<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='state'
                                                value={company.state}
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                placeholder='State'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.state && <span style={{ color: "red", fontSize: 12 }}>{error.state}</span>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>ZIP code:<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='zipcode'
                                                inputProps={{ maxLength: 6 }}
                                                value={company.zipcode}
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                placeholder='ZIP code'
                                                onInput={(e) => onlyNumbers(e)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.zipcode && <span style={{ color: "red", fontSize: 12 }}>{error.zipcode}</span>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Typography component='div' className='label'>Country:<Typography component='span' className='star'>*</Typography></Typography>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                name='country'
                                                value={company.country}
                                                onChange={handleCompanyDetails}
                                                className='select'
                                                placeholder='Country'
                                                MenuProps={MenuProps}
                                            >
                                                {countrylist ? countrylist.map((item, i) => (
                                                    <MenuItem value={item.name}>{item.name}</MenuItem>
                                                )) : ""}
                                            </Select>
                                            {error.country && <span style={{ color: "red", fontSize: 12 }}>{error.country}</span>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Phone:<Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='phone'
                                                value={company.phone}
                                                onChange={handleCompanyDetails}
                                                onInput={(e) => onlyNumbers(e)}
                                                inputProps={{ maxLength: 10 }}
                                                className='textfield'
                                                placeholder='Phone'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.phone && <span style={{ color: "red", fontSize: 12 }}>{error.phone}</span>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Fax:</Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='fax'
                                                value={company.fax}
                                                onChange={handleCompanyDetails}
                                                className='textfield'
                                                placeholder='Fax'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Typography component='h6' className='formTitle'>Login Info for the main admin contact</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Email Address: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='email'
                                                className='textfield'
                                                value={company.email}
                                                onChange={handleCompanyDetails}
                                                placeholder='Email'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.email && <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>}
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth size="small">
                                            <Typography component='div' className='label'>Password: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='password'
                                                className='textfield passwordField'
                                                value={company.password}
                                                onChange={handleCompanyDetails}
                                                type={values.showRePassword ? 'text' : 'password'}
                                                placeholder='Password'
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowRePassword}
                                                                edge="end" disableRipple>
                                                                {values.showRePassword ? <VisibilityOff className='fz-14 mr-10' /> : <Visibility className='fz-14 mr-10' />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                }}
                                            />
                                            {error.password && <span style={{ color: "red", fontSize: 12 }}>{error.password}</span>}
                                        </FormControl>
                                    </Grid> */}
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Corporate ID:</Typography>
                                            <TextField
                                                autoComplete='off'
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                name='corpoateId'
                                                value={company.corpoateId}
                                                onChange={handleCompanyDetails}
                                                placeholder='Corporate ID'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {/* <span style={{ color: "red", fontSize: 12 }}>{error.corpoateId}</span> */}
                                        </FormControl>
                                    </Grid>
                                    <BootstrapDialog
                                        aria-labelledby="customized-dialog-title"
                                        open={addmodel}
                                        className='addForm-modal'
                                    >
                                        <DialogContent className='deleteConfirmModal'>
                                            <HelpRounded className='confirm_icon' />
                                            <Typography component='div' className='text'>Would you like to send password reset link to same E-mail?</Typography>
                                        </DialogContent>
                                        <DialogActions className='deleteModalactbtns'>
                                            <Button autoFocus onClick={() => setAddmodel(false)} className='modalbtn cancel'>
                                                No</Button>
                                            <Button autoFocus onClick={addCompany} className=' sendbtn'>
                                                Yes</Button>
                                        </DialogActions>
                                    </BootstrapDialog>

                                    {/*ADD USER MODEL  */}
                                    <BootstrapDialog
                                        // onClose={setAddmodel(false)}
                                        aria-labelledby="customized-dialog-title"
                                        open={saveusermodel}
                                        className='addForm-modal'
                                    >
                                        {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={CloseeditModal} className='modal-title'> Delete</BootstrapDialogTitle> */}
                                        <DialogContent className='deleteConfirmModal'>
                                            <HelpRounded className='confirm_icon' />
                                            <Typography component='div' className='text'>Would you like to send password reset link to same E-mail?</Typography>
                                        </DialogContent>
                                        <DialogActions className='deleteModalactbtns'>
                                            <Button autoFocus onClick={() => setsaveUser(false)} className='modalbtn cancel'>
                                                No</Button>
                                            <Button autoFocus onClick={AddUser} className='sendbtn'>
                                                Yes</Button>
                                        </DialogActions>
                                    </BootstrapDialog>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}