import { CachedRounded, CloseRounded, FileDownloadOutlined, InfoOutlined, PlaylistAddRounded, SearchOutlined } from '@mui/icons-material'
import { Box, Button, Divider, FormControl, Grid, InputAdornment, MenuItem, TextareaAutosize, Select, TextField, Typography, Paper, CardMedia } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack'
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Pdfimage from "../../../assets/images/pdf.png";
import Docimage from "../../../assets/images/doc.png";
import axios from 'axios'
import { styled } from '@mui/material/styles';
import moment from 'moment'
import IconButton from '@mui/material/IconButton';

import { saveAs } from 'file-saver';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    {/* <CloseIcon className='fz-18' /> */}
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
export default function Complains() {
    const [statusOrder, setStatusOrder] = React.useState({ type: -1, column: 'createdAt' })
    const [status, setStatus] = React.useState()
    const [comlaintDetails, setComplaintDetails] = React.useState('');
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem('userData'))
    let navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    const [userDetails, setUserDetails] = React.useState()
    const [message, setMessage] = React.useState('')
    const [reopencomments, setReopenComments] = React.useState('')
    const [thumbsupcomments, setThumbsupComments] = React.useState('')
    const [users, setUsers] = React.useState([])
    const [userCount, setUserCount] = React.useState(0)
    const [confirmUserCount, setConfirmUserCount] = React.useState(false)
    const [complaint, setComplaint] = React.useState('')
    const [recentComplains, setRecentComplains] = React.useState([])
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchTerm, setSearchTerm] = React.useState('')
    const [usersForupdate, setUsersForupdate] = React.useState()
    const [thumbsupError, setThumbsupError] = React.useState('')
    const [thumbsup, setThumbsup] = React.useState(comlaintDetails.isThumbsup)
    const [selectedStatus, setSelectedStatus] = React.useState('')
    const [updateButton, setUpdateButton] = React.useState(false)
    const [originalStatus, setOriginalStatus] = React.useState('');
    const [tableStatus, setTableStatus] = React.useState('');
    let location = useLocation()
    var filterData = location.state ? location.state.filter : ''
    React.useEffect(() => {
        getComplaint()
        getStatus()
        getRecentComplaint()
        getUsers()
        getSortOrder()
    }, [statusOrder.type, searchTerm, page, rowsPerPage, comlaintDetails.complain_by_id, comlaintDetails.isThumbsup])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClickOpen = (item) => {
        // setComplaintDetails(item)
        setThumbsup(item.isThumbsup)
        setOpen(true);
        setUpdateButton(false)
        getRecentActivity(item._id)
        getComplaintDetails(item._id)
        setOriginalStatus(item.status_title);
    }

   

    const getComplaintDetails = (id) => {
        console.log(id, 'ididid');
        // setnotificationDetailModal(true);
        getRecentActivity(id)
        axios.get(process.env.REACT_APP_BASE_URL + `complainpage/getsinglecomplaintData/${id}`,).then((res) => {
            console.log(res.data, 'comlaintDetailscomlaintDetails');
            setComplaintDetails(res.data.details)
        }).catch((err) => console.log(err))
    }
    console.log(comlaintDetails, "commmmmmmmppppppp");
    const AddComplain = () => {
        if (userCount > 0) {
            navigate('/AddComplain');
        }
        if (userCount <= 0) {
            setConfirmUserCount(true)
        }
    }
    console.log(comlaintDetails, "comlaintDetailscomlaintDetailscomlaintDetails");
    const handleClose = () => {
        setOpen(false);
        setSelectedStatus('');
        setUserDetails();
        setUpdateButton(false)
    };

    

    const handleChangeUser = (e) => {
        setUserDetails(e.target.value)
    }

    const getComplaint = () => {
        var body = {
            userid: auth && auth._id,
            company_id: auth && auth.company_id
        }
        axios.post(process.env.REACT_APP_BASE_URL + `complainpage/getcomplinsfortrue?search=${searchTerm}&page=${page}&size=${rowsPerPage}&complaint=${filterData && filterData}`, body,).then((res) => {
            setComplaint(res.data.getdata[0].records)
            setCount(res.data.getdata[0].total)
        }).catch((err) => console.log(err))
    }

    const body = auth && auth.role_id == 1 ? [auth.company_id] : [auth.superAdminId, auth.company_id]
    const getStatus = () => {
        axios.post(process.env.REACT_APP_BASE_URL + `status/statusdropdown?sortColumn=${statusOrder.column}&sortType=${statusOrder.type}`, body,).then((res) => {
           console.log(comlaintDetails,"complain");
            if (comlaintDetails && comlaintDetails.complain_by_id !== auth._id) {
                var filterdata = res.data.status.filter((item) => {
                    return item.status_title !== 'Re-open'
                })
                setStatus(filterdata)
            }
            if (comlaintDetails && comlaintDetails.complain_by_id === auth._id) {
                setStatus(res.data.status)
            }
        }).catch((err) => console.log(err))
    }

    const getSortOrder = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `order/GetOrder/${auth.company_id}`,).then((res) => {
            setStatusOrder({ type: res.data !== null && res.data.order && res.data.order.status_order != null ? res.data.order.status_order : -1, column: res.data !== null && res.data.order && res.data.order.status_name != null && res.data.order.status_name ? res.data.order.status_name : "createdAt" })
        }).catch((err) => console.log(err))
    }

    const getRecentComplaint = () => {
        var details = {
            company_id: auth && auth.company_id,
            userid: auth && auth.company_id
        }
        axios.post(process.env.REACT_APP_BASE_URL + `companies/latestcomplains`, details,).then((res) => {
            setRecentComplains(res.data.recentdata)
        }).catch((err) => console.log(err))
    }

    const getUsers = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `companies/UserDetails?company_id=${auth && auth.company_id}&userid=${auth && auth._id}`,).then((res) => {
            setUsers(res.data.list)
            setUserCount(res.data.count)
        }).catch((err) => console.log(err))
    }


    const getUsersForUpdate = () => {
        var body = {
            userid: auth && auth._id,
            against_id: comlaintDetails && comlaintDetails.against_id,
            company_id: auth && auth.company_id,
            complain_by: comlaintDetails && comlaintDetails.complain_by_id
        }
        axios.post(process.env.REACT_APP_BASE_URL + `companies/userforupdate`, body,).then((res) => {
            console.log(res.data.list, "resssssssssssssssssssssssssss");
            setUsersForupdate(res.data.list)
        }).catch((err) => console.log(err))
    }
    //console.log(userDetails,usersForupdate,"userDetailsadmin");

    const handleUpdateComplaint = () => {
        var body = {
            statustitle: selectedStatus.status_title,
            statustype: selectedStatus.status_type,
            statuscolor: selectedStatus.status_color,
            escalted_to_id: userDetails && userDetails._id,
            escalated_to_email: userDetails && userDetails.email,
            escalated_to_name: userDetails && userDetails.firstname,
            message: message,
            commentsforReopen: reopencomments,
            isThumbsup: thumbsup,
            commentsectionforthumbsup: thumbsupcomments,
            adminId: userDetails && userDetails.company_id,
            againstrole: comlaintDetails.againstrole
        }
        console.log(body, 'bodybody');
        axios.put(process.env.REACT_APP_BASE_URL + `complainpage/updateCompaint/${comlaintDetails._id}`, body,).then((res) => {
            setSelectedStatus('');
            enqueueSnackbar("Status updated successfully", { variant: 'success' })
            setReopenComments('')
            setThumbsupComments('')
            getRecentComplaint()
            getComplaint()
            setOpen(false)
            getComplaintDetails()
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar(err.response.data.message, { variant: 'error' })
        })
    }

    const validation = () => {
        if (!thumbsupcomments) {
            setThumbsupError("Please add comment")
            return false
        }
        return true
    }

    const handleclickThumbsup = (data) => {
        console.log(data, 'data');
        if (data === true) {
            var body = {
                isThumbsup: data,
                commentsectionforthumbsup: ''
            }
            console.log(body, 'cond1')
            axios.put(process.env.REACT_APP_BASE_URL + `complainpage/updatecomplain/${comlaintDetails._id}`, body,).then((res) => {
                enqueueSnackbar("Success", { variant: 'success' })
                getRecentComplaint()
                getComplaint()
            }).catch((err) => {
                console.log(err)
                enqueueSnackbar(err.response.data.message, { variant: 'error' })
            })
        }

        else if (data === false) {
            const validator = validation()
            if (validator == true) {
                var body = {
                    isThumbsup: data,
                    commentsectionforthumbsup: thumbsupcomments
                }
                console.log(body, 'cond3')
                axios.put(process.env.REACT_APP_BASE_URL + `complainpage/updatecomplain/${comlaintDetails._id}`, body,).then((res) => {
                    enqueueSnackbar("Review added successfully", { variant: 'success' })
                    getRecentComplaint()
                    getComplaint()
                }).catch((err) => {
                    console.log(err)
                    enqueueSnackbar(err.response.data.message, { variant: 'error' })
                })
            }
        }
    }

    const Downloadfunc = (incomingdata) => {
        var urls = incomingdata
        // console.log(urls);
        // if (urls.length <= 0 || urls == process.env.REACT_APP_BASE_URL) {
        //     alert("There is no file to download")
        // } else {
        //     for (var i = 0; i < urls.length; i++) {
        //         var link = document.createElement("a");
        //         link.download = "filename.zip";
        //         link.target = "_blank";
        //         link.href = urls
        //         document.body.appendChild(link)               
        //         link.click();
        //         document.body.removeChild(link);

        //     }
        // }
        saveAs(urls)
    }
    const [recentActivity, setrecentActivity] = React.useState([])
    const getRecentActivity = (id) => {
        axios.get(process.env.REACT_APP_BASE_URL + `recentactivity/getrecentById/${id}`,).then((res) => {
            // console.log(res.data, 'activity');
            setrecentActivity(res.data.getdata)
        }).catch((err) => console.log(err))
    }
    const navigateAddUser = () => {
        navigate('/user')
    }

    // const CollectionSize = comlaintDetails && comlaintDetails.Image.length;
    // const [index, setActiveStep] = React.useState(0);
    // const goToNextPicture = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };
    // const goToPreviousPicture = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };
    // console.log(selectedStatus, comlaintDetails.statustitle, 'selectedStatusselectedStatus');
    const handleStatusChange = (e) => {
        const selectedStatusTitle = e.target.value;
        setSelectedStatus(selectedStatusTitle);
        getUsersForUpdate();
        // Show the update button only if the selected status is different from the original status
        setUpdateButton(selectedStatusTitle !== originalStatus && selectedStatusTitle !== tableStatus);
    };
    console.log(selectedStatus,updateButton, 'selectedStatusselectedStatusselectedStatus');
    console.log(selectedStatus, 'selectedStatusnew123');

    return (
        <Box className='Complains'>
            <MiniDrawer type={2} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading'>Complains</Typography>
                    <Box component='div' className='DTsearch' sx={{ marginRight: 5 }}>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'  >Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                className='textfield search '
                                placeholder='Search Here...'
                                onChange={(e) => setSearchTerm(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                                }}
                            />
                        </FormControl>
                    </Box>

                    <Button variant="contained" className='addbtn' onClick={() => AddComplain()}> <PlaylistAddRounded className='fz-18 mr-5' />Add Complaint</Button>


                </Box>
                <Box className='whiteContainer p-20'>
                    <Grid container columnSpacing={3} rowSpacing={0}>
                        <Grid item xs={12} sm={12} md={12} lg={9}>

                        <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>Complaint Type</strong></TableCell>
                                            <TableCell align="left"><strong>Against</strong></TableCell>
                                            <TableCell align="left" sx={{ width: 300, minWidth: 300 }}><strong>Details</strong></TableCell>
                                            <TableCell align="left"><strong>Role</strong></TableCell>
                                            <TableCell align="left"><strong>Reported On</strong></TableCell>
                                            <TableCell align="center"><strong>Status</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {complaint && complaint.length > 0 ? complaint.map((item) => (
                                            <TableRow
                                                key={item.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                style={{cursor:'pointer'}}
                                                onClick={(e) => handleClickOpen(item)}
                                            >

                                                <TableCell align="left">{item.complain_type ? item.complain_type : '-'}</TableCell>
                                                <TableCell align="left">{item.againstname ? item.againstname : '-'}</TableCell>
                                                <TableCell align="left">{item.description ? item.description.slice(0, 100)+'...' : '-'}</TableCell>
                                                <TableCell align="left">{item.againstrole != 'undefined' ? item.againstrole : '-'}</TableCell>
                                                <TableCell align="left">{moment(item.createdAt).format('DD/MM/YYYY, hh:mm A')}</TableCell>
                                                <TableCell align="center" ><span style={{ backgroundColor: item.statuscolor != "undefined" ? item.statuscolor : '', color: 'white' }}>{(item.statustitle != "undefined") ? item.statustitle : '-'}</span></TableCell>
                                            </TableRow>
                                        )) : <TableCell colSpan={4} className='nodata'>NO DATA FOUND</TableCell>}
                                    </TableBody>
                                </Table>
                            </TableContainer>


                            {/* {complaint && complaint.length > 0 ? complaint.map((item, i) => (
                                <Box className='table'>
                                    <Box className='table-head'>
                                        <Box>
                                            <Typography component='h6' variant='Complain Type'>Complaint Type</Typography>
                                            <Typography component='div' variant='' className='subtext'>{item.complain_type}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography component='h6' variant='Complain Type'>Against</Typography>
                                            <Typography component='div' variant='' className='subtext'>{item.againstname}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography component='h6' variant='Complain Type'>Role</Typography>
                                            <Typography component='div' variant='' className='subtext'>{item.againstrole != 'undefined' ? item.againstrole : '-'}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography component='h6' variant='Complain Type'>Reported On</Typography>
                                            <Typography component='div' variant='' className='subtext'>{moment(item.createdAt).format('DD/MM/YYYY, hh:mm A')}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography component='h6' variant='Complain Type'>Status</Typography>
                                            <Typography component='div' variant='' className='subtext '>
                                                <Typography component='div' className='status' style={{ backgroundColor: item.statuscolor }}>{item.statustitle}</Typography>
                                            </Typography>
                                        </Box>
                                        {(item.statustitle == "Escalate" || item.statustitle == "escalate") &&
                                            <Box>
                                                <Typography component='h6' variant='Complain Type'>Escalated To</Typography>
                                                <Typography component='div' variant='' className='subtext '>{item.escalated_to_name}</Typography>
                                            </Box>
                                        }

                                        <Box>
                                            {item.statustitle == 'Resolved' && item.isThumbsup === false ? <>
                                                <ThumbDownOutlinedIcon className='uploadicon thumbsUp liked ' /> </> :
                                                item.statustitle == 'Resolved' && item.isThumbsup === true &&
                                                <><ThumbUpAltOutlinedIcon className='uploadicon thumbsUp liked' /> </>}
                                        </Box>
                                    </Box>
                                    <Box className='table-content'>
                                        <Typography component='div' variant='' >{item.description && item.description.slice(0, 200) + (item.description.length > 200 ? "" : "")}<Button variant="outlined" className='morebtn' onClick={(e) => handleClickOpen(item)}>...read more</Button> </Typography>
                                    </Box>
                                </Box>
                            )) : <Box className='table'>
                                <Box className='table-head'>
                                    <Box>
                                        <Typography component='h6' variant='Complain Type'>Complaint Type</Typography>
                                    </Box>
                                    <Box>
                                        <Typography component='h6' variant='Complain Type'>Against</Typography>
                                    </Box>
                                    <Box>
                                        <Typography component='h6' variant='Complain Type'>Role</Typography>
                                    </Box>
                                    <Box>
                                        <Typography component='h6' variant='Complain Type'>Reported On</Typography>
                                    </Box>
                                    <Box>
                                        <Typography component='h6' variant='Complain Type'>Status</Typography>
                                    </Box>

                                </Box>
                                <Box className='table-content'>
                                    <Typography component='div' variant='' style={{ textAlign: 'center' }}>No complaints found</Typography>
                                </Box>
                            </Box>
                            } */}
                            {complaint && complaint.length > 0 &&
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    className='DT-pagination'
                                />}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3}>
                            <Box className='table'>
                                <Box component='div' className='activity'>
                                    <Box className='table-head p-20'>
                                        <Typography component='h3' variant='' className='recent-activity-heading p-0'>Recent 5 Complains
                                        </Typography>
                                    </Box>
                                    {recentComplains && recentComplains.length > 0 ? recentComplains.map((item, i) => (
                                        <Box component='div' className='recent-activity-container'>
                                            <Box className="activity-list-item mb-10">
                                                <img src={require('./../../../assets/images/verbal-complains.png')} className="" height="40" alt="" />
                                                <Box className="activity-list-content">
                                                    <Typography className="activity-desc"> Against <Typography component='span' className="by">{item.againstname}</Typography>
                                                        <Typography component='span' className="activity-time"> {moment(item.createdAt).format('DD/MM/YYYY, hh:mm A')}</Typography>
                                                    </Typography>
                                                    <Typography component='p' className="complain-shortdesc">{item.description && item.description.slice(0, 75) + (item.description.length > 75 ? "..." : "")}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )) : <Box component='div' className='recent-activity-container'>
                                        <Box className="activity-list-item mb-10">
                                            <Typography className="activity-desc"> No recent complaint found </Typography>
                                        </Box>
                                    </Box>}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    className='ComplainModal'
                >
                    <DialogTitle>
                        <Box className="heading">
                            <Typography component='h3' variant='' className="complain-heading">Harassment by {comlaintDetails.againstname} </Typography>

                            <Box style={{ display: 'inline-block', marginLeft: 'auto' }}>
                            
                            {(updateButton && (selectedStatus.status_title !== comlaintDetails.statustitle)) && <Button className="updatebtn" onClick={handleUpdateComplaint}><CachedRounded className='fz-18 mr-5' />Update</Button>}

                            </Box>

                            <CloseRounded className=' closebtn' onClick={handleClose} />
                        </Box>

                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description" className='mt-20'>
                            <Box className='comDetails'>
                                <Typography component='h6' variant='' className='leftCont'>Complaint Type</Typography>
                                <Typography component='div' variant='' className='rightCont'>{comlaintDetails.complain_type}</Typography>
                            </Box>
                            <Box className='comDetails'>
                                <Typography component='h6' variant='' className='leftCont'>Against</Typography>
                                <Typography component='div' variant='' className='rightCont'>{comlaintDetails.againstname}</Typography>

                            </Box>
                            <Box className='comDetails'>
                                <Typography component='h6' variant='' className='leftCont'>Role</Typography>
                                <Typography component='div' variant='' className='rightCont'>{comlaintDetails.againstrole}</Typography>
                            </Box>
                            {/* <Box className='comDetails'>
                                <Typography component='h6' variant='' className='leftCont'>Status Type</Typography>
                                <Typography component='div' className='status' style={{ backgroundColor: comlaintDetails.statuscolor }}>{comlaintDetails.statustype}</Typography>
                            </Box> */}
                            <Box className='comDetails'>
                                <Typography component='h6' variant='' className='leftCont'>Status</Typography>
                                <Typography component='div' className='status' style={{ backgroundColor: comlaintDetails.statuscolor }}>{comlaintDetails.statustitle}</Typography>
                            </Box>

                            <Box className='comDetails mb-20 mt-20'>

                                <Typography component='h6' variant='' className='leftCont'>Change Status</Typography>

                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={selectedStatus.status_title}
                                    // name='status'
                                    // onChange={handleStatusChange}
                                     onChange={(e) => { setSelectedStatus(e.target.value); getUsersForUpdate(); setUpdateButton(true) }}
                                    variant='outlined'
                                    className='select'
                                    MenuProps={MenuProps}
                                    style={{ width: 200 }}
                                >
                                    {status && status.map((item, i) => (
                                        <MenuItem value={item} className='menuitems'>{item.status_title}</MenuItem>
                                    ))}
                                </Select>

                            </Box>
                            {console.log(selectedStatus, "selectedStatus.status_titleselectedStatus.status_titleselectedStatus.status_title")}
                            {selectedStatus.status_title == 'Escalate' &&
                                <Box className='comDetails mb-20'>
                                    <Typography component='h6' variant='' className='leftCont'>To Whom</Typography>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={usersForupdate && usersForupdate.firstname}
                                        name='against'
                                        onChange={(e) => { handleChangeUser(e); setUpdateButton(true); }}
                                        variant='outlined'
                                        className='select'
                                        MenuProps={MenuProps}
                                        style={{ width: 200 }}
                                    >
                                        {usersForupdate && usersForupdate.map((item, i) => (
                                            
                                            <MenuItem value={item} className='menuitems'>{item.firstname}</MenuItem>
                                        ))}
                                    </Select>
                                </Box>}
                            {selectedStatus.status_title === 'Re-open' ?
                                <Box className='comDetails mb-20'>
                                    <Typography component='h6' variant='' className='leftCont'>Comments</Typography>
                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        placeholder="Add comments"
                                        className='textfield textarea'
                                        onChange={(e) => { setReopenComments(e.target.value); setUpdateButton(true) }}
                                        style={{ width: 200 }}
                                        minRows={3}
                                    />
                                </Box>
                                : <Box className='comDetails mb-20'>

                                    <Typography component='h6' variant='' className='leftCont'>Message</Typography>
                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        placeholder="Message"
                                        className='textfield textarea'
                                        onChange={(e) => { setMessage(e.target.value);; setUpdateButton(true) }}
                                        style={{ width: 200 }}
                                        minRows={3}
                                    />
                                </Box>}
                            {comlaintDetails && <>
                                <Divider className='mt-30 mb-30' />
                                <Box className='comDetails diffFile_type'>
                                    {/* {comlaintDetails.Image && comlaintDetails.Image.map((item, i) => (
                                    item.path.includes(".jpg") ?
                                        <Box className="videoupload" style={{}}  >
                                            <img src={process.env.REACT_APP_BASE_URL + item.path} alt="noimage" className='Imageeditview' />
                                            <FileDownloadOutlined className='uploadicon' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + item.path)} />
                                        </Box>
                                        : item.path.includes(".jpeg") ?
                                            <Box className="videoupload" style={{}}  >
                                                <img src={process.env.REACT_APP_BASE_URL + item.path} alt="noimage" className='Imageeditview' />
                                                <FileDownloadOutlined className='uploadicon ' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + item.path)} />
                                            </Box> : item.path.includes(".png") ?
                                                <Box className="videoupload" style={{}}  >
                                                    <img src={process.env.REACT_APP_BASE_URL + item.path} alt="noimage" className='Imageeditview' />
                                                    <FileDownloadOutlined className='uploadicon ' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + item.path)} />
                                                </Box> : item.path.includes(".pdf") ?
                                                    <Box className="videoupload" style={{}} >
                                                        <img src={Pdfimage} alt="noimages" className='Imageeditview' />
                                                        <FileDownloadOutlined className='uploadicon ' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + item.path)} />
                                                    </Box> :
                                                    item.path.includes(".doc" || ".docx") ?
                                                        <Box className="videoupload" style={{}} >
                                                            <img src={Docimage} alt="noimages" className='Imageeditview' />
                                                            <FileDownloadOutlined className='uploadicon ' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + item.path)} />
                                                        </Box> : ''
                                ))} */}

                                    <Swiper navigation={true} modules={[Navigation]} className="mySwiper imagesSwiper mb-20" spaceBetween={10} slidesPerView={3} loop={false} centeredSlides={comlaintDetails.Image && comlaintDetails.Image.length <= 2 ? true : false} >
                                        {comlaintDetails.Image && comlaintDetails.Image.map((slideContent, index) => (
                                            slideContent.path.includes(".jpg") ?
                                                <SwiperSlide key={slideContent.path} virtualIndex={index}>
                                                    <Box className="videoupload"   >
                                                        <img src={process.env.REACT_APP_BASE_URL + slideContent.path} alt="noimage" className='Imageeditview' />
                                                        <FileDownloadOutlined style={{ cursor: 'pointer' }} className='uploadicon' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + slideContent.path)} />
                                                    </Box></SwiperSlide> : slideContent.path.includes(".jpeg") ? <SwiperSlide key={slideContent.path} virtualIndex={index}>
                                                        <Box className="videoupload"   >
                                                            <img src={process.env.REACT_APP_BASE_URL + slideContent.path} alt="noimage" className='Imageeditview' />
                                                            <FileDownloadOutlined style={{ cursor: 'pointer' }} className='uploadicon' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + slideContent.path)} />
                                                        </Box></SwiperSlide> : slideContent.path.includes(".png") ? <SwiperSlide key={slideContent.path} virtualIndex={index}>
                                                            <Box className="videoupload"   >
                                                                <img src={process.env.REACT_APP_BASE_URL + slideContent.path} alt="noimage" className='Imageeditview' />
                                                                <FileDownloadOutlined style={{ cursor: 'pointer' }} className='uploadicon' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + slideContent.path)} />
                                                            </Box></SwiperSlide> : slideContent.path.includes(".pdf") ? <SwiperSlide key={slideContent.path} virtualIndex={index}>
                                                                <Box className="videoupload"  >
                                                                    <img src={Pdfimage} alt="noimages" className='Imageeditview' />
                                                                    <FileDownloadOutlined style={{ cursor: 'pointer' }} className='uploadicon ' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + slideContent.path)} />
                                                                </Box></SwiperSlide> : slideContent.path.includes(".doc") ? <SwiperSlide key={slideContent.path} virtualIndex={index}>
                                                                    <Box className="videoupload"  >
                                                                        <img src={Docimage} alt="noimages" className='Imageeditview' />
                                                                        <FileDownloadOutlined style={{ cursor: 'pointer' }} className='uploadicon ' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + slideContent.path)} />
                                                                    </Box></SwiperSlide> : slideContent.path.includes(".docx") ? <SwiperSlide key={slideContent.path} virtualIndex={index}><Box className="videoupload" style={{}} >
                                                                        <img src={Docimage} alt="noimages" className='Imageeditview' />
                                                                        <FileDownloadOutlined style={{ cursor: 'pointer' }} className='uploadicon ' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + slideContent.path)} />
                                                                    </Box></SwiperSlide> : ''

                                        ))}
                                    </Swiper>
                                    {/* {comlaintDetails && comlaintDetails.Image.length > 0 && <>
                                    {index !== 0 ? <KeyboardArrowLeftIcon onClick={goToPreviousPicture} disabled={index === CollectionSize - 1} /> : <KeyboardArrowLeftIcon sx={{ color: '#dbd5c5' }} />}
                                    {comlaintDetails.Image && comlaintDetails.Image[index].path.includes(".jpg") ? <Box className="videoupload">
                                        <img src={comlaintDetails && process.env.REACT_APP_BASE_URL + comlaintDetails.Image[index].path} alt="noimage" className='Imageeditview' />
                                        <FileDownloadOutlined className='uploadicon' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + comlaintDetails.Image[index].path)} />
                                    </Box> : comlaintDetails.Image && comlaintDetails.Image[index].path.includes(".jpeg") ? <Box className="videoupload">
                                        <img src={comlaintDetails && process.env.REACT_APP_BASE_URL + comlaintDetails.Image[index].path} alt="noimage" className='Imageeditview' />
                                        <FileDownloadOutlined className='uploadicon' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + comlaintDetails.Image[index].path)} />
                                    </Box> : comlaintDetails.Image && comlaintDetails.Image[index].path.includes(".png") ? <Box className="videoupload">
                                        <img src={comlaintDetails && process.env.REACT_APP_BASE_URL + comlaintDetails.Image[index].path} alt="noimage" className='Imageeditview' />
                                        <FileDownloadOutlined className='uploadicon' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + comlaintDetails.Image[index].path)} />
                                    </Box> : comlaintDetails.Image && comlaintDetails.Image[index].path.includes(".pdf") ? <Box className="videoupload">
                                        <img src={Pdfimage} alt="noimages" className='Imageeditview'/>
                                        <FileDownloadOutlined className='uploadicon' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + comlaintDetails.Image[index].path)} />
                                    </Box> : comlaintDetails.Image && comlaintDetails.Image[index].path.includes(".docx") ? <Box className="videoupload">
                                        <img src={Docimage} alt="noimages" className='Imageeditview'/>
                                        <FileDownloadOutlined className='uploadicon' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + comlaintDetails.Image[index].path)} />
                                    </Box>  : comlaintDetails.Image && comlaintDetails.Image[index].path.includes(".doc") ? <Box className="videoupload">
                                        <img src={Docimage} alt="noimages" className='Imageeditview'/>
                                        <FileDownloadOutlined className='uploadicon' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + comlaintDetails.Image[index].path)} />
                                    </Box> :''}
                                    {index !== CollectionSize - 1 ? <ChevronRightIcon onClick={goToNextPicture} disabled={index === CollectionSize - 1} /> : <ChevronRightIcon sx={{ color: '#dbd5c5' }} />}
                                </>} */}
                                    <Swiper navigation={comlaintDetails.Video && comlaintDetails.Video.length < 2 ? false : true} modules={[Navigation]} className="mySwiper"  >
                                        {comlaintDetails.Video && comlaintDetails.Video.map((item, i) => (
                                            console.log(item, "itemitem"),
                                            item.path.includes(".mp4") ?
                                                <SwiperSlide key={item.path} virtualIndex={i}>
                                                    <Box style={{}} className='mt-20'>
                                                        <CardMedia component='iframe' src={process.env.REACT_APP_BASE_URL + item.path} alt="noimages" style={{ border: 'none', opacity: 0.7 }} />
                                                    </Box>
                                                </SwiperSlide> : item.path.includes(".mp3") ?
                                                    <SwiperSlide key={item.path} virtualIndex={i}>
                                                        <Box style={{ boxShadow: 'none' }} className={comlaintDetails.Video && comlaintDetails.Video.length > 1 ? 'audioMb mp3Box' : 'mp3Box'} >
                                                            <audio controls src={process.env.REACT_APP_BASE_URL + item.path} style={{ maxWidth: '100%', opacity: 0.7 }}> </audio>
                                                        </Box>
                                                    </SwiperSlide>
                                                    : ''
                                        ))}
                                    </Swiper>
                                </Box>
                            </>}
                            <Divider className='mt-320 mb-20' />
                            <Box className='description'>
                                <Typography component='p'>{comlaintDetails.description}</Typography>
                            </Box>
                            {comlaintDetails.statustitle == 'Resolved' && <>
                                <Divider className='mt-20 mb-20' />
                                {comlaintDetails.complain_by_id === auth._id ? <>
                                    <Typography component='h3' variant='' className='fz-18 mb-10 semibold'>Are you Satisfied or Not?</Typography>
                                    <Box className='comDetails mt-20 mb-20 '>
                                        <TextareaAutosize
                                            aria-label="empty textarea"
                                            placeholder="Write comments here..."
                                            className='textfield textarea'
                                            defaultValue={comlaintDetails.commentsectionforthumbsup}
                                            onChange={(e) => { setThumbsupComments(e.target.value); setThumbsupError(''); setUpdateButton(true) }}
                                            style={{ width: 500 }}
                                            minRows={3}
                                        />
                                    </Box>
                                    {thumbsupError && <p className='error-field' style={{ color: 'red', marginBottom: '10px' }}>{thumbsupError}</p>}
                                    {/* <Button className='modalbtn addbtn' onClick={(e) => handleclickThumbsup(false)}>Submit</Button>  */}

                                    <Box className=''>
                                        {thumbsup === false ? <><ThumbUpAltOutlinedIcon className='uploadicon thumbsUp liked mr-5' onClick={(e) => { handleclickThumbsup(true); setThumbsup(true) }} />
                                            <ThumbDownIcon className='uploadicon thumbsUp liked' onClick={(e) => { handleclickThumbsup(false); setThumbsup(false) }} /> </> : thumbsup === true ?
                                            <><ThumbUpIcon className='uploadicon thumbsUp mr-5 liked' onClick={(e) => { handleclickThumbsup(true); setThumbsup(true) }} />
                                                <ThumbDownOutlinedIcon className='uploadicon thumbsUp liked ' onClick={(e) => { handleclickThumbsup(false); setThumbsup(false) }} />
                                            </> : <>  <ThumbUpAltOutlinedIcon className='uploadicon thumbsUp liked mr-5' onClick={(e) => { handleclickThumbsup(true); setThumbsup(true) }} />
                                                <ThumbDownOutlinedIcon className='uploadicon thumbsUp liked ' onClick={(e) => { handleclickThumbsup(false); setThumbsup(false) }} /> </>}
                                    </Box>
                                </> :
                                    <>
                                        <Box>
                                            <Typography component='h3' variant='' className='fz-18 mb-10 semibold'>{comlaintDetails.isThumbsup === false ? 'Dissatisfied' : 'Satisfied'}</Typography>
                                            {comlaintDetails.isThumbsup === false ? <> <ThumbDownOutlinedIcon className='uploadicon thumbsUp liked ' /> </> :
                                                <><ThumbUpAltOutlinedIcon className='uploadicon thumbsUp liked' /> </>}
                                            {comlaintDetails.commentsectionforthumbsup == true &&
                                                <Box className='comDetails mb-20 mt-20'><Typography>"</Typography>{comlaintDetails.commentsectionforthumbsup}<Typography>"</Typography></Box>}
                                        </Box>
                                    </>}
                                <Divider className='mt-20 mb-10' /></>}




                            <Box component='div' className='activity'>
                                <Typography component='h3' variant='' className='recent-activity-heading'> Activity
                                </Typography>
                                <Box component='div' className=''>
                                    {recentActivity && recentActivity.length > 0 ? recentActivity.map((item, i) => (
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/complains-vs-resolved.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc mb-12">Complaint status has been changed to <Typography component='span' className="by" style={{ backgroundColor: item.statuscolor, color: '#f5f3f2', paddingLeft: 4, paddingRight: 4, borderRadius: 2 }}>{item.statustitle}</Typography></Typography>
                                                {(item.statustitle == 'Escalate' || item.statustitle == 'escalate') && <Typography className="activity-desc mb-5">Escalated to <Typography component='span' className="by">{item.escalated_to_name}</Typography></Typography>}
                                                {item.message && <Typography className="activity-desc"><strong>Comment :</strong> {item.statustitle === 'Re-open' ? <Typography component='span' className="activity-desc">{item.commentsectionforthumbsup}</Typography> : <Typography component='span' className="activity-desc">{item.message}</Typography>}</Typography>}
                                                <Typography className="activity-time">{moment(item.createdAt).format('lll')}</Typography>
                                            </Box>
                                        </Box>
                                    )) :
                                        <Box className="activity-list-item mb-10 p-0" >
                                            <Typography component='div' className="activity-desc fz-14 p-0"> No Activity found </Typography>
                                        </Box>}
                                </Box>
                            </Box>

                        </DialogContentText>
                    </DialogContent>
                    {/* {downloaderComponentUI} */}
                </Dialog>
                <BootstrapDialog
                    onClose={() => setConfirmUserCount(false)}
                    aria-labelledby="customized-dialog-title"
                    open={confirmUserCount}
                    className='addForm-modal adduserAlert_Modal'
                >
                    <DialogContent className='deleteConfirmModal' >
                        <InfoOutlined className='confirm_icon' />
                        <Typography component='div' className='text' >Please add users before try to add complaint.</Typography>
                    </DialogContent>
                    <DialogActions className='deleteModalactbtns'>
                        <Button autoFocus onClick={() => setConfirmUserCount(false)} className='modalbtn cancel'>Cancel</Button>
                        <Button autoFocus onClick={navigateAddUser} className='modalbtn addbtn'>Add user</Button>
                    </DialogActions>
                </BootstrapDialog>


            </Box>
        </Box >
    )
}