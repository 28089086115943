import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import DataTable from './Datatable'

export default function Companies() {
    let navigate = useNavigate()

    const AddCompany = () => {
        navigate('/AddCompany');
    }
    return (
        <Box className='companies'>
            <MiniDrawer type={1} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading'>Companies</Typography>
                    <Button variant="contained" className='addbtn' onClick={() => AddCompany()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Company</Button>
                </Box>
                <Box className='whiteContainer'>
                    <DataTable />
                </Box>
            </Box>
        </Box>
    )
}
