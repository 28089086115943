import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import MiniDrawer from '../../../../components/drawer'
import DataTable from './tabledata'
import Sidebar from '../Sidebar'
import Settings_sidebar from '../Sidebar'

export default function Settings() {
    return (
        <Box className='settings'>
            <MiniDrawer type={5}/>
            <Sidebar subtype={0}/>
            <Box component="section" className="contentWraper">                
                <DataTable />
            </Box>
        </Box>
    )
}
