import React, { useState } from "react"
import { BusinessOutlined, Lock, Person } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, OutlinedInput, IconButton } from '@mui/material'
import axios from 'axios'
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";

export default function Adimlogimn() {
    let navigate = useNavigate();
    //calling state's
    const [userdata, setUserData] = React.useState({ companyname: "", username: "", password: "" })
    const [error, setError] = useState({ companyname: "", username: "", password: "" })

    const [values, setValues] = React.useState({
        showPassword: false,
        showRePassword: false
    });

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleClickShowRePassword = () => {
        setValues({
            ...values,
            showRePassword: !values.showRePassword,
        });
    };
    let uname, uvalue
    const handleChange = (e) => {
        // uname=e.target.name,
        // uvalue=e.target.value
        setUserData({ ...userdata, [e.target.name]: e.target.value })
        setError({ ...error, [e.target.name]: "" })
    }
    const validator = () => {

        if (!userdata.username && !userdata.password) {
            setError({ username: "Email cannot be empty", password: "Password cannot be empty" })
            return false
        }

        else if (!userdata.username) {
            setError({ username: "Email cannot be empty" });
            return false
        }
        else if (!userdata.password) {
            setError({ password: "Password cannot be empty" });
            return false
        }
        return true
    }

    const handleLogin = (e) => {
        console.log(process.env.REACT_APP_BASE_URL,'hitting');
        e.preventDefault();
        var validate = validator();
        if (validate) {
            var body = {
                email: userdata.username,
                password: userdata.password,
            }
            axios.post(process.env.REACT_APP_BASE_URL + "companies/Superadminlogin", body).then((res) => {
                console.log('hit',res.data);
                localStorage.setItem("userData", JSON.stringify(res.data.user))
                localStorage.setItem("token", res.data.token)
                navigate("/dashboard")
            }).catch((err) => {
                setError({ password: err.response.data ? err.response.data.message : "Something went wrong" })
                console.log(err,'errorcatchblock')
            })
        }
    }
    const  _handleKeyDown=(e)=>{
        if(e.key === 'Enter'){
            handleLogin(e)
          }
        }

    return (
        <Box className='login'>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='login_img'>
                        <Box className='logintext_box'>
                            <h1 className='heading'>Incidents are facts</h1>
                            <Typography component='p' variant='body1' className='subheading'>All incidents should be treated as opportunities to learn and improve, Here is the ledger to collect the facts.</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='Login_content'>
                        <Typography component='h4' variant='h4'>Login</Typography>
                        <Box className='form'>

                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Email</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    className='textfield'
                                    placeholder='Email'
                                    name='username'
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Person className='start_icon' /></InputAdornment>,
                                    }}

                                />
                                <p className='error-field'>{error.username}</p>

                            </FormControl>
                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Password</Typography>

                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    placeholder='Password'
                                    type={values.showRePassword ? 'text' : 'password'}
                                    className='textfield'
                                    name='password'
                                    onKeyDown={_handleKeyDown}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Lock className='start_icon' /></InputAdornment>,

                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowRePassword}
                                                    edge="end">
                                                    {values.showRePassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>

                                    }}
                                />
                                {/* <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    className='textfield'
                                    placeholder='Password'
                                    name='password'
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Lock className='start_icon' /></InputAdornment>,
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowRePassword}
                                                edge="end">
                                                {values.showRePassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                /> */}
                                <p className='error-field'>{error.password}</p>

                            </FormControl>

                        </Box>
                        <Button className='loginbtn' onClick={handleLogin}>Login</Button>
                        <Box className='a-link'>
                            <Typography component='a' href='/superdminforgotpassword'>Forgot your password?</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}
