import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, Select, MenuItem, SwipeableDrawer, Menu, Link } from '@mui/material';
import { ArrowDropDown, BusinessOutlined, HomeRounded, MenuOpen, PersonAddAlt1Rounded, PowerSettingsNew, SettingsRounded, Share, Notifications, SmsFailedRounded, SyncAlt, NotificationsNoneRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { header, headerformdata } from "../components/axiousheader"

const drawerWidth = 260;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
	backgroundColor: '#1F40C9',
	color: 'white'
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
	backgroundColor: '#1F40C9',
	color: 'white'
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `100%`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	backgroundColor: '#fff'
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),

		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),

	}),
);


export default function MiniDrawer({ type }) {
	const auth = JSON.parse(localStorage.getItem("userData"))
	const Permission = JSON.parse(localStorage.getItem("Permissions"))

	console.log(Permission, "Permission");
	const sidebar_Items = [

		{
			name: 'Dashboard',
			icon: <HomeRounded className='sidebarIcons' style={{}} />,
			link: '/dashboard'
		},
		auth && auth.role_id == 1 ?
			{
				name: 'Companies',
				icon: <BusinessOutlined className='sidebarIcons' />,
				link: '/companies'
			} : "",
		auth && auth.role_id == 2 || auth.role_id == 3 && Permission.Complaints.viewComplaints == true ?
			{
				name: 'Complains',
				icon: <SmsFailedRounded className='sidebarIcons' style={{}} />,
				link: '/complains'
			} : "",
		auth && auth.role_id != 1 && Permission && Permission.UserMangement.viewUserMangement &&
		{
			name: 'Users',
			icon: <SupervisedUserCircleIcon className='sidebarIcons' style={{}} />,
			link: '/user'
		},
		auth && auth.role_id == 2 || (auth.role_id == 3 && Permission.ViewNotification == true) ?
			{
				name: 'Notifications',
				icon: <Notifications className='sidebarIcons' style={{}} />,
				link: '/notification',
			} : "",
		auth && (auth.role_id == 1 || auth.role_id == 2 || (auth.role_id == 3 && Permission.Settings == true)) ?
			{
				name: 'Settings',
				icon: <SettingsRounded className='sidebarIcons' style={{}} />,
				link: '/setting/addcomplain'
			} : "",
	]

	React.useEffect(() => {
		getNotificationCount()
	}, [])
	const [open, setOpen] = React.useState(true);
	const [resdrawer, setresdrawer] = React.useState(true);
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});
	let navigate = useNavigate()



	const handleDrawerToggle = () => {
		document.querySelector('body').classList.toggle("sidebarclose");
		setOpen(!open);

		var element = document.querySelectorAll(".body");
		element.classList.add("drawer_closed");
	};

	const PageNavigation = (url) => {
		if (url == '/notification') {
			navigateComplaint()
			navigate(url);
		}
		navigate(url);
	}
	// ----responsive drawer------
	const toggleDrawer = (anchor, open) => (event) => {

		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};
	const toggleDrawer_res = (anchor) => (event) => {
		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setState({ ...state, [anchor]: !state[anchor] });
	};

	const list = (anchor) => (
		<Box
			sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
			style={{ paddingTop: 60 }}
		>
			<List>
				{sidebar_Items.map((text, index) => (
					text &&
					<ListItem key={index} disablePadding sx={{ display: 'block' }} selected={selectedIndex === index}
						onClick={(event) => handleListItemClick(event, index)}>
						<ListItemButton
							sx={{
								minHeight: 44,
								justifyContent: open ? 'initial' : 'center',
								px: 2.5,
								fontSize: 13,
								paddingLeft: 4
							}}
						// selected={type == text.name}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : 'auto',
									justifyContent: 'center',
									color: 'white',
									marginRight: 2
								}}
								onClick={() => navigate(text.link)}
							>
								{text.icon}
							</ListItemIcon>
							<ListItemText primary={text.name} sx={{ opacity: open ? 1 : 0 }} onClick={() => navigate(text.link)} className='sidebar_itemText' />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>
	);



	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const handleListItemClick = (event, index) => {
		setSelectedIndex(index);

	};
	const openAcct = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleSignout = () => {
		localStorage.clear();
		navigate("/login")
		axios.put(process.env.REACT_APP_BASE_URL + `companies/logout/${auth && auth._id}`, { headers: header.Authorization = localStorage.getItem("token") }).then((res) => {

			//    enqueueSnackbar("Log out successfully", { variant: 'success' })
		}).catch((err) => console.log(err))
	}
	// const handleClose = (e) => {
	// 	console.log(e.target,'eee')
		
	// };
	const handleClickmenu=(e)=>{

		if(window.location.pathname === '/profileupdate'){
			setAnchorEl(null);
		}
		navigate("/profileupdate")
		// console.log(e.currentTarget,"e.target.valuee.target.value");
		// switch (e.target.value) {
		// 	case "profile":
		// 		navigate("/profileupdate")
		// 		break;
		// 	case "logout":
		// 		handleSignout()
		// 		break;
		// 	default:
		// }
	}

	const handleClicklogout=()=>{
		handleSignout();
	}

	const handleCloseMenu = (e) => {
		setAnchorEl(null);
	  };
	  
	React.useState(() => {
		setSelectedIndex(type);
	}, [type])


	const showSettingsMenu = () => {
		document.querySelector('.settings').classList.toggle("showSettingMenu");

	}
	const showAccountMenu = () => {
		document.querySelector('body').classList.toggle("showAccountMenu");
	}

	const [notificationCount, setNotificationCount] = React.useState()
	const getNotificationCount = () => {
		axios.get(process.env.REACT_APP_BASE_URL + `complainpage/notificationcount/${auth && auth._id}`, { headers: header.Authorization = localStorage.getItem("token") }).then((res) => {
			console.log(res.data, 'notificationcount');
			setNotificationCount(res.data.Count)
		}).catch((err) => console.log(err))
	}
	console.log(header, 'headereeeee');
	const navigateComplaint = () => {
		axios.put(process.env.REACT_APP_BASE_URL + `complainpage/deleteCountNotification/${auth && auth._id}`, { headers: header.Authorization = localStorage.getItem("token") }).then((res) => {
			console.log(res.data, 'notificationcount');
			getNotificationCount()
			if (res.data.status == 1) {
				navigate('/notification');
			}
		})
			.catch(error => {

				console.log(error, "1111");
			})
	}

	const MenuProps = {
		PaperProps: {
			style: {
				// maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
				left: 1625
			},
		},
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<AppBar position="fixed" open={open} className='appbar'>
				<Toolbar className='toolbar'>
					<Box className='logo'>
						<img src={require('./../assets/images/infomaze-logo.png')} width='100%' height='auto'></img>
					</Box>
					{auth && auth.role_id == 1 && <>
						<Box className='icons'>
						</Box>
						<Box className='account'>
							<IconButton
								onClick={handleClick}
								size="small"
								sx={{ ml: 2 }}
								aria-controls={open ? 'account-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
								disableRipple={true}
							>
								<Avatar sx={{ width: 32, height: 32, marginRight: 1 }} alt={auth && auth.userrole}>{auth && auth.userrole && auth.userrole.split('')[0][0]} </Avatar>
								<Typography component='div' variant='body1' className='fz-13 name'>{auth && auth.userrole}</Typography>
								{/* <ArrowDropDown sx={{ marginTop: -0.4, fontSize: 18, color: '#222' }} /> */}
							</IconButton>
						</Box></>
					}

					{auth && (auth.role_id == 2 || auth.role_id == 3) &&
						<>

							<Box className='icons'>
								{Permission.ViewNotification == true &&
									<Box className='notificationicon'>
										<NotificationsNoneRounded onClick={(e) => navigateComplaint()} />
									</Box>
								}
								{notificationCount > 0 ? <div className='counter' onClick={navigateComplaint}><div>{notificationCount}</div></div> : null}

							</Box>

							<Box className='account'>
								<IconButton
									onClick={handleClick}
									size="small"
									sx={{ ml: 2 }}
									aria-controls={open ? 'account-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={open ? 'true' : undefined}
									disableRipple={true}
								>
									<Avatar sx={{ width: 32, height: 32, marginRight: 1 }} alt={auth && auth.firstname} src={auth && process.env.REACT_APP_BASE_URL + auth.Image}>{auth && auth.firstname && auth.firstname.split('')[0][0]} </Avatar>
									<Typography component='div' variant='body1' className='fz-13 name'>{auth && auth.firstname}</Typography>
									{/* <ArrowDropDown sx={{ marginTop: -0.4, fontSize: 18, color: '#222' }} /> */}
								</IconButton>
							</Box>
						</>
					}
					{/* <Select
						className='acct-menu select'
						anchorEl={anchorEl}
						id="account-menu"
						open={openAcct}
						onChange={handleClose}
						onClick={() => setAnchorEl(false)}
						// MenuProps={MenuProps}
					
						transformOrigin={{ horizontal: 'right', vertical: 'top' }}
						anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					>
						{auth && auth.role_id == 1 ?
							null : <MenuItem className='fz-14 appbarselect' value={'profile'}>
								<PersonAddAlt1Rounded className='fz-16 mr-5 ' /> My Profile
							</MenuItem>}
						<MenuItem className='fz-14 appbarselect' value={'logout'}>
							<PowerSettingsNew className='fz-16 mr-5' /> Logout
						</MenuItem>

					</Select> */}
					<Menu
						className='acct-menu select'
						anchorEl={anchorEl}
						id="account-menu"
						open={openAcct}
						// onChange={handleClickmenu}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
						onClose={handleCloseMenu}
					>
					{auth && auth.role_id == 1 ? null :	<MenuItem className='fz-14 appbarselect' value={'profile'}  onClick={handleClickmenu}>My Profile</MenuItem>}
						<MenuItem className='fz-14 appbarselect' value={'logout'} onClick={handleClicklogout} >Logout</MenuItem>
					</Menu>


					<IconButton
						aria-label="open drawer"
						edge="end"
						onClick={() => showAccountMenu()}
						// sx={{ ...(open && { display: 'none' }) }}
						className='Res_AccountIcon'
					>
						<Share />

					</IconButton>
					<IconButton
						aria-label="open drawer"
						edge="end"
						onClick={() => showSettingsMenu()}
						// sx={{ ...(open && { display: 'none' }) }}
						className='Res_settingsIcon'
					>
						<SyncAlt />

					</IconButton>
					<Box className='res-menu-icon'>
						{['left'].map((anchor) => (

							<React.Fragment key={anchor}>
								<IconButton
									color="inherit"
									aria-label="open drawer"
									edge="end"
									onClick={toggleDrawer_res(anchor)}
									// sx={{ ...(open && { display: 'none' }) }}
									className=''
								>
									<MenuIcon />
								</IconButton>
								<SwipeableDrawer
									anchor={anchor}
									open={state[anchor]}
									onClose={toggleDrawer(anchor, false)}
									onOpen={toggleDrawer(anchor, true)}
									className='res-drawer'

								>
									{list(anchor)}
								</SwipeableDrawer>
							</React.Fragment>
						))}
					</Box>
				</Toolbar>
				<Box className='resAccountmenu'>
					<Box className='account'>
						<IconButton
							onClick={handleClick}
							size="small"
							sx={{ ml: 2 }}
							aria-controls={open ? 'account-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							disableRipple={true}
						>
							<Avatar sx={{ width: 32, height: 32, marginRight: 1 }} alt={auth && auth.firstname} src={auth && process.env.REACT_APP_BASE_URL + auth.Image}>{auth && auth.firstname && auth.firstname.split('')[0][0]} </Avatar>
							<Typography component='div' variant='body1' className='fz-13 name'>Super Admin</Typography>
							<ArrowDropDown sx={{ marginTop: -0.4, fontSize: 18, color: '#222' }} />
						</IconButton>
					</Box>

				</Box>
			</AppBar>
			<Drawer variant="permanent" open={open} className='Custom_drawer'>
				<Box className={open ? 'menuicon-box' : 'menuIcon-boxClosed'}>
					{open ? <Typography component='h4' variant='body1' className='fz-12'>Main</Typography> : null}
					<IconButton onClick={handleDrawerToggle}>
						<MenuOpen className='menuicon' />
					</IconButton>
				</Box>
				<List>
					{sidebar_Items.map((text, index) => (
						text &&
						<ListItem key={index} disablePadding sx={{ display: 'block' }} selected={selectedIndex === index}>
							<ListItemButton
								sx={{
									minHeight: 44,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
									fontSize: 13,
									paddingLeft: 4
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
										color: 'white',
										marginRight: 2
									}}
									onClick={() => PageNavigation(text.link)}
								>
									{text.icon}
								</ListItemIcon>
								<ListItemText primary={text.name} sx={{ opacity: open ? 1 : 0 }} onClick={() => PageNavigation(text.link)} className='sidebar_itemText' />
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Drawer>
			<DrawerHeader />
		</Box >
	);
}
