import { Box, Grid, Typography } from '@mui/material'
import * as React from 'react';
import Usercomplaint from './usercomplaint'
import Admincomplaint from './admincomplaint'



export default function Dashboard() {
    const auth = JSON.parse(localStorage.getItem("userData"))
    return (
      <React.Fragment>
      {auth && auth.role_id ===3 ?<Usercomplaint/>:<Admincomplaint/>}
      </React.Fragment>
    )
}