import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { Button, ClickAwayListener, FormControl, Grid, FormGroup, Tooltip, Grow, InputAdornment, MenuItem, MenuList, Popper, TextField, FormControlLabel } from '@mui/material';
import { ArrowDropDown, BorderColorOutlined, DeleteOutlineRounded, HighlightOffRounded, PlaylistAddRounded, RepeatRounded, SearchOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { HexColorPicker } from 'react-colorful';
import useClickOutside from '../useClickOutside';
import axios from 'axios';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Info } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { header, headerformdata } from "../../../../components/axiousheader"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function DataTable() {
    const useStyles = styled(theme => ({
        arrow: {
            "&:before": {
                border: "1px solid #E6E8ED"
            },
            color: '#4A4A4A',
            curser: "pointer"
        },
        tooltip: {
            backgroundColor: "#4A4A4A",
            border: "1px solid #E6E8ED",
            color: "#4A4A4A"
        }
    }));
    let classes = useStyles();
    const auth = JSON.parse(localStorage.getItem("userData"))
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [editModal, seteditModal] = React.useState(false);
    const popover = React.useRef();
    const [isOpen, toggle] = React.useState(false);
    const [count, setCount] = React.useState(0)
    const [addModal, setaddModal] = React.useState(false);
    const close = React.useCallback(() => toggle(false), []);
    useClickOutside(popover, close);
    const [editdata, setEditdada] = React.useState({})
    const [role, setRole] = React.useState('')
    const [receiveComplaint, setReceiveComplaint] = React.useState(false)
    const [AddComplaint, setAddComplaint] = React.useState(false)
    const [ViewComplaint, setViewComplaint] = React.useState(false)
    const [DeleteComplaint, setDeleteComplaint] = React.useState(false)
    const [ShowDashboard, setShowDashboard] = React.useState(false)
    const [AddUserMangement, setAddUserMangement] = React.useState(false)
    const [ViewUserMangement, setViewUserMangement] = React.useState(false)
    const [DeleteUserMangement, setDeleteUserMangement] = React.useState(false)

    const [ShowSettings, setShowSettings] = React.useState(false)
    const [ViewNotification, setViewNotification] = React.useState(false)
    const [roleDetails, setRoleDetails] = React.useState([])
    const [sortorder, setSortorder] = React.useState({ type: -1, column: "createdAt" })
    const [search, setSearch] = React.useState('')
    React.useEffect(() => {
        getRoles()
        getSortOrder()
    }, [search, page, rowsPerPage, sortorder.type, sortorder.column])

    const OpenaddModal = () => {
        setaddModal(true);
    };

    const [error, setError] = React.useState({ role: "" })
    const validate = () => {
        if (!role) {
            setError({ ...error, role: "Please add Role" })
            return false
        }
        return true
    }

    const getSortOrder = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `order/GetOrder/${auth.company_id}`,).then((res) => {
            console.log(res.data, 'gggg');
            // setSortorder({ type: res.data.order.userrole_order, column: res.data.order.userrole_name })
            setSortorder({ type: res.data.order.userrole_order && res.data.order.userrole_order ? res.data.order.userrole_order : 1, column: res.data.order.userrole_name && res.data.order.userrole_name ? res.data.order.userrole_name : "createdAt" })
        }).catch((err) => console.log(err))
    }
    console.log(sortorder, 'sortorder');

    const submitRole = () => {
        if (
            !ShowDashboard &&
            !AddComplaint &&
            !ViewComplaint &&
            !ViewNotification &&
            !ShowSettings &&
            !receiveComplaint &&
            !AddUserMangement &&
            !ViewUserMangement &&
            !DeleteUserMangement
        ) {
          
            enqueueSnackbar("Please select at least one checkbox.", { variant: 'error' });
            return;
        }
        var valid = validate()
        if (valid == true) {
            const body = {
                user_role: role,
                receive_complaint: receiveComplaint,
                corporateid: auth.company_id,
                Complaints: {
                    addComplaints: AddComplaint,
                    viewComplaints: ViewComplaint,
                    deleteComplaints: DeleteComplaint
                },
                UserMangement: {
                    addUserMangement: AddUserMangement,
                    viewUserMangement: ViewUserMangement,
                    deleteUserMangement: DeleteUserMangement
                },
                ViewNotification: ViewNotification,
                Dashboard:ShowDashboard,
                Settings:ShowSettings


            }
            axios.post(process.env.REACT_APP_BASE_URL + 'userrole/AddUserrole', body,).then((res) => {
                enqueueSnackbar("Role added successfully", { variant: 'success' })
                getRoles()
                setaddModal(false);
                setRole('')
                setReceiveComplaint(false)
            }).catch((err) => {
                console.log(err)
                enqueueSnackbar(err.response.data.message, { variant: 'error' })
            })
        }
    }

    const CloseaddModal = () => {
        setaddModal(false);
    };

    const OpeneditModal = (data) => {
        console.log(data,"data");
        setAddComplaint(data.Complaints.addComplaints)
        setViewComplaint(data.Complaints.viewComplaints)
        setShowDashboard(data.Dashboard)
        setShowSettings(data.Settings)
        setAddUserMangement(data.UserMangement.addUserMangement)
        setViewUserMangement(data.UserMangement.viewUserMangement)
        setDeleteUserMangement(data.UserMangement.deleteUserMangement)
        setViewNotification(data.ViewNotification)
        setEditdada(data)
        seteditModal(true);
    };
    const CloseeditModal = () => {
        seteditModal(false);
        setDeletemodel(false)

    };




    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const body = auth && auth.role_id == 1 ? [auth.company_id] : [auth.superAdminId, auth.company_id]

    const getRoles = () => {
        axios.post(process.env.REACT_APP_BASE_URL + `userrole/GetUserrole?page=${page}
        &size=${rowsPerPage}&search=${search}&sortColumn=${sortorder.column}&sortType=${sortorder.type}`, body,).then((res) => {
            var value = { val: false }
            res.data.userrole && res.data.userrole.map((item, i) => {
                Object.assign(item, value)
            })
            if(auth.role_id != 1) {
                let abc = res.data.userrole.filter(item => item._id != '6593da44f681835b44699c1b')
                
                setRoleDetails(abc)
            setCount(res.data.totalcount)
            } else {
                setRoleDetails(res.data.userrole)
                setCount(res.data.totalcount)
            }
        //    setRoleDetails(res.data.userrole)
         //   setCount(res.data.totalcount)
        }).catch((err) => console.log(err))
    }


    console.log(roleDetails,"roleedetttttttttttttttttttttttttttt");

    const [editRole, setEditRole] = React.useState()
    const [editreceiveCmplaint, setEditReceiveComplaint] = React.useState('')

    const [deletemodel, setDeletemodel] = React.useState(false)
    const [deleteid, setDeleteid] = React.useState(false)

    const submitEditdata = () => {
        var body = {
            user_role: editRole ? editRole : editdata.user_role,
            receive_complaint: editreceiveCmplaint !== '' ? editreceiveCmplaint : editdata.receive_complaint,
            corporateid: editdata && editdata.corporateid,
            Complaints: {
                addComplaints: AddComplaint,
                viewComplaints: ViewComplaint,
                deleteComplaints: DeleteComplaint
            },
            UserMangement: {
                addUserMangement: AddUserMangement,
                viewUserMangement: ViewUserMangement,
                deleteUserMangement: DeleteUserMangement
            },
            ViewNotification: ViewNotification,
            Dashboard:ShowDashboard,
            Settings:ShowSettings
        }
        axios.put(process.env.REACT_APP_BASE_URL + `userrole/Edituserrole/${editdata._id}`, body,).then((res) => {
            seteditModal(false)
            getRoles()
            enqueueSnackbar("Role updated successfully", { variant: "success" })
            setEditRole()
            setEditReceiveComplaint('')
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar(err.response.data.message, { variant: 'error' })
        })
    }

    const handleDeleteData = (id) => {
        setDeletemodel(true)
        setDeleteid(id)
    }

    const DeleteSubmit = () => {
        axios.put(process.env.REACT_APP_BASE_URL + `userrole/DeleteUserrole/${deleteid}`,).then((res) => {
            setDeletemodel(false)
            getRoles()
            enqueueSnackbar("Role deleted successfully", { variant: "success" })
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar(err.response.data.message, { variant: 'error' })
        })
    }

    const [deleteid1, setDeleteid1] = React.useState([])
    const [deletearry, setDeleteArray] = React.useState([])
    const [isCheckAll, setIsCheckAll] = React.useState(false);

    const CheckChange = (e, id, index) => {
        var newarray = [];
        var newdata = []
        if (e.target.checked == true) {
            roleDetails && roleDetails.map((item, i) => {
                if (i == index) {
                    item.val = e.target.checked
                }
                if (item.corporateid !== auth.company_id) {

                    newdata.push(item.corporateid)
                    // if (datacom.length == deleteid1.length + 1) {
                    //     setIsCheckAll(true)
                    //    }

                }
                if (i > newdata.length - 1) {
                    newarray.push(item.val)
                }
                if (newarray.includes(false)) {
                    setIsCheckAll(false)
                } else {
                    setIsCheckAll(true)
                }
            })
            setDeleteid1(pre => pre.concat({ id: id, }))
            deletearry.push(id)

        }
        else {
            roleDetails && roleDetails.map((item, i) => {
                if (i == index) {
                    item.val = e.target.checked
                }
            })
            var data = deleteid1.filter(item1 => item1.id != id)
            var data1 = deletearry.filter((ids) => ids != id)
            setDeleteid1(data)
            setDeleteArray(data1)
            setIsCheckAll(false)
        }
    }
    const checkAll = (e) => {
        setIsCheckAll(!isCheckAll)
        if (e.target.checked == true) {
            roleDetails && roleDetails.map((item, i) => {
                item.val = e.target.checked
                if (item.corporateid == auth.company_id) {
                    setDeleteid1(pre => pre.concat({ id: item._id, }))
                    deletearry.push(item._id)
                }
            })
        }
        else {
            roleDetails && roleDetails.map((item, i) => {
                item.val = e.target.checked
            })
            setDeleteid1([])
            setDeleteArray([])

        }
    }
    const [bulkmodel, setBulkmodel] = React.useState(false)

    const bulkDelete = () => {
        setBulkmodel(true)
    }
    const handlebulkDelete = () => {
        var body = {
            ids: deletearry
        }
        axios.post(process.env.REACT_APP_BASE_URL + `userrole/multipleDelete`, body,).then((res) => {
            setBulkmodel(false)
            getRoles()
            enqueueSnackbar("Role deleted successfully", { variant: "success" })
            setDeleteArray([])
            setDeleteid1([])
            setIsCheckAll(false)
        }).catch((err) => {
            console.log(err)
            enqueueSnackbar(err.response.data.message, { variant: 'error' })
        })
    }

    const handleTostmessage = () => {
        enqueueSnackbar("Unable to delete default data", { variant: "warning" })
    }
    const handleTostforEdit = () => {
        enqueueSnackbar("Unable to edit default data", { variant: "warning" })
    }
    const handleSavedOrder = () => {
        var body = {
            userrole_order: -1,
            userrole_name: 'createdAt',
            company_id: auth.company_id
        }
        axios.post(process.env.REACT_APP_BASE_URL + `order/addOrder`, body,).then((res) => {
            console.log(res.data);
            getSortOrder()
        }).catch((err) => console.log(err))
    }

    const handleAlphabeticalOrder = () => {
        var body = {
            userrole_order: 1,
            userrole_name: 'user_role',
            company_id: auth.company_id
        }
        axios.post(process.env.REACT_APP_BASE_URL + `order/addOrder`, body,).then((res) => {
            console.log(res.data);
            getSortOrder()
        }).catch((err) => console.log(err))
    }
console.log(editdata,"000000");

    return (
        <Box sx={{ width: '100%' }}>
            <Box className='headingBox'>
                <Typography component='h4' variant='h4' className='Submenu-page-heading'>User Role</Typography>
                {auth.role_id != 1 &&
                <Box className='heading-btns'>
                    {sortorder && sortorder.type == -1 ? <Button variant="contained" className='addbtn mr-10' id='disablebtn' onClick={handleSavedOrder} disabled>Saved Order</Button> : <Button variant="contained" className='addbtn mr-10' onClick={handleSavedOrder}>Saved Order</Button>}
                    {sortorder && sortorder.type == 1 ? <Button variant="contained" className='addbtn' id='disablebtn' onClick={handleAlphabeticalOrder} disabled> Save in Alphabetical Order</Button> : <Button variant="contained" className='addbtn' onClick={handleAlphabeticalOrder}> Save in Alphabetical Order</Button>}
                    {deleteid1 && deleteid1.length > 0 && auth.role_id !=1 ? <Button variant="contained" className='' style={{
                        float: 'right', background: "#f64e60", marginLeft: 10, boxShadow: 'none',
                        height: 34,
                        textTransform: 'capitalize'
                    }} onClick={bulkDelete} >Delete</Button> : ""}
                </Box>}
            </Box>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='settings-Datatable'>
            {auth.role_id != 1 &&<Toolbar className='toolbar'
                    >
{/* 
                     //   {auth&& auth.role_id !=1 && */}
                    {
                      auth&& auth.role_id !=1 &&  
                        <Button variant="text" className='addbtn-modal' onClick={OpenaddModal}>
                        <PlaylistAddRounded className='mr-10 fz-18' />Add User Role
                        
                    </Button>}
                        
                    <Box component='div' className='DTsearch'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10' >Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                className='textfield search '
                                placeholder='Search Here...'
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                                }}
                            />
                        </FormControl>
                    </Box>
                </Toolbar>}
                <TableContainer component={Paper} >
                    <Table aria-label="customized table" className='table'>
                        <TableHead className='table-head'>
                            <TableRow className='table-row'>
                                <StyledTableCell align="center" width={75} className='checkbox'><FormControlLabel control={<Checkbox onChange={checkAll} checked={isCheckAll} />} /></StyledTableCell>
                                <StyledTableCell align="left" sx={{ width: 200, minWidth: 200 }} className='semibold'><strong>Role</strong></StyledTableCell>
                                <StyledTableCell align="left" sx={{ width: 200, minWidth: 200 }} className='semibold'><strong>Receive Complaint</strong></StyledTableCell>


                                <StyledTableCell align="center" className='semibold'>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {roleDetails && roleDetails.length > 0 ? roleDetails.map((item, i) => (
                               // item._id != '6593da44f681835b44699c1b' &&
                                <StyledTableRow className='tbody-row' key={i}>
                                    <StyledTableCell align="center" className='checkbox DT-row' >
                                        {auth && auth.company_id === item.corporateid ? <FormGroup>
                                            <FormControlLabel style={{ justifyContent: "center" }} control={<Checkbox onChange={(e) => CheckChange(e, item._id, i)} checked={item && item.val} size='medium' />} />
                                        </FormGroup> : <FormGroup>
                                            <FormControlLabel style={{ justifyContent: "center" }} control={<Checkbox disabled size='medium' />} />
                                        </FormGroup>}
                                    </StyledTableCell>
                                    <StyledTableCell className='DT-row' align="left" >
                                        <Typography className='fz-14'>{item.user_role}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className='DT-row' align="left" >
                                        <Typography className='fz-14'>{item.receive_complaint ? item.receive_complaint == true ? 'Yes' : item.receive_complaint == false : "No"}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className='DT-row' align="center" >
                                        {auth && auth.company_id === item.corporateid && auth.role_id !=1 ? <Box className='actionbtns'>
                                            <Tooltip title="Update" arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}><BorderColorOutlined style={{ cursor: 'pointer' }} className='edit-icon mr-10' onClick={() => OpeneditModal(item)} /></Tooltip>
                                            <Tooltip title="Update" arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}><DeleteOutlineRounded style={{ cursor: 'pointer' }} className='delete-icon' onClick={() => handleDeleteData(item._id)} /></Tooltip>
                                        </Box> : <Box className='actionbtns'>
                                            
                                            <Tooltip title="Update" arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}><BorderColorOutlined style={{ cursor: 'pointer' }} className='edit-icon-disable mr-10' onClick={handleTostforEdit} /></Tooltip>
                                            <Tooltip title="Update" arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}><DeleteOutlineRounded style={{ cursor: 'pointer' }} className='delete-icon-disable' onClick={handleTostmessage} /></Tooltip>
                                        </Box>}
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : <StyledTableCell colSpan={4} className='nodata' > NO DATA FOUND </StyledTableCell>}
                        </TableBody>
                    </Table>

                    <BootstrapDialog
                        onClose={CloseaddModal}
                        aria-labelledby="customized-dialog-title"
                        open={addModal}
                        className='addRoleForm_modal'>
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={CloseaddModal} className='modal-title'>Add User Role</BootstrapDialogTitle>
                        <DialogContent dividers>
                            <Grid container columnSpacing={2} rowSpacing={2}>
                                <Grid item xs={12} sm={12} md={4} >
                                    <Typography component='div' className='label pt-10'>Role name</Typography>

                                   
                                </Grid>
                                
                                <Grid item xs={12} sm={12} md={8}>

                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            size="small"
                                            className='textfield'
                                            placeholder='Ex=Developer'
                                            onChange={(e) => setRole(e.target.value)}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            }}
                                        />
                                        {error.role && <p className='error-field'>{error.role}</p>}
                                    </FormControl>
                                    </Grid>
                                {auth.role_id !=1 && <>
                               
                                <Grid item xs={12} sm={12} md={8}>
                                    <FormGroup>
                                        <FormControlLabel onChange={(e) => setReceiveComplaint(e.target.checked)} control={<Checkbox size='medium' defaultChecked={receiveComplaint} />} label={<Typography component='div' className='fz-14 flex-box' >Receive All complaint<Typography component='span'><Tooltip title="This role can have special privileges to receive complaint from  all the user from the company" ><Info style={{ color: '#037bd7', width: '18px', marginLeft: '5px', marginBottom: '0px' }} /></Tooltip></Typography></Typography>} />
                                      
                                    </FormGroup>
                                </Grid>


                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography component='div' className='label'>Modules</Typography>

                                    <FormGroup>
                                        <Box className='eachmodule'>
                                            <Typography component='h6' variant='h6'>Dashboard</Typography>
                                            <Box className='flex-center'>
                                            <FormControlLabel className='flex-col-rev' name="receive" onChange={(e) => setShowDashboard(e.target.checked)} control={<Checkbox size='medium'   checked={ShowDashboard} />} label={<Typography component='div' className='fz-14 flex-box' >Show<Typography component='span'></Typography></Typography>} />
                                                {/* <FormControlLabel className='flex-col-rev' name="receive" onChange={(e) => setViewNotification(e.target.checked)} control={<Checkbox size='medium' checked={ViewNotification} />} label={<Typography component='div' className='fz-14 flex-box' >View Notification<Typography component='span'></Typography></Typography>} /> */}
                                            </Box>
                                        </Box>
                                        <Box className='eachmodule'>
                                            <Typography component='h6' variant='h6'>Complaint</Typography>
                                            <Box className='flex-center'>
         

                                                  <FormControlLabel className='flex-col-rev'name="receive"onChange={(e) => {setAddComplaint(e.target.checked);setViewComplaint(e.target.checked); }}control={<Checkbox size='medium' checked={AddComplaint} />}label={<Typography component='div' className='fz-14 flex-box'>Add/Edit <Typography component='span'></Typography></Typography>}/>

                                                <FormControlLabel className='flex-col-rev' name="receive" onChange={(e) => setViewComplaint(e.target.checked)} control={<Checkbox size='medium'   checked={ViewComplaint} />} label={<Typography component='div' className='fz-14 flex-box' >View <Typography component='span'></Typography></Typography>} />

                                              
                                            </Box>
                                        </Box>
                                        <Box className='eachmodule'>
                                            <Typography component='h6' variant='h6'>User Mangement</Typography>
                                            <Box className='flex-center'>
                                            <FormControlLabel
                                                className='flex-col-rev'
                                                name="receive"
                                                onChange={(e) => {
                                                    setAddUserMangement(e.target.checked);
                                                    setViewUserMangement(e.target.checked);
                                                }}
                                                control={<Checkbox size='medium' checked={AddUserMangement} />}
                                                label={
                                                    <Typography component='div' className='fz-14 flex-box'>
                                                    Add/Edit <Typography component='span'></Typography>
                                                    </Typography>
                                                }
                                                />

                                                <FormControlLabel className='flex-col-rev' name="receive" onChange={(e) => setViewUserMangement(e.target.checked)} control={<Checkbox size='medium'   checked={ViewUserMangement} />} label={<Typography component='div' className='fz-14 flex-box' >View <Typography component='span'></Typography></Typography>} />
                                                <FormControlLabel
                                                className='flex-col-rev'
                                                name="receive"
                                                onChange={(e) => {
                                                    setDeleteUserMangement(e.target.checked);
                                                    setViewUserMangement(e.target.checked);
                                                }}
                                                control={<Checkbox size='medium' checked={DeleteUserMangement} />}
                                                label={
                                                    <Typography component='div' className='fz-14 flex-box'>
                                                    Delete <Typography component='span'></Typography>
                                                    </Typography>
                                                }
                                                />

                                            </Box>
                                        </Box>
                                        <Box className='eachmodule'>
                                            <Typography component='h6' variant='h6'>Notifications</Typography>
                                            <Box className='flex-center'>
                                                <FormControlLabel className='flex-col-rev' name="receive" onChange={(e) => setViewNotification(e.target.checked)} control={<Checkbox size='medium'  checked={ViewNotification} />} label={<Typography component='div' className='fz-14 flex-box' >View Notification<Typography component='span'></Typography></Typography>} />
                                            </Box>
                                        </Box>
                                        <Box className='eachmodule'>
                                            <Typography component='h6' variant='h6'>Settings</Typography>
                                            <Box className='flex-center'>
                                                <FormControlLabel className='flex-col-rev' name="receive" onChange={(e) => setShowSettings(e.target.checked)} control={<Checkbox size='medium' checked={ShowSettings} />} label={<Typography component='div' className='fz-14 flex-box' >Show<Typography component='span'></Typography></Typography>} />
                                            </Box>
                                        </Box>

                                    </FormGroup>



                                </Grid>
                                </>
}
                                {/* <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormGroup>
                                            <Typography variant="h6">Complaint Permissions</Typography>
                                            {/* <FormControlLabel
            name="receiveComplaint"
            onChange={(e) => setReceiveComplaint(e.target.checked)}
            control={<Checkbox size='medium' checked={receiveComplaint} />}
            label="Receive Complaint"
          /> -----


                                            <FormControlLabel
                                                name="receiveComplaint"
                                                onChange={(e) => setReceiveComplaint(e.target.checked)}
                                                control={<Checkbox size='medium' checked={receiveComplaint} />}
                                                label={
                                                    <Typography component='div' className='fz-14 flex-box'>
                                                        Receive Complaint
                                                        <Tooltip title="This role can have special privileges to receive complaints from within the company">
                                                            <Info style={{ color: '#037bd7', width: '18px', marginLeft: '5px', marginBottom: '0px' }} />
                                                        </Tooltip>
                                                    </Typography>
                                                }
                                            />

                                            <FormControlLabel
                                                name="addComplaint"
                                                onChange={(e) => setAddComplaint(e.target.checked)}
                                                control={<Checkbox size='medium' checked={AddComplaint} />}
                                                label={
                                                    <Typography component='div' className='fz-14 flex-box'>
                                                        Add/Edit Complaints
                                                        <Tooltip title="This role can have special privileges to add/edit complaints">
                                                            <Info style={{ color: '#037bd7', width: '18px', marginLeft: '5px', marginBottom: '0px' }} />
                                                        </Tooltip>
                                                    </Typography>
                                                }
                                            />

                                            <FormControlLabel
                                                name="viewComplaint"
                                                onChange={(e) => setViewComplaint(e.target.checked)}
                                                control={<Checkbox size='medium' checked={ViewComplaint} />}
                                                label={
                                                    <Typography component='div' className='fz-14 flex-box'>
                                                        View Complaints
                                                        <Tooltip title="This role can have special privileges to view complaints">
                                                            <Info style={{ color: '#037bd7', width: '18px', marginLeft: '5px', marginBottom: '0px' }} />
                                                        </Tooltip>
                                                    </Typography>
                                                }
                                            />

                                            <FormControlLabel
                                                name="deleteComplaint"
                                                onChange={(e) => setDeleteComplaint(e.target.checked)}
                                                control={<Checkbox size='medium' checked={DeleteComplaint} />}
                                                label={
                                                    <Typography component='div' className='fz-14 flex-box'>
                                                        Delete Complaints
                                                        <Tooltip title="This role can have special privileges to delete complaints">
                                                            <Info style={{ color: '#037bd7', width: '18px', marginLeft: '5px', marginBottom: '0px' }} />
                                                        </Tooltip>
                                                    </Typography>
                                                }
                                            />

                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormGroup>
                                            <Typography variant="h6">User Management Permissions</Typography>
                                            <FormControlLabel
                                                name="addUserManagement"
                                                onChange={(e) => setAddUserMangement(e.target.checked)}
                                                control={<Checkbox size='medium' checked={AddUserMangement} />}
                                                label={
                                                    <Typography component='div' className='fz-14 flex-box'>
                                                        Add/Edit User Management
                                                        <Tooltip title="This role can have special privileges to add/edit user management">
                                                            <Info style={{ color: '#037bd7', width: '18px', marginLeft: '5px', marginBottom: '0px' }} />
                                                        </Tooltip>
                                                    </Typography>
                                                }
                                            />

                                            <FormControlLabel
                                                name="viewUserManagement"
                                                onChange={(e) => setViewUserMangement(e.target.checked)}
                                                control={<Checkbox size='medium' checked={ViewUserMangement} />}
                                                label={
                                                    <Typography component='div' className='fz-14 flex-box'>
                                                        View User Management
                                                        <Tooltip title="This role can have special privileges to view user management">
                                                            <Info style={{ color: '#037bd7', width: '18px', marginLeft: '5px', marginBottom: '0px' }} />
                                                        </Tooltip>
                                                    </Typography>
                                                }
                                            />

                                            <FormControlLabel
                                                name="deleteUserManagement"
                                                onChange={(e) => setDeleteUserMangement(e.target.checked)}
                                                control={<Checkbox size='medium' checked={DeleteUserMangement} />}
                                                label={
                                                    <Typography component='div' className='fz-14 flex-box'>
                                                        Delete User Management
                                                        <Tooltip title="This role can have special privileges to delete user management">
                                                            <Info style={{ color: '#037bd7', width: '18px', marginLeft: '5px', marginBottom: '0px' }} />
                                                        </Tooltip>
                                                    </Typography>
                                                }
                                            />

                                        </FormGroup>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormGroup>
                                            <Typography variant="h6">Notification Permissions</Typography>
                                            <FormControlLabel
                                                name="viewNotification"
                                                onChange={(e) => setViewNotification(e.target.checked)}
                                                control={<Checkbox size='medium' checked={ViewNotification} />}
                                                label={
                                                    <Typography component='div' className='fz-14 flex-box'>
                                                        View Notification
                                                        <Tooltip title="This role can have special privileges to view notifications">
                                                            <Info style={{ color: '#037bd7', width: '18px', marginLeft: '5px', marginBottom: '0px' }} />
                                                        </Tooltip>
                                                    </Typography>
                                                }
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={submitRole} className='modalbtn'>Add</Button>
                        </DialogActions>
                    </BootstrapDialog>

                    <BootstrapDialog
                        onClose={CloseeditModal}
                        aria-labelledby="customized-dialog-title"
                        open={editModal}
                        className='addRoleForm_modal'
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={CloseeditModal} className='modal-title'>
                            Update
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                            <Grid container columnSpacing={2} rowSpacing={2}>
                                <Grid item xs={12} sm={12} md={4} >
                                    <Typography component='div' className='label'>Role</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            size="small"
                                            className='textfield'
                                            placeholder='Role'
                                            name='role'
                                            onChange={(e) => setEditRole(e.target.value)}
                                            defaultValue={editdata.user_role}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                
                                <Grid item xs={12} sm={12} md={8}>
                                    <FormGroup>
                                        <FormControlLabel onChange={(e) => setEditReceiveComplaint(e.target.checked)} control={<Checkbox size='medium' defaultChecked={editdata.receive_complaint} />} label={<Typography component='div' className='fz-14 flex-box' >Receive All complaint<Typography component='span'><Tooltip title="This role can have special privileges to receive complaint from  all the user from the company" ><Info style={{ color: '#037bd7', width: '18px', marginLeft: '5px', marginBottom: '0px' }} /></Tooltip></Typography></Typography>} />
                                      
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography component='div' className='label'>Modules</Typography>

                                    <FormGroup>
                                        <Box className='eachmodule'>
                                            <Typography component='h6' variant='h6'>Dashboard</Typography>
                                            <Box className='flex-center'>
                                            <FormControlLabel className='flex-col-rev' name="receive" onChange={(e) => setShowDashboard(e.target.checked)} control={<Checkbox size='medium' defaultChecked={editdata.Dashboard} checked={ShowDashboard} />} label={<Typography component='div' className='fz-14 flex-box' >Show<Typography component='span'></Typography></Typography>} />
                                                {/* <FormControlLabel className='flex-col-rev' name="receive" onChange={(e) => setViewNotification(e.target.checked)} control={<Checkbox size='medium' checked={ViewNotification} />} label={<Typography component='div' className='fz-14 flex-box' >View Notification<Typography component='span'><Tooltip title="This role can have special privileges to receive complaint from with in the company" ><Info style={{ color: '#037bd7', width: '18px', marginLeft: '5px', marginBottom: '0px' }} /></Tooltip></Typography></Typography>} /> */}
                                            </Box>
                                        </Box>
                                        <Box className='eachmodule'>
                                            <Typography component='h6' variant='h6'>Complaint</Typography>
                                            <Box className='flex-center'>
                                            <FormControlLabel
  className='flex-col-rev'
  name="receive"
  onChange={(e) => {
    setAddComplaint(e.target.checked);
    setViewComplaint(e.target.checked);
  }}
  control={<Checkbox size='medium' checked={AddComplaint} />}
  label={
    <Typography component='div' className='fz-14 flex-box'>
      Add/Edit <Typography component='span'></Typography>
    </Typography>
  }
/>

                                                <FormControlLabel className='flex-col-rev' name="receive" onChange={(e) => setViewComplaint(e.target.checked)} control={<Checkbox size='medium'defaultChecked={editdata.receive_complaint} checked={ViewComplaint} />} label={<Typography component='div' className='fz-14 flex-box' >View <Typography component='span'></Typography></Typography>} />
                                            </Box>
                                        </Box>
                                        <Box className='eachmodule'>
                                            <Typography component='h6' variant='h6'>User Mangement</Typography>
                                            <Box className='flex-center'>
                                            <FormControlLabel
                                                    className='flex-col-rev'
                                                    name="receive"
                                                    // onChange={(e) => {
                                                    //     setDeleteUserMangement(e.target.checked);
                                                    //     setViewUserMangement(e.target.checked);
                                                    // }}
                                                    // control={<Checkbox size='medium' defaultChecked={editdata.receive_complaint} checked={DeleteUserMangement} />}
                                                    onChange={(e) => {
                                                        setAddUserMangement(e.target.checked);
                                                        setViewUserMangement(e.target.checked);
                                                    }} control={<Checkbox size='medium' defaultChecked={editdata.receive_complaint} checked={AddUserMangement} />}
                                                    label={
                                                        <Typography component='div' className='fz-14 flex-box'>
                                                        Add/Edit <Typography component='span'></Typography>
                                                        </Typography>
                                                    }
                                                    />
                                                <FormControlLabel className='flex-col-rev' name="receive" onChange={(e) => setViewUserMangement(e.target.checked)} control={<Checkbox size='medium'defaultChecked={editdata.receive_complaint}  checked={ViewUserMangement} />} label={<Typography component='div' className='fz-14 flex-box' >View <Typography component='span'></Typography></Typography>} />
                                                <FormControlLabel
                                                    className='flex-col-rev'
                                                    name="receive"
                                                    onChange={(e) => {
                                                        setDeleteUserMangement(e.target.checked);
                                                        setViewUserMangement(e.target.checked);
                                                    }}
                                                    control={<Checkbox size='medium' defaultChecked={editdata.receive_complaint} checked={DeleteUserMangement} />}
                                                    label={
                                                        <Typography component='div' className='fz-14 flex-box'>
                                                        Delete <Typography component='span'></Typography>
                                                        </Typography>
                                                    }
                                                    />


                                            </Box>
                                        </Box>
                                        <Box className='eachmodule'>
                                            <Typography component='h6' variant='h6'>Notifications</Typography>
                                            <Box className='flex-center'>
                                                <FormControlLabel className='flex-col-rev' name="receive" onChange={(e) => setViewNotification(e.target.checked)} control={<Checkbox size='medium' defaultChecked={editdata.receive_complaint} checked={ViewNotification} />} label={<Typography component='div' className='fz-14 flex-box' >View Notification<Typography component='span'></Typography></Typography>} />
                                            </Box>
                                        </Box>
                                        <Box className='eachmodule'>
                                            <Typography component='h6' variant='h6'>Settings</Typography>
                                            <Box className='flex-center'>
                                                <FormControlLabel className='flex-col-rev' name="receive" onChange={(e) => setShowSettings(e.target.checked)} control={<Checkbox size='medium'defaultChecked={editdata.receive_complaint}  checked={ShowSettings} />} label={<Typography component='div' className='fz-14 flex-box' >Show<Typography component='span'></Typography></Typography>} />
                                            </Box>
                                        </Box>

                                    </FormGroup>



                                </Grid>
                                
                            </Grid>
                        </DialogContent>
                        <DialogActions><Button autoFocus onClick={submitEditdata} className='modalbtn'><RepeatRounded className='fz-18' style={{ marginRight: 6 }} /> Update</Button></DialogActions>
                    </BootstrapDialog>

                    <BootstrapDialog
                        onClose={CloseeditModal}
                        aria-labelledby="customized-dialog-title"
                        open={deletemodel}
                        className='addForm-modal'
                    >
                        <DialogContent className='deleteConfirmModal'>
                            <HighlightOffRounded className='delIcon' />
                            <Typography component='div' className='text'>Are you sure want to delete?</Typography>
                        </DialogContent>
                        <DialogActions className='deleteModalactbtns'>
                            <Button autoFocus onClick={() => setDeletemodel(false)} className='modalbtn cancel'>
                                Cancel</Button>
                            <Button autoFocus onClick={DeleteSubmit} className='modalbtn delete'>
                                Delete</Button>
                        </DialogActions>
                    </BootstrapDialog>
                    <BootstrapDialog
                        onClose={CloseeditModal}
                        aria-labelledby="customized-dialog-title"
                        open={bulkmodel}
                        className='addForm-modal'
                    >
                        <DialogContent className='deleteConfirmModal'>
                            <HighlightOffRounded className='delIcon' />
                            <Typography component='div' className='text'>Are you sure want to delete?</Typography>
                        </DialogContent>
                        <DialogActions className='deleteModalactbtns'>
                            <Button autoFocus onClick={() => setBulkmodel(false)} className='modalbtn cancel'>
                                Cancel</Button>
                            <Button autoFocus onClick={handlebulkDelete} className='modalbtn delete'>
                                Delete</Button>
                        </DialogActions>
                    </BootstrapDialog>
                </TableContainer>
                {auth.role_id != 1 &&
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />}
            </Paper>

        </Box>
    );
}