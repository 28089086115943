import { Box, Grid, Typography, Select, MenuItem, FormControl, TextField } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as React from 'react';
import MiniDrawer from '../../../components/drawer'
import ReactEcharts from "echarts-for-react";
import axios from 'axios';
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { header, headerformdata } from "../../../components/axiousheader"

const companiesChartOptions = {
    tooltip: {
        trigger: 'axis'
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: {
        type: 'category',
        data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        boundaryGap: false,
    },
    yAxis: {
        type: 'value',
        splitNumber: 10,

    },

    series: [
        {
            data: [2, 5, 4, 6, 8],
            label: {
                show: true,
                position: 'top'
            },
            type: 'line',
            smooth: true,
            areaStyle: {
                opacity: 0.8,
                // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //     {
                //         offset: 0,
                //         color: '#0258FF'
                //     },
                //     {
                //         offset: 0.5,
                //         color: '#CCDEFF'
                //     },
                //     {
                //         offset: 1,
                //         color: '#fff'
                //     }
                // ])
            },
        }
    ]

};





const mostComplainsOptions = {
    tooltip: {
        trigger: 'item'
    },
    legend: {
        // orient: 'vertical',
        // top: 20,
        bottom: 20,
        //left: 'middle',
        icon: 'circle',
    },
    series: [
        {
            name: 'Complained Against',
            type: 'pie',
            radius: '50%',
            color: ['#004B8D', '#1F40C9', '#0258FF', '#CCDEFF', '#FFBF00',],
            label: { "formatter": (params) => params.value },
            data: [
                { value: 90, name: 'ABC' },
                { value: 450, name: 'XYZ' },
                { value: 300, name: 'POQ' },
                { value: 221, name: 'MNO' },
                { value: 129, name: 'CDE' }
            ],
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};


const seriesLabel = {
    show: true,
    position: 'right',
};

export default function Admin() {

    const auth = JSON.parse(localStorage.getItem("userData"))
    const [piedata, setPiedata] = React.useState([])
    const [companyregister, setCompanyregiste] = React.useState([])
    const [complaitype, setComplaitype] = React.useState([])
    const [lessthansevendays, setLessthansevendays] = React.useState([])
    const [sevenTofourteen, setSevenTofourteen] = React.useState([])
    const [fifteenTotwentytwo, setFifteenTotwentytwo] = React.useState([])
    const [twentythreetothirty, setTwentythreetothirty] = React.useState([])
    const [notclosed, setNotclosed] = React.useState([])
    let navigate = useNavigate()
    const hhh = localStorage.getItem("token")
    console.log(hhh, "headerheader");

    //For COPMPALINT TYPE
    const [dateforcomptype, setDateforcomptype] = React.useState('')
    const [dateforcomptypeto, setDateforcomptypeto] = React.useState('')

    //date filter for Resolve
    const [resolvedateFrom, setresolvedateFrom] = React.useState('')
    const [resolvedateTo, setresolvedateTo] = React.useState('')

    const [selectdayforgroup, setSelectDayforgroup] = React.useState("all")
    const [fromdate, setFromdate] = React.useState(moment().startOf('year').toDate());
    const [todate, setTodate] = React.useState(moment().endOf('year').toDate());
    const gettest = () => {
        header.Authorization = localStorage.getItem("token")
        axios.get(process.env.REACT_APP_BASE_URL + `complainpage/CompanyOwnership/${auth && auth.company_id}`,).then((res) => {
            setPiedata(res.data.data)
            console.log(res, "piechart")
        }).catch((err) => console.log(err))
    }

    const getCompaniesRegistered = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `complainpage/complainResolverApiforAdmin/${auth && auth.company_id}?userid=${auth && auth._id}&fromdate=${fromdate}&todate=${todate}`,).then((res) => {
            setCompanyregiste(res.data.data)
            console.log(res, "companiesss")
        }).catch((err) => console.log(err))
    }


    const selectDayforgroup = (e) => {
        setSelectDayforgroup(e.target.value)
        switch (e.target.value) {
            case 'week':
                setFromdate(moment().startOf('week').toDate())
                setTodate(moment().endOf('week').toDate())
                break;
            case 'month':
                setFromdate(moment().startOf('month').toDate())
                setTodate(moment().endOf('month').toDate())
                break;
            case 'year':
                setFromdate(moment().startOf('year').toDate())
                setTodate(moment().endOf('year').toDate())
                break;
            default:
        }
    }
    // complain type 
    const getComplaintType = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `complaintype/ComplainsAdmin/${auth && auth.company_id}?userid=${auth && auth._id}&fromdate=${fromdate}&todate=${todate}`,).then((res) => {
            setComplaitype(res.data.data)
            console.log(res, "complaintype");
        }).catch((err) => console.log(err))
    }

    const getDaysGraph = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `complainpage/daystoclosegraphforadmin/${auth && auth.company_id}?userid=${auth && auth._id}`,).then((res) => {
            console.log(res.data, 'days to close API');
            {
                res.data.series && res.data.series.map((item, i) => {
                    if (item.name == 'less than 7') {
                        setLessthansevendays(item.data)
                    }
                    if (item.name == '7-14') {
                        setSevenTofourteen(item.data)
                    }
                    if (item.name == '15-22') {
                        setFifteenTotwentytwo(item.data)
                    }
                    if (item.name == '23-30 days') {
                        setTwentythreetothirty(item.data)
                    }
                    if (item.name == 'Not Closed') {
                        setNotclosed(item.data)
                    }
                })
            }
        }).catch((err) => console.log(err))
    }

    React.useEffect(() => {
        gettest()
        getCompaniesRegistered()
        getComplaintType()
        getDaysGraph()
        getComplaines()
    }, [resolvedateFrom, resolvedateTo, dateforcomptype, dateforcomptypeto, fromdate, todate])


    const closingComplainsOptions = {
        color: ['#004B8D', '#1F40C9', '#0258FF', '#CCDEFF', '#FFBF00'],
        legend: {
            data: ['Closed in < 7days', '7-14', '15-22', '23-30 days', 'Not Closed'],
            bottom: 0,
            icon: 'circle',
            "formatter": (name) => { return name + '   ' },
        },
        grid: {
            left: 85,
            bottom: 100
        },
        xAxis: {
            type: 'value',
            // axisLabel: {
            //   formatter: '{value}'
            // }
            splitNumber: 2
        },
        yAxis: {
            type: 'category',
            inverse: true,
            data: ['Verbal', 'Abusive', 'Sexual', 'Harassment', 'Disrespectful', 'Others'],
        },
        series: [
            {
                name: 'Closed in < 7days',
                type: 'bar',
                data: lessthansevendays,
                label: seriesLabel,
                barGap: '35%',
                itemStyle: {
                    emphasis: {
                        barBorderRadius: [50, 50]
                    },
                    normal: {
                        barBorderRadius: [0, 6, 6, 0]
                    }
                }
            },
            {
                name: '7-14',
                type: 'bar',
                label: seriesLabel,
                barGap: '35%',
                data: sevenTofourteen,
                itemStyle: {
                    emphasis: {
                        barBorderRadius: [50, 50]
                    },
                    normal: {
                        barBorderRadius: [0, 6, 6, 0]
                    }
                }
            },
            {
                name: '15-22',
                type: 'bar',
                label: seriesLabel,
                barGap: '35%',
                data: fifteenTotwentytwo,
                itemStyle: {
                    emphasis: {
                        barBorderRadius: [50, 50]
                    },
                    normal: {
                        barBorderRadius: [0, 6, 6, 0]
                    }
                }
            },
            {
                name: '23-30 days',
                type: 'bar',
                data: twentythreetothirty,
                label: seriesLabel,
                barGap: '35%',
                itemStyle: {
                    emphasis: {
                        barBorderRadius: [50, 50]
                    },
                    normal: {
                        barBorderRadius: [0, 6, 6, 0]
                    }
                }
            },
            {
                name: 'Not Closed',
                type: 'bar',
                label: seriesLabel,
                barGap: '35%',
                data: notclosed,
                itemStyle: {
                    emphasis: {
                        barBorderRadius: [50, 50]
                    },
                    normal: {
                        barBorderRadius: [0, 6, 6, 0]
                    }
                }
            },

        ]
    }
    // const handlecopmlainttypefrom = (e) => {
    //     setDateforcomptype(e)
    // }
    // const handlecopmlainttypeto = (e) => {
    //     setDateforcomptypeto(e)
    // }

    const complainsTypeOptions = {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            left: 'center',
            top: 'bottom',
            icon: 'pin',
            textStyle: {
                fontFamily: 'Nunito',
                lineHeight: 24,
            },
            "formatter": (name) => { return name + '   ' },
        },
        series: [
            {
                name: 'Complains Type',
                type: 'pie',
                radius: ['10%', '60%'],
                // center: ['50%', '50%'],
                roseType: 'radius',
                itemStyle: {
                    borderRadius: 5
                },
                color: ['#004B8D', '#0258FF', '#f50202', '#FFBF00', '#CCDEFF', '#1F40C9'],
                label: {
                    show: true,
                    "formatter": (params) => params.value,
                    textStyle: {
                        fontFamily: 'Nunito',
                    }
                },
                emphasis: {
                    label: {
                        show: true
                    }
                },
                data: complaitype.sort(function (a, b) {
                    return a.value - b.value;
                }),
            },
        ]
    };
    // const handleresolveFromcomplain = (e) => {
    //     setresolvedateFrom(e)

    // }

    // const handleresolveTocomplain = (e) => {
    //     setresolvedateTo(e)
    // }

    //Allfromdate
    const handleAllFromDate = (e) => {
        // setresolvedateFrom(e)
        setFromdate(e)
        // setDateforcomptype(e)
    }

    //Alltodate
    const handleAllTodate = (e) => {
        // setresolvedateTo(e)
        setTodate(e)
        // setDateforcomptypeto(e)
    }


    const complainsResolvedOptions = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: 'left',
            icon: 'circle',
            textStyle: {
                fontWeight: 700,
                fontFamily: 'Nunito',
            },
        },
        series: [
            {
                name: 'Open Vs Resolved',
                type: 'pie',
                radius: ['40%', '60%'],
                // center: ['35%', '50%'],
                avoidLabelOverlap: false,
                color: ['#0258FF', '#CCDEFF'],
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '22',
                        fontWeight: 'bold',
                        fontFamily: 'Nunito',
                    }
                },
                labelLine: {
                    show: false
                },
                data: companyregister
            }
        ]
    };

    const ownershipChartOptions = {
        color: ['#004B8D', '#1F40C9', '#0258FF', '#CCDEFF', '#FFBF00'],
        tooltip: {
            trigger: 'item',
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            top: '20',
            itemStyle: {
            },
            textStyle: {
                fontWeight: 700,
                fontFamily: 'Nunito',
            },
            icon: 'pin',

        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['50%', '70%'],
                data: piedata,
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '22',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },

            }
        ]
    };
    React.useEffect(() => {
        getCount()
        getComplaines()
        getRecentComplaint()
        getrecentActivity()
        // getCompaniesRegistered()
    }, [])



    const [countvaalues, setCount] = React.useState({ verbalcomplains: 0, sexualcomplains: 0, complains: 0, resolvecomplains: 0 })
    var complainsmonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    var complainesvalues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

    const [comlaines, setComlaines] = React.useState([])

    const getComplaines = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `complaintype/complaingraphforcompany?corporateid=${auth && auth.company_id}&userid=${auth && auth._id}&fromdate=${fromdate}&todate=${todate}`,).then((res) => {
            setComlaines(res.data.complains)
            console.log(res, "getcomplains")
        }).catch((err) => console.log(err))
    }

    for (let i = 0; i < comlaines.length; i++) {
        for (let j = 0; j < complainsmonths.length; j++) {
            if (comlaines[i].date == complainsmonths[j]) {
                // console.log(complainsmonths.indexOf(complainsmonths[j]))
                var index = complainsmonths.indexOf(complainsmonths[j])
                complainesvalues[j] = comlaines[i].count
            }
        }
    }



    const complainsChartOptions = {
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: complainsmonths,
            boundaryGap: false,
            //boundaryGap: ['2%', '2%']
        },
        yAxis: {
            type: 'value',
            splitNumber: 1,
            //data: [0, 1, 2, 3, 4, 5, 6, 7, 8]
        },
        series: [
            {
                data: complainesvalues,
                label: {
                    show: true,
                    position: 'top'
                },
                itemStyle: {
                    borderRadius: 5
                },
                type: 'line',
                //smooth: true,
                areaStyle: {
                    opacity: 0.8,
                    // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    // {
                    //     offset: 0,
                    //     color: '#0258FF'
                    // },
                    // {
                    //     offset: 0.5,
                    //     color: '#CCDEFF'
                    // },
                    // {
                    //     offset: 1,
                    //     color: '#fff'
                    // }
                    // ])
                },
            }
        ]
    };

    const getCount = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `companies/DashbordCountforadmin/${auth && auth.company_id}?userid=${auth && auth._id}`,).then((res) => {
            console.log("hittt", res);
            setCount({ verbalcomplains: res.data.verbalcomplains, sexualcomplains: res.data.sexualcomplains, complains: res.data.complains, resolvecomplains: res.data.resolvecomplains })
        }).catch((err) => console.log(err))
    }


    const [recentComplains, setRecentComplains] = React.useState([])
    const [recentactivity, setRecentActivity] = React.useState([])

    // const body = auth && auth.role_id == 1 ? [auth.company_id] : [auth.superAdminId, auth.company_id]
    const getRecentComplaint = () => {
        if (auth.viewcomplaint == true) {
            var details = {
                company_id: auth && auth.company_id,
                userid: auth && auth._id
            }
            axios.post(process.env.REACT_APP_BASE_URL + `companies/latestcomplains`, details,).then((res) => {
                console.log(res.data, 'recentactivitytrue');
                setRecentComplains(res.data.recentdata)
            }).catch((err) => console.log(err))
        }
        if (auth.viewcomplaint == false) {
            var details = {
                // company_id: auth && auth.company_id,
                userid: auth && auth._id
            }
            axios.post(process.env.REACT_APP_BASE_URL + `companies/latestcomplainsUserid`, details,).then((res) => {
                console.log(res.data, 'recentactivityfalse');
                setRecentComplains(res.data.recentdata)
            }).catch((err) => console.log(err))
        }
    }

    const getrecentActivity = () => {
        if (auth.role_id == 2) {
            var body = {
                userid: auth && auth._id,
                company_id: auth && auth.company_id
            }
            axios.post(process.env.REACT_APP_BASE_URL + `recentactivity/getbasedoncompanyid`, body,).then((res) => {
                console.log(res.data, 'recentactivity1');
                setRecentActivity(res.data.getdata)
            }).catch((err) => console.log(err))
        }
        if (auth.viewcomplaint == true && auth.role_id == 3) {
            var body = {
                userid: auth && auth._id,
                company_id: auth && auth.company_id
            }
            axios.post(process.env.REACT_APP_BASE_URL + `recentactivity/getbasedoncompanyid`, body,).then((res) => {
                console.log(res.data, 'recentactivity2');
                setRecentActivity(res.data.getdata)
            }).catch((err) => console.log(err))
        }
        if (auth.viewcomplaint == false && auth.role_id == 3) {
            var body = {
                userid: auth && auth._id,
                // company_id: auth && auth.company_id
            }
            axios.post(process.env.REACT_APP_BASE_URL + `recentactivity/userRecentActivity`, body,).then((res) => {
                console.log(res.data, 'recentactivity3');
                setRecentActivity(res.data.getdata)
            }).catch((err) => console.log(err))
        }
    }
    const handleClickVerbal = () => {
        navigate('/complains', { state: { filter: 'Verbal' } })
    }
    const handleClickSexual = () => {
        navigate('/complains', { state: { filter: 'Sexual' } })
    }
    const handleClickComplaint = () => {
        navigate('/complains', { state: { filter: '' } })
    }
    return (
        <Box className='dashboard AdminDashboard'>
            <MiniDrawer type={0} />
            <Box component="section" className="contentWraper">
                <Typography component='h4' variant='h4' className='page-heading'>Dashboard</Typography>
                <Grid container columnSpacing={4} rowSpacing={2} className='main_grid'>
                    <Grid item xs={12} sm={12} md={9} lg={9}>
                        <Box className='whiteContainer'>
                            <Grid container columnSpacing={4} rowSpacing={2} className='complaints_cardList'>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box component='div' className='cards'>
                                        <Box component='div' className='card-img'>
                                            <img src={require('./../../../assets/images/complains-vs-resolved.png')} width='100%'></img>
                                        </Box>
                                        <Box component='div' className='card-content'>
                                            <Typography component='h3' variant='h3' className='card-value'>
                                                <Typography component='span' variant='body1' className='mr-10' sx={{ cursor: 'pointer' }} onClick={handleClickComplaint}>{countvaalues.complains} <span style={{ color: '#89979F', fontSize: 20 }}>Vs</span></Typography>
                                                <Typography component='span' variant='body1' sx={{ cursor: 'pointer' }} onClick={handleClickComplaint}>{countvaalues.resolvecomplains}</Typography>
                                            </Typography>
                                            <Typography component='h6' className='card-title' sx={{ cursor: 'pointer' }} onClick={handleClickComplaint}>Complains Vs Resolved</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box component='div' className='cards'>
                                        <Box component='div' className='card-img'>
                                            <img src={require('./../../../assets/images/verbal-complains.png')} width='100%'></img>
                                        </Box>
                                        <Box component='div' className='card-content'>
                                            <Typography component='h3' variant='h3' className='card-value' sx={{ cursor: 'pointer' }} onClick={handleClickVerbal}>{countvaalues.verbalcomplains}</Typography>
                                            <Typography component='h6' className='card-title' sx={{ cursor: 'pointer' }} onClick={handleClickVerbal}>Verbal Complains</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box component='div' className='cards'>
                                        <Box component='div' className='card-img'>
                                            <img src={require('./../../../assets/images/sexual-complains.png')} width='100%'></img>
                                        </Box>
                                        <Box component='div' className='card-content'>
                                            <Typography component='h3' variant='h3' className='card-value' sx={{ cursor: 'pointer' }} onClick={handleClickSexual}>{countvaalues.sexualcomplains}</Typography>
                                            <Typography component='h6' className='card-title' sx={{ cursor: 'pointer' }} onClick={handleClickSexual}>Sexual Complains</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                <Box className='calendarBox'>
                                    <Box className='flex-box alignItems_center mr-10'>
                                        <Box style={{ width: '100%' }} className='label'>
                                            Fetch Record From
                                        </Box>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Box className='datepicker '>
                                                <DesktopDatePicker
                                                    inputFormat="dd/MM/yyyy"
                                                    value={fromdate}
                                                    className='textfield'
                                                    onChange={handleAllFromDate}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Box>
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                <Box className='calendarBox'>
                                    <Box className='flex-box alignItems_center mr-10'>
                                        <Box className='label'>
                                            To
                                        </Box>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} style={{ alignItems: 'flex-start', marginLeft: '20px' }}>
                                            <Box className='datepicker '>
                                                <DesktopDatePicker
                                                    inputFormat="dd/MM/yyyy"
                                                    value={todate}
                                                    className='textfield'
                                                    onChange={handleAllTodate}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </Box>
                                        </LocalizationProvider>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                <Box className='flex-box heading' >
                                    <Select
                                        style={{ maxHeight: '37px', width: '100%', height: '100%' }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        className='select fz-14'
                                        value={selectdayforgroup}
                                        onChange={selectDayforgroup}
                                    >
                                        <MenuItem value={'all'}>All</MenuItem>
                                        <MenuItem value={'week'}>Week</MenuItem>
                                        <MenuItem value={'month'}>Month</MenuItem>
                                        <MenuItem value={'year'}>Year</MenuItem>
                                    </Select>
                                </Box>
                            </Grid>
                        </Grid>


                        <Grid container columnSpacing={3} className='mt-20 admindashboard_rows'>
                            <Grid item xs={12} sm={12} md={6} lg={6}>

                                <Box className='whiteContainer h-100p'>
                                    <Box component='div' className='chart'>
                                        <Typography component='h3' variant='' className='chart-heading'>Complains Type</Typography>
                                        {/* <Box className='flexContainer'>

                                            <Box className='calendarBox'>
                                                <Box className='flex-box alignItems_center mr-10'>
                                                    <Box style={{ width: '92%' }} className='label'>
                                                        Fetch Record From
                                                    </Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Box className='datepicker '>
                                                            <DesktopDatePicker
                                                                // label="From"
                                                                inputFormat="dd/MM/yyyy"
                                                                value={fromdate}
                                                                className='textfield'

                                                                onChange={handleAllFromDate}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                </Box>
                                                <Box className='flex-box alignItems_center toText'>
                                                    <Box className='label'>
                                                        To
                                                    </Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} style={{ alignItems: 'flex-start', marginLeft: '20px' }}>
                                                        <Box className='datepicker '>

                                                            <DesktopDatePicker
                                                                // label="To"
                                                                inputFormat="dd/MM/yyyy"
                                                                value={todate}
                                                                onChange={handleAllTodate}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                className='textfield'

                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                </Box>
                                            </Box>
                                        </Box> */}
                                        <ReactEcharts option={complainsTypeOptions} className='echarts chart600' />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box className='whiteContainer h-100p'>
                                    <Box component='div' className='chart'>
                                        <Typography component='h3' variant='' className='chart-heading'>Complains</Typography>

                                        {/* <Box className='flexContainer'>

                                            <Box className='calendarBox'>
                                                <Box className='flex-box alignItems_center mr-10'>
                                                    <Box style={{ width: '92%' }} className='label'>
                                                        Fetch Record From
                                                    </Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Box className='datepicker '>
                                                            <DesktopDatePicker
                                                                // label="From"
                                                                inputFormat="dd/MM/yyyy"
                                                                value={fromdate}
                                                                className='textfield'

                                                                 onChange={handleAllFromDate}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                </Box>
                                                <Box className='flex-box alignItems_center toText'>
                                                    <Box className='label'>
                                                        To
                                                    </Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} style={{ alignItems: 'flex-start', marginLeft: '20px' }}>
                                                        <Box className='datepicker '>

                                                            <DesktopDatePicker
                                                                // label="To"
                                                                inputFormat="dd/MM/yyyy"
                                                                value={todate}
                                                                className='textfield'
                                                                onChange={handleAllTodate}
                                                                // onChange={handleChangeTodate}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                </Box>
                                            </Box>
                                            <Box className='flex-box heading' >
                                                <Select style={{ maxHeight: '37px', width: '100%', height: '100%' }}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    className='select fz-14'
                                                    value={selectdayforgroup}
                                                    onChange={selectDayforgroup}
                                                >
                                                    <MenuItem value={'all'}>All </MenuItem>
                                                    <MenuItem value={'week'}>Week</MenuItem>
                                                    <MenuItem value={'month'}>Month</MenuItem>

                                                    <MenuItem value={'year'}>Year</MenuItem>
                                                </Select>
                                            </Box>
                                        </Box> */}

                                        <ReactEcharts option={complainsChartOptions} className='echarts' />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={3} className='mt-20 admindashboard_rows'>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box className='whiteContainer h-100p'>
                                    <Box component='div' className='chart'>
                                        <Typography component='h3' variant='' className='chart-heading'>Complains Resolved</Typography>

                                        {/* <Box className='flexContainer'>

                                            <Box className='calendarBox'>
                                                <Box className='flex-box alignItems_center mr-10'>
                                                    <Box style={{ width: '92%' }} className='label'>
                                                        Fetch Record From
                                                    </Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Box className='datepicker '>
                                                            <DesktopDatePicker
                                                                // label="From"
                                                                inputFormat="dd/MM/yyyy"
                                                                value={fromdate}
                                                                className='textfield'

                                                                onChange={handleAllFromDate}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                </Box>
                                                <Box className='flex-box alignItems_center  toText'>
                                                    <Box className='label'>
                                                        To
                                                    </Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} style={{ alignItems: 'flex-start', marginLeft: '20px' }}>
                                                        <Box className='datepicker '>

                                                            <DesktopDatePicker
                                                                // label="To"
                                                                className='textfield'

                                                                inputFormat="dd/MM/yyyy"
                                                                value={todate}
                                                                onChange={handleAllTodate}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                </Box>
                                            </Box>
                                        </Box> */}

                                        <ReactEcharts option={complainsResolvedOptions} className='echartssss' />
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                        <Box className='whiteContainer mt-20'>
                            <Box component='div' className='chart closingcomplains'>
                                <Typography component='h3' variant='' className='chart-heading'>Days to close complains</Typography>
                                <ReactEcharts option={closingComplainsOptions} className='echarts' />
                            </Box>
                        </Box>

                    </Grid>
                    {auth && auth.role_id != 1 ? <>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Box className='whiteContainer'>
                                <Box component='div' className='activity'>
                                    <Typography component='h3' variant='' className='recent-activity-heading'>Recent Activity
                                    </Typography>
                                    {/* <Box component='div' className='recent-activity-container'> */}
                                    {recentactivity && recentactivity.length > 0 ? recentactivity.map((item, i) => (
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/complains-vs-resolved.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc mb-12">Complaint status has been changed to <Typography component='span' className="by" style={{ backgroundColor: item.statuscolor, color: '#f5f3f2', paddingLeft: 4, paddingRight: 4, borderRadius: 2 }}>{item.statustitle}</Typography></Typography>
                                                {item.statustitle == 'Escalate' && <Typography className="activity-desc mb-5">Escalated to <Typography component='span' className="by">{item.escalated_to_name}</Typography></Typography>}
                                                <Typography className="activity-desc"><strong>Comment :</strong> {item.statustitle === 'Re-open' ? <Typography component='span' className="activity-desc">{item.commentsectionforthumbsup}</Typography> : <Typography component='span' className="activity-desc">{item.message}</Typography>}</Typography>
                                                <Typography className="activity-time">{moment(item.createdAt).format('lll')}</Typography>
                                            </Box>
                                        </Box>
                                    )) : <Box component='div' className='recent-activity-container'>
                                        <Box className="activity-list-item mb-10">
                                            <Typography className="activity-desc"> No recent Activity found </Typography>
                                        </Box>
                                    </Box>}
                                    {/* </Box> */}
                                </Box>
                            </Box>
                            <Box className='whiteContainer mt-20' >
                                <Box component='div' className='activity'>
                                    <Typography component='h3' variant='' className='recent-activity-heading'>Recent Complains</Typography>
                                    {recentComplains && recentComplains.length > 0 ? recentComplains.map((item, i) => (
                                        <Box component='div' className='recent-activity-container'>
                                            <Box className="activity-list-item mb-10">
                                                <img src={require('./../../../assets/images/verbal-complains.png')} className="" height="40" alt="" />
                                                <Box className="activity-list-content">
                                                    <Typography className="activity-desc"> Against <Typography component='span' className="by">{item.againstname}</Typography>
                                                        <Typography component='span' className="activity-time"> {moment(item.createdAt).format('MM/DD/YYYY, h:mm A')}</Typography>
                                                    </Typography>
                                                    <Typography component='p' className="complain-shortdesc">{item.description && item.description.slice(0, 75) + (item.description.length > 75 ? "..." : "")}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )) : <Box component='div' className='recent-activity-container'>
                                        <Box className="activity-list-item mb-10">
                                            <Typography className="activity-desc"> No recent Complains found </Typography>
                                        </Box>
                                    </Box>}
                                </Box>
                            </Box>
                        </Grid>
                    </> : ''}

                </Grid>
            </Box>
        </Box>

    )
}