import React from 'react'
import PrivateRoute from '../components/privateroute'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Drawer from '../components/drawer'
import ForgotPassword from '../pages/Authentication/ForgotPassword'
import Login from '../pages/Authentication/Login'
import ResetPassword from '../pages/Authentication/ResetPassword'
import Companies from '../pages/Home/Companies'
import AddCompany from '../pages/Home/Companies/AddCompany'
import AddUser from '../pages/Home/Companies/AddUser'
import Dashboard from '../pages/Home/Dashboard'
import Settings from '../pages/Home/Settings'
import Settings_sidebar from '../pages/Home/Settings/Sidebar'
import SuperAdmin_Login from '../pages/Authentication/superadminlogin'
import CompanyDetail from '../pages/Home/Companies/CompanyDetails'
import Complains from '../pages/Home/Complains'
import Addcomplais from '../pages/Home/Settings/index'
import Addstatus from '../pages/Home/Settings/status/status'
import Addindustry from '../pages/Home/Settings/industry/industry'
import Addownership from '../pages/Home/Settings/ownership/ownership'
import Addroles from '../pages/Home/Settings/roles/role'
import Profile from '../pages/profile/index'
import AddComplain from '../pages/Home/Complains/AddComplain'
import CompDet_Adduser from '../pages/Home/Companies/CompanyDetails/adduser'
import User from '../pages/Home/Settings/user/user'
import Notification from '../pages/Home/notification/notification'
import SuperAdmin_Resetpassword from '../pages/Authentication/superAdminReset'
import SuperdminForgotPassword from '../pages/Authentication/Forgotpasswordforsuperadmin'
import ImageDownload from '../pages/imagedownload/index'


export default function Routers() {
    return (
        <Router>
            <Routes>
                <Route exact path='/superadminlogin' element={<SuperAdmin_Login />}></Route>
                <Route exact path='/superdminforgotpassword' element={<SuperdminForgotPassword />}></Route>
                <Route exact path='/superdmin_resetpassword/:token' element={<SuperAdmin_Resetpassword />}></Route>
                <Route exact path='/' element={<Login />}></Route>
                <Route exact path='/login' element={<Login />}></Route>
                <Route exact path='/forgotpassword' element={<ForgotPassword />}></Route>
                <Route exact path='/reset-password/:token' element={<ResetPassword />}></Route>
                <Route path="/home" element={<PrivateRoute><Drawer /> </PrivateRoute>} />
                <Route path="/dashboard" element={<PrivateRoute><Dashboard /> </PrivateRoute>} />
                <Route path="/Companies" element={<PrivateRoute><Companies /> </PrivateRoute>} />                            
                <Route exact path='/Settings' element={<PrivateRoute><Settings /></PrivateRoute>}></Route>
                <Route exact path='/Settings_sidebar' element={<PrivateRoute><Settings_sidebar /></PrivateRoute>}></Route>
                <Route exact path='/AddCompany' element={<PrivateRoute><AddCompany /></PrivateRoute>}></Route>
                <Route exact path='/Adduser' element={<PrivateRoute><AddUser /></PrivateRoute>}></Route>
                <Route exact path='/Complains' element={<PrivateRoute><Complains /></PrivateRoute>}></Route>
                <Route exact path='/CompanyDetail' element={<PrivateRoute><CompanyDetail /></PrivateRoute>}></Route>
                <Route exact path='/setting/addcomplain' element={<PrivateRoute><Addcomplais /></PrivateRoute>}></Route>
                <Route exact path='/setting/addstatus' element={<PrivateRoute><Addstatus /></PrivateRoute>}></Route>
                <Route exact path='/setting/addindustry' element={<PrivateRoute><Addindustry /></PrivateRoute>}></Route>
                <Route exact path='/setting/addownership' element={<PrivateRoute><Addownership /></PrivateRoute>}></Route>
                <Route exact path='/setting/addroles' element={<PrivateRoute><Addroles /></PrivateRoute>}></Route>
                <Route exact path='/profileupdate' element={<PrivateRoute><Profile /></PrivateRoute>}></Route>
                <Route exact path='/AddComplain' element={<PrivateRoute><AddComplain /></PrivateRoute>}></Route>
                <Route exact path='/CompDet_Adduser' element={<PrivateRoute><CompDet_Adduser /></PrivateRoute>}></Route>
                <Route exact path='/user' element={<PrivateRoute><User /></PrivateRoute>}></Route>
                <Route exact path='/adminlogin' element={<SuperAdmin_Login />}></Route>
                {/* <Route exact path='/download' element={<ImageDownload/>}></Route> */}
                <Route exact path='/notification' element={<PrivateRoute><Notification/></PrivateRoute>}></Route>
            </Routes>
        </Router>
    )
}
