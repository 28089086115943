import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { AssignmentOutlined, Business, FeaturedPlayListOutlined, LockPersonOutlined, VpnKeyRounded } from '@mui/icons-material';
import { ListItemButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';



export default function Sidebar({subtype}) {
    const auth = JSON.parse(localStorage.getItem("userData"))
    let navigate = useNavigate()
    const data = [
        auth && auth.role_id == 1 ? {
            icons: <VpnKeyRounded className='fz-18' />,
            text: 'Ownership',
            link: '/setting/addownership'
        } : "",
        auth && auth.role_id == 1 ? {
            icons: <Business className='fz-18' />,
            text: 'Industry',
            link: '/setting/addindustry'
        } : "",
        {
            icons: <LockPersonOutlined className='fz-18' />,
            text: 'User Roles',
            link: '/setting/addroles'
        },
        {
            icons: <AssignmentOutlined className='fz-18' />,
            text: 'Status',
            link: '/setting/addstatus'
        },
        {
            icons: <FeaturedPlayListOutlined className='fz-18' />,
            text: 'Complain Type',
            link: '/setting/addcomplain'
        }
    ]

    const [dense, setDense] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };
    const PageNavigation = (url) => {
        navigate(url);

    }

    React.useState(()=>{
		setSelectedIndex(subtype);
	},[subtype])
    return (
        <>
            <Box className='sidebar'>
                <Typography variant="h5" component="h5" >
                    ADMIN SETTINGS
                </Typography>
                <Typography variant="h6" component="h6" >
                    RECORDS
                </Typography>
                <List dense={dense} className='list'>
                    {data.map((item, index) => (
                        item &&
                        <ListItemButton key={index} selected={selectedIndex === index}
                            onClick={(event) => handleListItemClick(event, index)}>
                            <ListItemIcon style={{ minWidth: 35, fontSize: 16 }} className='itemIcon'>
                                {item.icons}
                            </ListItemIcon>
                            <ListItemText className='sidebarText'
                                primary={item.text} onClick={() => PageNavigation(item.link)}
                            />
                        </ListItemButton>
                    ))}
                </List>
            </Box>
            <Box className='Ressidebar'>
                <Typography variant="h5" component="h5" >
                    ADMIN SETTINGS
                </Typography>
                <Typography variant="h6" component="h6" >
                    RECORDS
                </Typography>
                <List className='list'>

                    {data.map((item, index) => (

                        <ListItem key={index} disablePadding sx={{ display: 'block' }} selected={selectedIndex === index}

                        >
                            <ListItemButton


                                onClick={(event) => handleListItemClick(event, index)} >
                                <ListItemIcon style={{ minWidth: 35, fontSize: 16 }} className='itemIcon'>
                                    {item.icons}
                                </ListItemIcon>
                                <ListItemText className='sidebarText'
                                    primary={item.text} onClick={() => PageNavigation(item.link)}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>


    )
}
