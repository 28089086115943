import { Box, Grid, Typography } from '@mui/material'
import * as React from 'react';
import SuperAdmin from './SuperAdmin'
import Admin from './admin'
import User from './user'

export default function Dashboard() {
    const auth = JSON.parse(localStorage.getItem("userData"))
    return (
      <React.Fragment>
      { auth && auth.role_id === 1 ? <SuperAdmin/> : auth.role_id === 2 ? <Admin/> : auth.role_id === 3 ? <User/> : '' }
      </React.Fragment>
    )
}