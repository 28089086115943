import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, Avatar, Input, TextField, FormControlLabel, RadioGroup, Radio, Typography, IconButton } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SearchOutlined, AddAPhoto } from '@mui/icons-material';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'
import Avatarimage from "../../../assets/images/Avatar.png"
import { locale } from 'moment';
import { header, headerformdata } from "../../../components/axiousheader"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#091865',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});


export default function AddUser() {
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    let location = useLocation()
    var data = location.state ? location.state.result : null
    const [user, setUser] = React.useState({ firstname: '', lastname: '', email: '', gender: 'Male', role: 'User', jobtitle: '', phone: '', Imageprew: "", Imageraw: "" });
    const [error, setError] = React.useState({ firstname: "", email: "" })
    const [userRole, setUserRole] = React.useState('')
    const [roleDetails, setRoleDetails] = React.useState([])
    let username, uservalue;
    const [roleOrder, setRoleOrder] = React.useState({ type: -1, column: 'createdAt' })
    const handleChange = (e) => {
        username = e.target.name;
        uservalue = e.target.value;
        setUser({ ...user, [username]: uservalue })
        setError({ ...error, [username]: '' })
    };
    const handleaddphoto = (e) => {
        setUser({ ...user, Imageprew: URL.createObjectURL(e.target.files[0]), Imageraw: e.target.files[0] })
    }
    React.useEffect(() => {
        getRoles()
        getSortOrder()
    }, [roleOrder.type])


    const validation = () => {
        if (!user.firstname) {
            setError({ firstname: "First name can not be empty" })
            return false
        }
        else if (!user.email) {
            setError({ email: "Email cannot be empty" })
            return false
        }
        else if (user.email) {
            const pattern = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")
            var valid = pattern.test(user.email)
            if (!valid) {
                setError({ email: "Please enter valid Email" })
                return false
            }
        }
        return true
    }


    const addUserDetails = () => {
        const validator = validation()
        if (validator == true) {
            const formData = new FormData();
            formData.append("Image", user.Imageraw ? user.Imageraw : "");
            formData.append("firstname", user.firstname);
            formData.append("lastname", user.lastname);
            formData.append("email", user.email);
            formData.append("jobtitle", user.jobtitle);
            formData.append("phone", user.phone);
            formData.append("role", userRole.user_role);
            formData.append("viewcomplaint", userRole.receive_complaint);
            formData.append("gender", user.gender);
            formData.append("userrole", 'User');
            formData.append("role_id", 3);
            formData.append("company_id", data._id);
            formData.append("companyname", data.companyname,);
            formData.append("superAdminId", auth && auth.company_id);
            formData.append("UserRole_id",userRole._id);

            for (const value of formData.values()) {
                console.log(value);
            }

            axios.post(process.env.REACT_APP_BASE_URL + `companies/addUser`, formData, { headers: headerformdata.Authorization=localStorage.getItem("token") }).then((res) => {
                setUser({ firstname: '', lastname: '', email: '', role: '', jobtitle: '', phone: '', Imageprew: "", Imageraw: "" });
                enqueueSnackbar("User added successfully", { variant: 'success' })
            }).catch((err) => {
                console.log(err)
                enqueueSnackbar(err&&err.response&&err.response.data.message?err.response.data.message:"Somthing went wront", { variant: 'error' })
            })
        }
    }
    console.log(userRole,"userRoleuserRole");
    // console.log(roleDetails, 'roleDetailsroleDetails');
    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };

    const handleChangeUser = (e) => {
        setUserRole(e.target.value)
    }

    const getSortOrder = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `order/GetOrder/${auth.company_id}`, ).then((res) => {
            console.log(res.data, 'roledetausadbjbjbjbjb');
            setRoleOrder({ type: res.data.order.userrole_order, column: res.data.order.userrole_name })
        }).catch((err) => console.log(err))
    }

    const body = auth && auth.role_id == 1 ? [auth.company_id] : [auth.superAdminId, auth.company_id]
    const getRoles = () => {
        axios.post(process.env.REACT_APP_BASE_URL + `userrole/rolesdropdown?sortColumn=${roleOrder.column}&sortType=${roleOrder.type}`, body, ).then((res) => {
            console.log(res.data, 'rolesdataaaaa');
          //  setRoleDetails(res.data.userrole)
          if(auth.role_id != 1) {
            let abc = res.data.userrole.filter(item => item._id != '6593da44f681835b44699c1b')
            
            setRoleDetails(abc)
    
        } else {
            setRoleDetails(res.data.userrole)
       
        }
        }).catch((err) => console.log(err))
    }

    return (
        <Box className='Addcompany'>
            <MiniDrawer />
            <Box component="section" className="contentWraper">
                {/* <Button variant="contained" className='addbtn mb-10' > Back</Button> */}
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add User</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <Box className='personal-info-tab'>
                                            <Typography component='h2' className='profile-heading' >
                                                Profile Photo
                                            </Typography>
                                            <Box className='profile-photo'>
                                                <label htmlFor="icon-button-file">
                                                    <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={handleaddphoto} />
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                        {user.Imageprew ? <Avatar src={user.Imageprew}>
                                                        </Avatar> : <Avatar src={Avatarimage} />
                                                        }
                                                        <AddAPhoto />
                                                    </IconButton>
                                                </label>
                                            </Box>
                                            <Typography className='allow-filetypes'>
                                                Allowed *.jpeg, *.jpg, *.png<br />max size of 2 MB
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>First Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='firstname'
                                                className='textfield'
                                                placeholder='First Name'
                                                value={user.firstname}
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Last Name: </Typography>

                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='lastname'
                                                className='textfield'
                                                placeholder='Last Name'
                                                value={user.lastname}
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='email'
                                                className='textfield'
                                                value={user.email}
                                                placeholder='Email'
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth size="small">
                                            <Typography component='div' className='label'>User Role:</Typography>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                name='role'
                                                onChange={handleChangeUser}
                                                value={userRole.user_role}
                                                variant='outlined'
                                                className='select'
                                                MenuProps={MenuProps}
                                            >
                                                {
                                                    roleDetails && roleDetails.map((item, i) => (
                                                        <MenuItem value={item} className='menuitems'>{item.user_role}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} sm={3} md={3} lg={3} className='radiobtnGrid'>
                                        <Typography component='div' className='label '>Gender:</Typography>

                                    </Grid>
                                    <Grid item xs={8} sm={9} md={9} lg={9}>
                                        <Box className=" ">
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-customized-radios"
                                                name="gender"
                                                onChange={handleChange}
                                                value={user.gender}
                                            >
                                                <FormControlLabel value="Male" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Male</Typography>} />
                                                <FormControlLabel value="Female" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Female</Typography>} />
                                            </RadioGroup>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Job Title:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='jobtitle'
                                                value={user.jobtitle}
                                                className='textfield'
                                                placeholder='Job Title'
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Phone:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='phone'
                                                className='textfield'
                                                value={user.phone}
                                                placeholder='Phone'
                                                onChange={handleChange}
                                                onInput={(e) => onlyNumbers(e)}
                                                inputProps={{ maxLength: 10 }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button variant="contained" className='addbtn mr-10' onClick={addUserDetails}>Save</Button>
                                    <Button variant="contained" className='addbtn' onClick={addUserDetails}> Save + Add Users</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}