import * as React from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { Typography, TableBody, IconButton, TextField, InputAdornment, Box, Button, FormControl, FormGroup } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import axios from 'axios'
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from 'notistack'
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { ExpandMore, SearchOutlined, UnfoldMore, HighlightOffRounded } from '@mui/icons-material';
import moment from 'moment'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import { header, headerformdata } from "../../../components/axiousheader"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    {/* <CloseIcon className='fz-18' /> */}
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function DataTable() {
    var navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()

    const [isCheckAll, setIsCheckAll] = React.useState(false);
    const [deleteid, setDeleteid] = React.useState([])
    const [deletearry, setDeleteArray] = React.useState([])
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [companyDetails, setCompanyDetails] = React.useState('')
    const [searchTerm, setSearchTerm] = React.useState('')
    const [sortstatus, setsortstatus] = React.useState(false)
    const [sortname, setsortname] = React.useState("createdAt")
    const [deletemodel, setDeletemodel] = React.useState(false)

    React.useEffect(() => {
        getCompanyDetails()
    }, [page, rowsPerPage, searchTerm, sortname, sortstatus])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handlenavigate = (data) => {
        navigate('/CompanyDetail', { state: data })
    }

    const getCompanyDetails = () => {
        console.log(sortname, "sortname");
        console.log(sortstatus == true ? 1 : -1, 'sortstatus == true ? 1 : -1');
        axios.get(process.env.REACT_APP_BASE_URL + `companies/companydata?size=${rowsPerPage}&page=${page}&search=${searchTerm}&sortColumn=${sortname}&sortType=${sortstatus == true ? 1 : -1}`,).then((res) => {
            var value = { val: false }
            console.log(res.data.list, 'res.data.list');
            res.data.list && res.data.list.map((item, i) => {
                Object.assign(item, value)
            })
            setCompanyDetails(res.data.list)
            setCount(res.data.total != undefined ? res.data.total : 0)
        }).catch((err) => {
                console.log(err);
            })
    }

    const checkAll = (e) => {
        setIsCheckAll(!isCheckAll)
        if (e.target.checked == true) {
            companyDetails && companyDetails.map((item, i) => {
                item.val = e.target.checked
                setDeleteid(pre => pre.concat({ id: item._id, }))
                deletearry.push(item._id)
            })
        }
        else {
            companyDetails && companyDetails.map((item, i) => {
                item.val = e.target.checked
            })
            setDeleteid([])
            setDeleteArray([])
        }
    }

    const CheckChange = (e, id, index) => {
        if (e.target.checked == true) {
            companyDetails && companyDetails.map((item, i) => {
                if (i == index) {
                    item.val = e.target.checked
                }
            })
            setDeleteid(pre => pre.concat({ id: id, }))
            deletearry.push(id)
            console.log(companyDetails.length, deleteid.length + 1);
            if (companyDetails.length == deleteid.length + 1) {
                setIsCheckAll(true)
            }
        }
        else {
            companyDetails && companyDetails.map((item, i) => {
                if (i == index) {
                    item.val = e.target.checked
                }
            })
            var data = deleteid.filter(item1 => item1.id != id)
            var data1 = deletearry.filter((ids) => ids != id)
            setDeleteid(data)
            setDeleteArray(data1)
            setIsCheckAll(false)
        }
    }

    const handleDeleteData = () => {
        setDeletemodel(true)
    }

    const handleDelete = () => {
        var body = {
            ids: deletearry
        }
        axios.post(process.env.REACT_APP_BASE_URL + `companies/multipleDelete`, body,).then((res) => {
            getCompanyDetails()
            setDeletemodel(false)
            setDeleteArray([])
            enqueueSnackbar("Selected companies deleted successfully", { variant: 'success' })
        }).catch((err) => {
            console.log(err);
        })
    }

    console.log(sortstatus, 'sortstatus');

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>
                <Toolbar className='toolbar comptoolbar'>
                    {/* <Button
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        className='DT-actionbtn'>Actions <ArrowDropDown sx={{ marginTop: -0.1, fontSize: 18, color: '#222' }} /></Button>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="bottom-start"
                        transition
                        disablePortal
                        className='menu_popper'
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                            onKeyDown={handleListKeyDown}
                                            className='menu'
                                        >
                                            <MenuItem onClick={handleDelete} sx={{ color: '#e53935' }}><DeleteOutlineOutlinedIcon sx={{ marginRight: 1 }} />Delete</MenuItem>
                                            <MenuItem onClick={handleClose} sx={{ color: '#ff9800' }}><PauseCircleOutlineOutlinedIcon sx={{ marginRight: 1 }} />Suspend</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper> */}
                    {deletearry && deletearry.length > 0 && <Button className='del-btn' onClick={handleDeleteData}>Delete</Button>}
                    <Box component='div' className='DTsearch'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10' >Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                className='textfield search '
                                placeholder='Search Here...'
                                onChange={(e) => setSearchTerm(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                                }}
                            />
                        </FormControl>
                    </Box>
                </Toolbar>
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-label="customized table" className='table'>
                        <TableHead className='table-head'>
                            <TableRow className='table-row '>
                                <StyledTableCell align="center" width={150} className='checkbox'><FormControlLabel control={<Checkbox onChange={checkAll} checked={isCheckAll} />} /></StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }} align="left" onClick={() => { setsortstatus(!sortstatus); setsortname("companyname") }} className='TRow'>
                                    <strong>Company</strong> {sortstatus ? <IconButton size='small' style={{ paddingTop: 2 }}><ExpandMore fontSize='10px' /></IconButton> : <IconButton size='small' style={{ paddingTop: 2 }}><UnfoldMore fontSize='10px' /></IconButton>}</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }} align="left" onClick={() => { setsortstatus(!sortstatus); setsortname("industry") }} className='TRow'> <strong>Sector</strong>  {sortstatus ? <IconButton size='small' style={{ paddingTop: 2 }}><ExpandMore fontSize='10px' /></IconButton> : <IconButton size='small' style={{ paddingTop: 2 }}><UnfoldMore fontSize='10px' /></IconButton>}</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }} align="left" onClick={() => { setsortstatus(!sortstatus); setsortname("ownershipname") }} className='TRow'> <strong>Ownership</strong>  {sortstatus ? <IconButton size='small' style={{ paddingTop: 2 }}><ExpandMore fontSize='10px' /></IconButton> : <IconButton size='small' style={{ paddingTop: 2 }}><UnfoldMore fontSize='10px' /></IconButton>}</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 180 }} align="left" onClick={() => { setsortstatus(!sortstatus); setsortname("state") }} className='TRow'> <strong>Location</strong>  {sortstatus ? <IconButton size='small' style={{ paddingTop: 2 }}><ExpandMore fontSize='10px' /></IconButton> : <IconButton size='small' style={{ paddingTop: 2 }}><UnfoldMore fontSize='10px' /></IconButton>}</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }} align="center" onClick={() => { setsortstatus(!sortstatus); setsortname("createdAt") }} className='TRow'> <strong>Member Since</strong>  {sortstatus ? <IconButton size='small' style={{ paddingTop: 2 }}><ExpandMore fontSize='10px' /></IconButton> : <IconButton size='small' style={{ paddingTop: 2 }}><UnfoldMore fontSize='10px' /></IconButton>}</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }} align="center" onClick={() => { setsortstatus(!sortstatus); setsortname("companyname") }} className='TRow'> <strong># of Users</strong>  {sortstatus ? <IconButton size='small' style={{ paddingTop: 2 }}><ExpandMore fontSize='10px' /></IconButton> : <IconButton size='small' style={{ paddingTop: 2 }}><UnfoldMore fontSize='10px' /></IconButton>}</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }} align="center" onClick={() => { setsortstatus(!sortstatus); setsortname("companyname") }} style={{ minWidth: 250 }} className='TRow'> <strong># of Reported Complains</strong>  {sortstatus ? <IconButton size='small' style={{ paddingTop: 2 }}><ExpandMore fontSize='10px' /></IconButton> : <IconButton size='small' style={{ paddingTop: 2 }}><UnfoldMore fontSize='10px' /></IconButton>}</StyledTableCell>
                                <StyledTableCell style={{ minWidth: 150 }} align="center" onClick={() => { setsortstatus(!sortstatus); setsortname("companyname") }} className='TRow'> <strong>Resolved</strong>  {sortstatus ? <IconButton size='small' style={{ paddingTop: 2 }}><ExpandMore fontSize='10px' /></IconButton> : <IconButton size='small' style={{ paddingTop: 2 }}><UnfoldMore fontSize='10px' /></IconButton>}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companyDetails && companyDetails.length > 0 ? companyDetails.map((row, i) => {
                                console.log(row,'rowrowrowrowrowrowrowrowrowrowrow')
                                return (
                                    <StyledTableRow className='tbody-row'>
                                        <StyledTableCell align="center" className='p-0 m-0 checkbox'>
                                            <FormGroup>
                                                <FormControlLabel style={{ justifyContent: "center", marginBottom: 0 }} control={<Checkbox size='medium' onChange={(e) => CheckChange(e, row._id, i)} checked={row && row.val} />} />
                                            </FormGroup>
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" padding="none" className='DT-row' style={{ cursor: 'pointer' }} onClick={() => handlenavigate(row)}>{row ? row.companyname ? row.companyname : '-' : '-'}</StyledTableCell>
                                        <StyledTableCell align="left" className='DT-row' style={{ cursor: 'pointer' }} onClick={() => handlenavigate(row)}>{row.industry ? row.industry : '-'}</StyledTableCell>
                                        <StyledTableCell align="left" className='DT-row' style={{ cursor: 'pointer' }} onClick={() => handlenavigate(row)}>{row.ownershipname ? row.ownershipname : '-'}</StyledTableCell>
                                        <StyledTableCell align="left" className='DT-row' style={{ cursor: 'pointer' }} onClick={() => handlenavigate(row)}>{row.state}, {row.city}</StyledTableCell>
                                        <StyledTableCell align="center" className='DT-row' style={{ cursor: 'pointer' }} onClick={() => handlenavigate(row)}>{moment(row.createdAt).format('MM/YY')}</StyledTableCell>
                                        <StyledTableCell align="center" className='DT-row' style={{ cursor: 'pointer' }} onClick={() => handlenavigate(row)}>{row.noofusers ? row.noofusers : '-'}</StyledTableCell>
                                        <StyledTableCell align="center" className='DT-row pr-50' style={{ cursor: 'pointer' }} onClick={() => handlenavigate(row)}>{row.noofcomplains ? row.noofcomplains : '-'}</StyledTableCell>
                                        <StyledTableCell align="center" className='DT-row' style={{ cursor: 'pointer' }} onClick={() => handlenavigate(row)}>{row.resolved ? row.resolved.length : '-'}</StyledTableCell>
                                    </StyledTableRow>
                                );
                            }) : <StyledTableCell colSpan={9} className='nodata' > NO DATA FOUND </StyledTableCell>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />
            </Paper>
            <BootstrapDialog
                onClose={() => setDeletemodel(false)}
                aria-labelledby="customized-dialog-title"
                open={deletemodel}
                className='addForm-modal'
            >
                <DialogContent className='deleteConfirmModal'>
                    <HighlightOffRounded className='delIcon' />
                    <Typography component='div' className='text'>Are you sure want to delete all selected companies?</Typography>
                </DialogContent>
                <DialogActions className='deleteModalactbtns'>
                    <Button autoFocus onClick={() => setDeletemodel(false)} className='modalbtn cancel'>Cancel</Button>
                    <Button autoFocus onClick={handleDelete} className='modalbtn delete'>Delete</Button>
                </DialogActions>
            </BootstrapDialog>
        </Box>
    );
}
