import './App.css';
import './assets/css/Custom.css';
import './assets/css/common.css';
import './assets/css/responsive.css';
import Routers from './routes';
import React, { useEffect, useState } from "react";

import { SnackbarProvider } from 'notistack';
import { useSnackbar } from 'notistack'
// import { messaging } from '../src/firebase';
// import { onMessage } from 'firebase/messaging';
import Closebutton from "./components/closebutton"
function App() {

	React.useEffect(() => {
		// onMessage(messaging, (payload) => {
		// 	console.log(payload, "foreground");
		// 	//   toast.success(payload.data.body, {
		// 	//     position: "top-center",
		// 	//     autoClose: 5000,
		// 	//     hideProgressBar: false,
		// 	//     closeOnClick: true,
		// 	//     pauseOnHover: true,
		// 	//     draggable: true,
		// 	//     progress: undefined,
		// 	// });
		// 	alert(payload.notification.body)
		// 	// enqueueSnackbar("payload.data.body", { variant: 'success' })
		// });
	}, []);

	return (
		<> 
		<SnackbarProvider maxSnack={3}   anchorOrigin={{
		  vertical: 'top',
		  horizontal: 'right',}}   action={closeid => <Closebutton value={closeid} />}>	  
			<Routers />
			</SnackbarProvider>		  
		  </>
	);
}

export default App;
