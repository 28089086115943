export const header = {
    
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem("token")  
}


export const headerformdata = {
    'Content-Type': 'multipart/form-data',
    Authorization: localStorage.getItem("token")  
}