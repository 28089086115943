import React, { useState } from 'react';
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, Input, TextField, Typography, TextareaAutosize, Paper, IconButton, DialogContent, Dialog, Link, DialogTitle, CardMedia } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import axios from 'axios';
import { useSnackbar } from 'notistack'
import Pdfimage from "../../../assets/images/pdf.png";
import Docimage from "../../../assets/images/doc.png";
import Videoimage from "../../../assets/images/video.png";
import Audioimage from "../../../assets/images/audio.png";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Close } from '@mui/icons-material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { header, headerformdata } from "../../../components/axiousheader"
import { Navigate } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom'

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function AddComplain() {

    let navigate = useNavigate()
    let auth = JSON.parse(localStorage.getItem('userData'))
    const { enqueueSnackbar } = useSnackbar()
    const [complain, setComplain] = React.useState({ complaintype: '', details: '', status: '' });
    const [error, setError] = React.useState({ complaintname: "", against: "" })
    const [complainttype, setComplainttype] = React.useState([])
    const [adddocumentfileview, setadddocumentfileview] = React.useState({ preview: "", raw: "" })
   
    const [statusDetails, setStatusDetails] = React.useState('')
    console.log(statusDetails,"stausstaus");
    const [userDetails, setUserDetails] = React.useState({userrole: ''})
    const [modalOpen, setModalOpen] = useState(true);
    const [handleComplaint, setHandleComplaint] = React.useState('');
    const [filetype, setFiletype] = React.useState("")
    const [complaintTypeOrder, setComplaintTypeOrder] = React.useState({ type: -1, column: 'createdAt' })
    const [statusOrder, setStatusOrder] = React.useState({ type: -1, column: 'createdAt' })
    const [addAudio, setAddAudio] = React.useState({ preview: "", raw: "" })
    const [type, setType] = React.useState("")

    console.log(addAudio, "addAudio");
    const adddocumentfiles = (e) => {
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files)
        for (let i = 0; i < fileObj[0].length; i++) {
            if (fileObj[0][i].name.slice(-3) === "jpg" || fileObj[0][i].name.slice(-3) === "png" || fileObj[0][i].name.slice(-4) === "jpeg") {
                setFiletype('image');
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
            else if (fileObj[0][i].name.slice(-3) === "pdf") {

                setFiletype('pdf');
                fileArray.push("pdf.pdf")
                filepath.push(fileObj[0][i])
            }
            else if (fileObj[0][i].name.slice(-3) === "doc" || fileObj[0][i].name.slice(-4) === "docx") {
                fileArray.push("doc.doc");
                setFiletype('doc');
                filepath.push(fileObj[0][i])
            }
        }
        setadddocumentfileview({ preview: fileArray, raw: filepath })
    }

    const addAudioVideofiles = (e) => {
        console.log(e, "eeeeeeeeeeeeeeeee");
        var fileObj = [];
        var fileArray = [{ url: '', type: '' }];
        var filepath = [];
        fileObj.push(e.target.files)
        for (let i = 0; i < fileObj[0].length; i++) {
            if (fileObj[0][i].name.slice(-3) === "mp4" || fileObj[0][i].name.slice(-4) === "mp4") {
                fileArray.push({ url: URL.createObjectURL(fileObj[0][i]), type: fileObj[0][i].name.slice(-4) })
                filepath.push(fileObj[0][i])
            }
            else if (fileObj[0][i].name.slice(-3) === "mp3" || fileObj[0][i].name.slice(-4) === "mp3") {
                fileArray.push({ url: URL.createObjectURL(fileObj[0][i]), type: fileObj[0][i].name.slice(-4) })
                filepath.push(fileObj[0][i])
            }
        }
        setAddAudio({ preview: fileArray, raw: filepath })
    }


    React.useEffect(() => {
        getComplainsType()
        getCompanydata()
        getStatus()
        getSortOrder()
    }, [complaintTypeOrder.type, statusOrder.type])

    let compainName, complainValue
    const handleChange = (e) => {
        compainName = e.target.name;
        complainValue = e.target.value;
        // console.log(compainName, '==>', complainValue);
        setComplain({ ...complain, [compainName]: complainValue })
    };

    const handleChangeUser = (e) => {
        setUserDetails(e.target.value)
        setError({ against: '' })
    }
    
    console.log(userDetails, "Ad");
    const validation = () => {
        if (!handleComplaint) {
            setError({ complaintname: "Please select type of complain" })
            return false
        }
        if (!userDetails) {
            setError({ against: "Please select against" })
            return false
        }
        return true
    }

    const handleChangeComplaint = (e) => {
        setHandleComplaint(e.target.value)
        setError({ complaintname: '' })
    }

    const body = auth && auth.role_id == 1 ? [auth.company_id] : [auth.superAdminId, auth.company_id]
    const getComplainsType = () => {
        axios.post(process.env.REACT_APP_BASE_URL + `complaintype/companydropdown?sortColumn=${complaintTypeOrder.column}&sortType=${complaintTypeOrder.type}`, body,).then((res) => {
            console.log(res.data, 'complaint type');
            setComplainttype(res.data.complaintype)
        }).catch((err) => console.log(err))
    }


    const handlePreviewdelete = (index, name) => {
        // var body = { filesArray: name }
        // axios.put(process.env.REACT_APP_BASEURL + `photos/removeFile/${deleteidfile}`, body, { headers: header }).then((response) => {
        //   getimage()
        //   toast.warn('File has been removed ', {
        //     position: "top-center",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   });
        // }).catch((err) => { console.log(err) })
        // var filteredpreview = imageview.filter((value, i) => {
        //   return i !== index;
        // });
        // var filteredraw = imageview.filter((value, i) => {
        //   return i !== index;
        // });
        // setImageview(filteredraw)
        // setEditimage1({ preview: filteredpreview, raw: filteredraw })
        // if (documenteditview === true) {

        //   var filtered1preview = Imagedataedit.preview.filter((value, i) => {
        //     return i !== index;
        //   });
        //   var filtere1draw = Imagedataedit.raw.filter((value, i) => {
        //     return i !== index;
        //   });
        //   setEditimage1({ preview: filtered1preview, raw: filtere1draw })
        // }
        // setImagedata({ preview: filteredpreview, raw: filteredraw })
    }

    // const body = auth && auth.role_id == 1 ? [auth.company_id] : [auth.superAdminId, auth.company_id]
    // const getRoles = () => {
    //     axios.post(process.env.REACT_APP_BASE_URL + `userrole/rolesdropdown?sortColumn=${roleOrder.column}&sortType=${roleOrder.type}`, body).then((res) => {
    //         console.log(res.data,'rolesdataaaaa');
    //         setRoleDetails(res.data.userrole)
    //     }).catch((err) => console.log(err))
    // }

    const handleclosemodel = () => {
    navigate('/complains')
     }

    
 
    console.log(userDetails, "userDetails1234");
    const addcomplaint = () => {
        const validator = validation()
        if (validator == true) {
            const formData = new FormData();
            formData.append('complain_type', handleComplaint.complain_type)
            formData.append('complain_name', handleComplaint.compain_name)
            formData.append('againstname', userDetails.firstname);
            formData.append('againsemail', userDetails.email);
            formData.append('against_id', userDetails._id);
            formData.append('againstrole', userDetails.role)
            formData.append('description', complain.details)
            formData.append('statustitle', statusDetails.status_title)
            formData.append('statustype', statusDetails.status_type)
            formData.append('statuscolor', statusDetails.status_color)
            formData.append('complain_by_name', auth.firstname)
            formData.append('complain_by_email', auth.email)
            formData.append('complain_by_id', auth._id)
            formData.append('corporateid', auth.company_id)
            // formData.append('file_type', filetype)
            { adddocumentfileview.raw ? adddocumentfileview.raw.forEach((item) => { formData.append('Image', item); }) : formData.append('Image', "") }
            { addAudio.raw ? addAudio.raw.forEach((item) => { formData.append('Video', item); }) : formData.append('Video', "") }

            for (var pair of formData.entries()) {
                console.log(pair[0] + ' - ' + pair[1]);
            }
            axios.post(process.env.REACT_APP_BASE_URL + `complainpage/addcomplainpage`, formData, { headers: headerformdata.Authorization = localStorage.getItem("token") }).then((res) => {
                setComplain({ complaintype: '', details: '', status: '' });
                setadddocumentfileview({ preview: "", raw: "" });
                setAddAudio({ preview: "", raw: "" });
                //setUserDetails()
                setComplainttype([]);
                // setUserDetails({});
                // setHandleComplaint('')
                enqueueSnackbar("Complaint added successfully", { variant: 'success' })
                navigate('/complains')
            }).catch((err) => {
                console.log(err)
                enqueueSnackbar(err.response.data.message, { variant: 'error' })
            })
        }
    }
    const [users, setUsers] = React.useState([])
    const getCompanydata = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `companies/UserDetails?company_id=${auth && auth.company_id}&userid=${auth && auth._id}`,).then((res) => {
            setUsers(res.data.list)
        }).catch((err) => console.log(err))
    }
    const [status, setStatus] = React.useState()
    const getStatus = () => {
        axios.post(process.env.REACT_APP_BASE_URL + `status/statusdropdown?sortColumn=${statusOrder.column}&sortType=${statusOrder.type}`, body,).then((res) => {
            console.log(res.data, 'status');
            setStatus(res.data.status)
        }).catch((err) => console.log(err))
    }
    // console.log(status, 'statusstatus');
    const getSortOrder = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `order/GetOrder/${auth.company_id}`,).then((res) => {
            // console.log(res.data, 'hfhfhfhfh');
            setComplaintTypeOrder({ type: res.data.order.complain_order, column: res.data.order.complain_name })
            setStatusOrder({ type: res.data.order.status_order, column: res.data.order.status_name })
        }).catch((err) => console.log(err))
    }

    // console.log(addAudio,'addAudioaddAudioaddAudio');
    return (
        <Box className='Addcompany'>
            <MiniDrawer />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        
                        <Box className=' whiteContainer'>
                            <Box className='content p-20' autocomplete="off">
                                <Typography component='h6' className='formTitle'>Add Complain</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Complain Type: <Typography component='span' className='star'>*</Typography></Typography>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                value={handleComplaint.compain_name}
                                                onChange={handleChangeComplaint}
                                                variant='outlined'
                                                className='select'
                                                MenuProps={MenuProps}>
                                                {complainttype && complainttype.map((item, i) => (
                                                    <MenuItem value={item} className='menuitems'>{item.compain_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {error.complaintname && <p className='error-field'>{error.complaintname}</p>}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Against: <Typography component='span' className='star'>*</Typography></Typography>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                value={userDetails && userDetails.firstname}
                                                name='against'
                                                onChange={handleChangeUser}
                                                variant='outlined'
                                                className='select'
                                                MenuProps={MenuProps}
                                            >
                                                {users && users.map((item, i) => (
                                                    <MenuItem value={item} className='menuitems'>{item.firstname}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {error.against && <p className='error-field'>{error.against}</p>}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth size="small">
                                            <Typography component='div' className='label'>Role:</Typography>
                                            <TextField
                                                disabled
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='role'
                                                autocomplete="off"
                                                value={userDetails && userDetails.userrole == "User" ? userDetails.role : userDetails.userrole}
                                                //  value={userDetails && userDetails.role}
                                                className='textfield'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Details:</Typography>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Details"
                                                className='textfield textarea'
                                                name='details'
                                                value={complain.details}
                                                onChange={handleChange}
                                                minRows={3}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Status:</Typography>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                value={statusDetails.status_title}
                                                name='status'
                                                onChange={(e) => setStatusDetails(e.target.value)}
                                                variant='outlined'
                                                className='select'
                                                MenuProps={MenuProps}
                                            >
                                                {status && status.map((item, i) => (
                                                    <MenuItem value={item} className='menuitems'>{item.status_title}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>File:</Typography>
                                            <div className='uploadedImgs'>
                                                {
                                                    adddocumentfileview.preview ? adddocumentfileview.preview.map((url, index) => (
                                                        // console.log(url.slice(-3),'urlinside')
                                                        url.slice(0, 4) === "blob" ?
                                                            <Paper elevation={4} className="videoupload" >
                                                                {/* <Close className="previewcancel" onClick={() => handlePreviewdelete(index, url)} /> */}
                                                                <img src={url.slice(0, 4) === "blob" ? url : process.env.REACT_APP_BASE_URL + url} alt="noimages" className='Imageeditview' />
                                                            </Paper> :
                                                            url.slice(-3) === "jpg" || url.slice(-3) === "png" || url.slice(-4) === "jpeg" ?
                                                                <Paper className="videoupload" >
                                                                    {/* <Close className="previewcancel" onClick={() => handlePreviewdelete(index, url)} /> */}
                                                                    {/* <img src={url} alt="noimage" className='Imageeditview' /> */}
                                                                    <img src={url.slice(0, 4) === "blob" ? url : process.env.REACT_APP_BASE_URL + url} alt="noimages" className='Imageeditview' />
                                                                </Paper> : url.slice(-3) === "pdf" ?
                                                                    <Paper elevation={4} className="videoupload"  >
                                                                        {/* <Close className="previewcancel" onClick={() => handlePreviewdelete(index, url)} /> */}
                                                                        <img src={url.slice(0, 4) === "blob" ? url : Pdfimage} alt="noimages" className='Imageeditview' />
                                                                    </Paper> : url.slice(-3) === "doc" || url.slice(-4) === 'docx' ?
                                                                        <Paper elevation={4} className="videoupload" >
                                                                            {/* <Close className="previewcancel" onClick={() => handlePreviewdelete(index, url)} /> */}
                                                                            <img src={url.slice(0, 4) === "blob" ? url : Docimage} alt="noimages" className='Imageeditview' />
                                                                        </Paper> : ""
                                                    )) : ""
                                                }
                                                <label htmlFor="contained-button-file1" className='multiple_uploadbtn'>
                                                    <Paper htmlFor="contained-button-file1" elevation={3} className="videoupload1">
                                                        <Input
                                                            accept="image/*,.doc,.docx,application/msword,application/zip,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,application/pdf"
                                                            inputProps={{ multiple: true }}
                                                            // onChange={handleGalleryImage}
                                                            style={{ display: "none" }}
                                                            id="contained-button-file1"
                                                            type="file"
                                                            onChange={adddocumentfiles}
                                                        />
                                                        <FileUploadOutlinedIcon />
                                                    </Paper>
                                                </label>
                                            </div>
                                            {/* <Button variant="contained" component="label" className='uploadbtn'>
                                                Upload
                                                <input hidden accept="image/*,video/*,.doc,.docx,application/msword,application/zip,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,application/pdf,audio/*" multiple type="file" onChange={adddocumentfiles} />
                                            </Button> */}
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Audio/Video:</Typography>
                                            <div className='uploadedVideos' >
                                                {
                                                    addAudio.preview ? addAudio.preview.map((url, index) => (
                                                        url && url.type == '.mp4' ?
                                                            <Paper elevation={4} >
                                                                {/* <Close className="previewcancel"  /> */}
                                                                <CardMedia component='iframe' src={url.url} alt="noimages" />
                                                            </Paper> : url && url.type == '.mp3' ?
                                                                <Paper style={{ boxShadow: 'none' }}  >
                                                                    {/* <Close className="previewcancel" /> */}
                                                                    <audio controls src={url.url} style={{ maxWidth: '100%' }}></audio></Paper> : ""
                                                    )) : ""
                                                }
                                                {/* {
                                                    addAudio.preview ? addAudio.preview.map((url, index) => (                                                        
                                                        url.slice(-3) === ".mp4" ?
                                                            <Paper elevation={4} >
                                                                 <Close className="previewcancel"  />
                                                                <CardMedia component='iframe' src={url} alt="noimages"  />
                                                            </Paper> : url.slice(-3) === "mp3" ?
                                                                <Paper style={{ boxShadow: 'none' }}  >
                                                                     <Close className="previewcancel" />
                                                                    <audio controls src={url} style={{ maxWidth: '100%' }}></audio></Paper> : "" 
                                                    )) : ""
                                                } */}
                                            </div>
                                            <Button variant="contained" component="label" className='uploadbtn'>
                                                Upload
                                                <input hidden accept="video/*,audio/*" multiple type="file" onChange={addAudioVideofiles} />
                                            </Button>
                                        </FormControl>
                                    </Grid>


                                </Grid>
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button variant="contained" className='addbtn mr-10' onClick={addcomplaint}>Add</Button>
                                    <Button variant="contained" className='addbtn mr-10' onClick={handleclosemodel}>Cancel</Button>                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
            
        </Box >
    )
}