import React, { useState } from "react"
import { BusinessOutlined, Lock, Person } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, IconButton } from '@mui/material'
import axios from 'axios'
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useNavigate, useLocation } from "react-router-dom";
// import { auth, db,requestForToken } from "../../firebase";



export default function Login() {
    // React.useEffect(()=>{
    //     requestForToken();
    // },[])
    let navigate = useNavigate();
    let location = useLocation()
    const [userdata, setUserData] = React.useState({ companyname: location && location.state && location.state.companyname ? location.state.companyname : "", username: "", password: "" })
    const [error, setError] = useState({ text: "", username: "", password: "" })
    const [values, setValues] = React.useState({
        showPassword: false,
        showRePassword: false
    });
    const [company, setCompany] = React.useState([])
    const [text, setText] = React.useState( location && location.state && location.state.companyname ? location.state.companyname : "")
    const [suggestion, setSuggestion] = React.useState([])
    React.useEffect(() => {
        getCompanyDetails()
    }, [])
  
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleClickShowRePassword = () => {
        setValues({
            ...values,
            showRePassword: !values.showRePassword,
        });
    };
    let uname, uvalue
    const handleChange = (e) => {
        // uname=e.target.name,
        // uvalue=e.target.value
        setUserData({ ...userdata, [e.target.name]: e.target.value })
        setError({ ...error, [e.target.name]: "" })
    }
    const validator = () => {
        if (!userdata.username && !userdata.password && !text) {
            setError({ text: "Company name cannot be empty", username: "Email cannot be empty", password: "Password cannot be empty" })
            return false
        }
        if (!text) {
            setError({ text: "Company name cannot be empty" });
            return false
        }
        if (!userdata.username) {
            setError({ username: "Email  cannot be empty" });
            return false
        }
        if (!userdata.password) {
            setError({ password: "Password cannot be empty" });
            return false
        }
        return true
    }


    // const handleLogin = (e) => {
    //     e.preventDefault();
    //     var validate = validator();
    //     console.log(validate,'validatevalidate');
    //     if (validate) {
    //         var body = {
    //             companyname: text,
    //             email: userdata.username,
    //             password: userdata.password,
    //             fcmtoken: localStorage.getItem('fcm_token')
    //         }
    //         console.log(process.env.REACT_APP_BASE_URL + "companies/login","1111111111111");
    //         axios.post(process.env.REACT_APP_BASE_URL + "companies/login", body).then((res) => {
    //             console.log(res.data.user,"res.data.user")
    //             localStorage.setItem("userData", JSON.stringify(res.data.user))
    //             localStorage.setItem("token", res.data.token)
    //             localStorage.setItem("Permissions", JSON.stringify(res.data.user.UserRole_id))
    //             navigate("/dashboard")
    //         }).catch((err) => {
    //             setError({ password: err.response.data ? err.response.data.message : "Some thing went wrong" })
    //             console.log(err)
    //         })
    //     }
    // }

    const handleLogin = (e) => {
        e.preventDefault();
        var validate = validator();
        console.log(validate,'validatevalidate');
        if (validate) {
            var body = {
                companyname: text,
                email: userdata.username,
                password: userdata.password,
                fcmtoken: localStorage.getItem('fcm_token')
            }
            console.log(process.env.REACT_APP_BASE_URL + "companies/login","1111111111111");
            axios.post(process.env.REACT_APP_BASE_URL + "companies/login", body).then((res) => {
                console.log(res.data.user,"res.data.user")
                localStorage.setItem("userData", JSON.stringify(res.data.user))
                localStorage.setItem("token", res.data.token)
            //    localStorage.setItem("Permissions", JSON.stringify(res.data.user.UserRole_id))
            if(res.data.user.UserRole_id != undefined) {
                localStorage.setItem("Permissions", JSON.stringify(res.data.user.UserRole_id))
            } else {
                let someData = {
                    "Complaints": {
                        "addComplaints": false,
                        "viewComplaints": false
                    },
                    "UserMangement": {
                        "addUserMangement": false,
                        "viewUserMangement": false,
                        "deleteUserMangement": false
                    },
                    "_id": "656193b01840b3f20cbf8444",
                    "receive_complaint": false,
                    "Dashboard": false,
                    "Settings": false,
                    "ViewNotification": false,
                    "corporateid": "656192761840b3f20cbf8435",
                    "isdelete": false,
                    "role_id": 1,
                    "ByDefault": false,
                    "createdAt": "2023-11-25T06:26:56.513Z",
                    "updatedAt": "2023-11-25T06:26:56.513Z",
                    "__v": 0
                }
                localStorage.setItem("Permissions", JSON.stringify(someData))
            }
                navigate("/dashboard")
            }).catch((err) => {
                setError({ password: err.response.data ? err.response.data.message : "Some thing went wrong" })
                console.log(err)
            })
        }
    }

    const handleChangeCompany = (text) => {
        let matches = []
        if (text.length > 0) {
            matches = company && company.filter(cmpny => {
                const regex = new RegExp(`${text}`, 'gi')
                return cmpny.companyname.match(regex)               
            })
        }      
        setSuggestion(matches)
        setText(text)
        setError({ ...error, 'text': "" })
    }
    

    const handleOnFocus = ()=>{
        console.log("insideeee");
        let matches = []
      //  if (text.length > 0) {
            matches = company && company.filter(cmpny => {
                const regex = new RegExp(`${text}`, 'gi')
                return cmpny.companyname.match(regex)               
            })
        //}   
        setSuggestion(matches)   
    }
    const onSuggestHandler = (text1) => {
        setText(text1)
        setSuggestion([])
    }
    const getCompanyDetails = () => {
        console.log(process.env.REACT_APP_BASE_URL,'process.env.REACT_APP_BASE_URL');
        axios.get(process.env.REACT_APP_BASE_URL + `companies/getAllcompanyname`).then((res) => {
            setCompany(res.data.company);
        }).catch((err) => {
            console.log(err);
        })
    }
    
    const  _handleKeyDown=(e)=>{
        if(e.key === 'Enter'){
            handleLogin(e)
          }
        }
    
    return (
        <Box className='login'>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='login_img'>
                        <Box className='logintext_box'>
                            <h1 className='heading'>Incidents are facts</h1>
                            <Typography component='p' variant='body1' className='subheading'>All incidents should be treated as opportunities to learn and improve, Here is the ledger to collect the facts.</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='Login_content'>
                        <Typography component='h4' variant='h4'>Login</Typography>
                        <Box className='form'>
                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Company</Typography>
                                <TextField
                                 onClickCapture={handleOnFocus}
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    className='textfield'
                                    placeholder='Company Name'
                                    value={text}
                                    name='text'
                                    onChange={(e) => handleChangeCompany(e.target.value)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><BusinessOutlined className='start_icon' /></InputAdornment>,
                                    }}
                                    autoComplete='off'
                                />
                                {error.text ? <p className='error-field'>{error.text}</p> : ''}
                                {suggestion && suggestion.map((item, i) => (
                                <Box className="suggestionBox">
                                    
                                    <div key={i} className='suggestion' onClick={(e) => onSuggestHandler(item.companyname)} value={text} onBlur={() => {
                                        setTimeout(() => {
                                            setSuggestion([])
                                        })
                                    }}>{item.companyname}</div>
                                </Box>

                                ))}

                                
                            </FormControl>
                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Email</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    className='textfield'
                                    placeholder='Email'
                                    name='username'
                                    autoComplete='off'
             
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Person className='start_icon' /></InputAdornment>,
                                    }}
                                />
                               {error.username && <p className='error-field'>{error.username}</p>} 

                            </FormControl>
                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Password</Typography>

                                <TextField
                               
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    placeholder='Password'
                                    type={values.showRePassword ? 'text' : 'password'}
                                    className='textfield'
                                    name='password'
                                    onKeyDown={_handleKeyDown}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Lock className='start_icon' /></InputAdornment>,
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowRePassword}
                                                    edge="end">
                                                    {values.showRePassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}
                                />
                                {error.password && <p className='error-field'>{error.password}</p>}                            
                                

                            </FormControl>                     
                        </Box>
                        <Button className='loginbtn' onClick={handleLogin}>Login</Button>
                       <Box className="logintype">
                            <Box className='a-link'>
                            <Typography component='a' href='/superadminlogin'>Login as super admin </Typography>
                                </Box>
                            <Box className='a-link'>
                                    <Typography component='a' href='/forgotpassword'>Forgot your password?</Typography>
                                </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}
