import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, CardMedia, Dialog, DialogTitle, DialogContent, List, DialogContentText, Stack, TextareaAutosize, ListItemAvatar, ListItemText, ListItem, Divider } from '@mui/material';
import { FileDownloadOutlined, CloseRounded } from '@mui/icons-material';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { saveAs } from 'file-saver';
import Sidebar from '../../../components/drawer';
import Moment from 'react-moment';
import { AvatarGroup } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import Slide from '@mui/material/Slide';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Pdfimage from "../../../assets/images/pdf.png";
import Docimage from "../../../assets/images/doc.png";
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import moment from 'moment'
import MiniDrawer from '../../../components/drawer';
import { Navigation, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { header, headerformdata } from "../../../components/axiousheader"

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

const Notifications = () => {
    const auth = JSON.parse(localStorage.getItem('userData'))
    let limit = 10
    const [pageno, setPageno] = useState(1)
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [notificationDetailModal, setnotificationDetailModal] = React.useState(false);
    const [comlaintDetails, setComplaintDetails] = React.useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [notification, setNotificatoin] = useState([])
    useEffect(() => {
        getnotification()
    }, [rowsPerPage, page])
 
    const getnotification = () => {
        if (auth.viewcomplaint == true) {
            var body = {
                userid: auth && auth._id,
                company_id: auth && auth.company_id
            }
            console.log(body,'bodybodybodybody')
            console.log(process.env.REACT_APP_BASE_URL + `notifications/getcomplinsfortrueNotify?page=${page}&size=${rowsPerPage}`, { headers: header.Authorization = localStorage.getItem("token") },"apiiiii");

            console.log(body,"bodyyyy");
            axios.post(process.env.REACT_APP_BASE_URL + `notifications/getcomplinsfortrueNotify?page=${page}&size=${rowsPerPage}`, body, { headers: header.Authorization = localStorage.getItem("token") }).then((res) => {
                console.log(res.data, 'responcenotification');
                setNotificatoin(res.data.getdata[0].records)
                setCount(res.data.getdata[0].total)
            }).catch((err) => console.log(err))
        }

        if (auth.viewcomplaint == false) {
            var body = {
                userid: auth && auth._id,
            }


            console.log(process.env.REACT_APP_BASE_URL + `notifications/getcomplinsforfalseNotify?page=${page}&size=${rowsPerPage}`, body, { headers: header.Authorization = localStorage.getItem("token") },"apiiiii");

            console.log(body,"bodyyyy");
            axios.post(process.env.REACT_APP_BASE_URL + `notifications/getcomplinsforfalseNotify?page=${page}&size=${rowsPerPage}`, body, { headers: header.Authorization = localStorage.getItem("token") }).then((res) => {
                setNotificatoin(res.data.getdata[0].records)
                setCount(res.data.getdata[0].total)
            }).catch((err) => console.log(err))
        }
    }
    console.log(notification,"noooooooooooooooooooo");
    const handleDetails = (id) => {
        setnotificationDetailModal(true);
        getRecentActivity(id)
        axios.get(process.env.REACT_APP_BASE_URL + `complainpage/getsinglecomplaintData/${id}`,).then((res) => {
            setComplaintDetails(res.data.details)
        }).catch((err) => console.log(err))
    }

    const handleClose = () => {
        setnotificationDetailModal(false);
    };

    const Downloadfunc = (incomingdata) => {
        var urls = incomingdata
        saveAs(urls)
    }

    const [recentActivity, setrecentActivity] = React.useState([])
    const getRecentActivity = (id) => {
        axios.get(process.env.REACT_APP_BASE_URL + `recentactivity/getrecentById/${id}`,).then((res) => {
            setrecentActivity(res.data.getdata)
        }).catch((err) => console.log(err))
    }
console.log(notification,'notificationnotificationnotificationnotification');
console.log(comlaintDetails  ,'comlaintDetailscomlaintDetails');

    return (
        <React.Fragment>
            <Box className='notification'>
                <MiniDrawer type={4} />
                <Box component="section" className="contentWraper">
                    <Box className='headingBox mb-10'>
                        <Typography component='h4' variant='h4' className='Submenu-page-heading'>Notifications</Typography>
                    </Box>

                    <Box className='whiteContainer p-5'>
                        <Box className='notificationList' >
                            {notification && notification.length !== 0 ? notification.map((data, i) => {
                                return (
                                    <List sx={{ width: '100%', bgcolor: '#fff' }} onClick={(e) => handleDetails(data.complain_ID)}  >
                                        <ListItem alignItems="flex-start" >
                                            <ListItemAvatar style={{ marginTop: 6 }}>
                                                <Avatar alt={data ? data.againstname : ""} className='bg-success' />
                                            </ListItemAvatar>
                                            <ListItemText
                                                style={{ paddingLeft: 5 }}
                                                primary={
                                                    <>
                                                        <Typography component='h5' variant='h5'>{data.title}</Typography>
                                                        <Typography className='description' >
                                                            {data.description && data.description.slice(0, 200) + (data.description.length > 200 ? "..." : "")}
                                                        </Typography>
                                                        <Typography component='div' variant='' className='rightCont'>Complain type: {data.complain_type}</Typography>
                                                        <Typography component='div' variant='' className='rightCont' >Status: <span className='status' style={{ backgroundColor: data.statuscolor }}>{data.statustitle}</span></Typography>

                                                    </>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography className='notification-time fz-14'>{<Moment format="MMM D, YY" >{data.createdAt}</Moment>}</Typography>
                                                        <ListItem className='avatarGrp'>
                                                            <AvatarGroup max={3}>
                                                         
                                                                {data.Image && data.Image.map((dataa, i) => {
                                                                    return (
                                                                        <Avatar src={process.env.REACT_APP_BASE_URL + dataa.path} alt='kk' />
                                                                    )
                                                                })}
                                                            </AvatarGroup>
                                                        </ListItem>
                                                    </React.Fragment>}
                                                    
                                                    />
                                        </ListItem>
                                        <Divider variant="inset" component="li" style={{ marginLeft: -1 }} />
                                    </List>
                                )
                            }) : <Box className='table-content'>
                                <Typography component='div' variant='' style={{ textAlign: 'center' }}>No notification found</Typography>
                            </Box>}
                        </Box>
                        {notification && notification.length !== 0 && <Stack spacing={2} >
                            <Box className="patreq-pagination" sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50]}
                                    component="div"
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    className='DT-pagination'
                                />

                            </Box>
                        </Stack>}
                        <Dialog
                            open={notificationDetailModal}
                            // TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                            className='ComplainModal'
                        >
                            {console.log(comlaintDetails,"comlaintDetails")}
                            <DialogTitle>
                                <Box className="heading">
                                    <Typography component='h3' variant='' className="complain-heading">Harassment by {comlaintDetails.againstname} </Typography>
                                    <CloseRounded className=' closebtn' onClick={() => { setnotificationDetailModal(false) }} />
                                </Box>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description" className='mt-20'>
                                    <Box className='comDetails'>
                                        <Typography component='h6' variant='' className='leftCont'>Complain Type</Typography>
                                        <Typography component='div' variant='' className='rightCont'>{comlaintDetails.complain_type}</Typography>
                                    </Box>
                                    <Box className='comDetails'>
                                        <Typography component='h6' variant='' className='leftCont'>Against</Typography>
                                        <Typography component='div' variant='' className='rightCont'>{comlaintDetails.againstname}</Typography>
                                    </Box>
                                    <Box className='comDetails'>
                                        <Typography component='h6' variant='' className='leftCont'>Role</Typography>
                                        <Typography component='div' variant='' className='rightCont'>{comlaintDetails.againstrole}</Typography>
                                    </Box>
                                    <Box className='comDetails'>
                                        <Typography component='h6' variant='' className='leftCont'>Status Type</Typography>
                                        <Typography component='div' className='status' style={{ backgroundColor: comlaintDetails.statuscolor }}>{comlaintDetails.statustype}</Typography>
                                    </Box>
                                    <Box className='comDetails'>
                                        <Typography component='h6' variant='' className='leftCont'>Status</Typography>
                                        <Typography component='div' className='status' style={{ backgroundColor: comlaintDetails.statuscolor }}>{comlaintDetails.statustitle}</Typography>
                                    </Box>
                                    <Divider className='mt-30 mb-30' />
                                    <Box className='comDetails diffFile_type'>
                                        <Swiper navigation={true} modules={[Navigation]} className="mySwiper imagesSwiper mb-20" spaceBetween={10} slidesPerView={3} loop={false} centeredSlides={comlaintDetails.Image && comlaintDetails.Image.length <= 2 ? true : false} >
                                            {comlaintDetails.Image && comlaintDetails.Image.map((slideContent, index) => (
                                                slideContent.path.includes(".jpg") ?
                                                    <SwiperSlide key={slideContent.path} virtualIndex={index}>
                                                        <Box className="videoupload" style={{}}>
                                                            <img src={process.env.REACT_APP_BASE_URL + slideContent.path} alt="noimage" className='Imageeditview' />
                                                            <FileDownloadOutlined className='uploadicon' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + slideContent.path)} />
                                                        </Box></SwiperSlide> : slideContent.path.includes(".jpeg") ? <SwiperSlide key={slideContent.path} virtualIndex={index}>
                                                            <Box className="videoupload" style={{}}  >
                                                                <img src={process.env.REACT_APP_BASE_URL + slideContent.path} alt="noimage" className='Imageeditview' />
                                                                <FileDownloadOutlined className='uploadicon' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + slideContent.path)} />
                                                            </Box></SwiperSlide> : slideContent.path.includes(".png") ? <SwiperSlide key={slideContent.path} virtualIndex={index}>
                                                                <Box className="videoupload" style={{}}  >
                                                                    <img src={process.env.REACT_APP_BASE_URL + slideContent.path} alt="noimage" className='Imageeditview' />
                                                                    <FileDownloadOutlined className='uploadicon' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + slideContent.path)} />
                                                                </Box></SwiperSlide> : slideContent.path.includes(".pdf") ? <SwiperSlide key={slideContent.path} virtualIndex={index}>
                                                                    <Box className="videoupload" style={{}} >
                                                                        <img src={Pdfimage} alt="noimages" className='Imageeditview' />
                                                                        <FileDownloadOutlined className='uploadicon ' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + slideContent.path)} />
                                                                    </Box></SwiperSlide> : slideContent.path.includes(".doc") ? <SwiperSlide key={slideContent.path} virtualIndex={index}>
                                                                        <Box className="videoupload" style={{}} >
                                                                            <img src={Docimage} alt="noimages" className='Imageeditview' />
                                                                            <FileDownloadOutlined className='uploadicon ' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + slideContent.path)} />
                                                                        </Box></SwiperSlide> : slideContent.path.includes(".docx") ? <SwiperSlide key={slideContent.path} virtualIndex={index}><Box className="videoupload" style={{}} >
                                                                            <img src={Docimage} alt="noimages" className='Imageeditview' />
                                                                            <FileDownloadOutlined className='uploadicon ' onClick={() => Downloadfunc(process.env.REACT_APP_BASE_URL + slideContent.path)} />
                                                                        </Box></SwiperSlide> : ''))}
                                        </Swiper>
                                        <Swiper navigation={comlaintDetails.Video && comlaintDetails.Video.length < 2 ? false : true} modules={[Navigation]} className="mySwiper"  >
                                            {comlaintDetails.Video && comlaintDetails.Video.map((item, i) => (
                                                item.path.includes(".mp4") ?
                                                    <SwiperSlide key={item.path} virtualIndex={i}>
                                                        <Box style={{}} className='mt-20'>
                                                            <CardMedia component='iframe' src={process.env.REACT_APP_BASE_URL + item.path} alt="noimages" style={{ border: 'none', opacity: 0.7 }} />
                                                        </Box>
                                                    </SwiperSlide> : item.path.includes(".mp3") ?
                                                        <SwiperSlide key={item.path} virtualIndex={i}>
                                                            <Box style={{ boxShadow: 'none' }} className={comlaintDetails.Video && comlaintDetails.Video.length > 1 ? 'audioMb mp3Box' : 'mp3Box'} >
                                                                <audio controls src={process.env.REACT_APP_BASE_URL + item.path} style={{ maxWidth: '100%', opacity: 0.7 }}> </audio>
                                                            </Box>
                                                        </SwiperSlide> : ''))}
                                        </Swiper>
                                    </Box>
                                    <Divider className='mt-30 mb-10' />
                                    <Box className='description'>
                                        <Typography component='p'>{comlaintDetails.description}</Typography>
                                    </Box>
                                    {comlaintDetails.statustitle == 'Resolved' && comlaintDetails.isThumbsup != undefined &&
                                        <>
                                            <Divider className='mt-30 mb-20' />

                                            <Box>
                                                {console.log('omlaintDetails.statustitle:', comlaintDetails.statustitle)}
                                                <Typography component='h3' variant='' className='fz-18 mb-10 semibold'>{comlaintDetails.isThumbsup === false ? 'Dissatisfied' : 'Satisfied'}</Typography>
                                                {comlaintDetails.statustitle == 'Resolved' && comlaintDetails.isThumbsup === false ? <>
                                                    <ThumbDownOutlinedIcon className='uploadicon thumbsUp liked ' /></> : comlaintDetails.statustitle == 'Resolved' && comlaintDetails.isThumbsup === true &&
                                                <><ThumbUpAltOutlinedIcon className='uploadicon thumbsUp liked' /></>}
                                            </Box>
                                            {comlaintDetails.commentsectionforthumbsup &&
                                                <Box className='comDetails mb-20 mt-20'><Typography>"</Typography>{comlaintDetails.commentsectionforthumbsup}<Typography>"</Typography></Box>}
                                            <Divider className='mt-20 mb-10' /> </>}
                                    <Box component='div' className='activity'>
                                        <Typography component='h3' variant='' className='recent-activity-heading'> Activity
                                        </Typography>
                                        <Box component='div' className=''>
                                            {recentActivity && recentActivity.length > 0 ? recentActivity.map((item, i) => (
                                                <Box className="activity-list-item">
                                                    <img src={require('./../../../assets/images/complains-vs-resolved.png')} className="" height="40" alt="" />
                                                    <Box className="activity-list-content">
                                                        <Typography className="activity-desc mb-12">Complain status has been changed to <Typography component='span' className="by" style={{ backgroundColor: item.statuscolor, color: '#f5f3f2', paddingLeft: 4, paddingRight: 4, borderRadius: 2 }}>{item.statustitle}</Typography></Typography>
                                                        {(item.statustitle == 'Escalate' || item.statustitle == 'escalate') && <Typography className="activity-desc mb-5">Complain escalated to <Typography component='span' className="by">{item.escalated_to_name}</Typography></Typography>}
                                                        <Typography className="activity-desc"><strong>Comment :</strong> {item.statustitle === 'Re-open' ? <Typography component='span' className="activity-desc">{item.commentsectionforthumbsup}</Typography> : <Typography component='span' className="activity-desc">{item.message}</Typography>}</Typography>
                                                        <Typography className="activity-time">{moment(item.createdAt).format('lll')}</Typography>
                                                    </Box>
                                                </Box>
                                            )) : <Box className="activity-list-item mb-10 p-0" >
                                                <Typography component='div' className="activity-desc fz-14 p-0"> No Activity found </Typography>
                                            </Box>}
                                        </Box>
                                    </Box>
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default Notifications;