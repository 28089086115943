import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, FormControl, Input, Grid, Select, InputAdornment, MenuItem, RadioGroup, Radio, TextField, Avatar,Tooltip } from '@mui/material';
import { HighlightOffRounded, BorderColorOutlined, AddAPhoto, SearchOutlined, DeleteOutlineRounded, Save } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import moment from 'moment';
import { useSnackbar } from 'notistack'
import Avatarimage from "../../../../assets/images/Avatar.png"
import Spinner from '../../../../components/spinner'
import { header, headerformdata } from "../../../../components/axiousheader"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#091865',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >

                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const headCells = [
    {
        id: 'Company',
        numeric: false,
        disablePadding: true,
        label: 'Company',
        minWidth: 250
    },
    {
        id: 'Sector',
        numeric: false,
        disablePadding: false,
        label: 'Sector',
        minWidth: 120

    },
    {
        id: 'Ownership',
        numeric: false,
        disablePadding: false,
        label: 'Ownership',
        minWidth: 180

    },
    {
        id: 'Location',
        numeric: false,
        disablePadding: false,
        label: 'Location',
        minWidth: 200

    },
    {
        id: 'MemberSince',
        numeric: '',
        disablePadding: false,
        label: 'Member Since',
        minWidth: 200

    },
    {
        id: 'Users',
        numeric: true,
        disablePadding: false,
        label: '# of Users',
        minWidth: 150

    },
    {
        id: 'Reportedcomplains',
        numeric: true,
        disablePadding: false,
        label: '# of Reported Complains',
        minWidth: 220
    },
    {
        id: 'Resolved',
        numeric: true,
        disablePadding: false,
        label: '# Resolved',
        minWidth: 150

    },

];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className='DT-head'>
            <TableRow>
                <TableCell padding="checkbox"
                    className='DT-checkbox'
                >
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : headCell.id === 'MemberSince' ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className='DT-headCell'
                        style={{ minWidth: headCell.minWidth }}
                    >
                        <TableSortLabel
                            active={headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={headCell.id === 'Reportedcomplains' || 'Resolved' ? true : false}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;
    return (
        <Toolbar
            sx={{
                pl: { sm: 0 },
                pr: { xs: 0, sm: 0 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
                paddingInline: 0
            }}
        >
            <Box component='div' className='DTsearch m-0'>
                <FormControl variant="standard">
                    <Typography component='div' className='label mr-10' >Search :</Typography>
                    <TextField
                        id="filled-size-small"
                        variant="outlined"
                        size="small"
                        className='textfield search '
                        placeholder='Search Here...'
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                        }}
                    />
                </FormControl>
            </Box>

        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function Users() {

    const useStyles = styled(theme => ({
        arrow: {
          "&:before": {
            border: "1px solid #E6E8ED"
          },
          color: '#4A4A4A',
          curser:"pointer"
        },
        tooltip: {
          backgroundColor:  "#4A4A4A",
          border: "1px solid #E6E8ED",
          color: "#4A4A4A"
        }
      }));
      let classes = useStyles();
      
    const auth = JSON.parse(localStorage.getItem("userData"))
    const { enqueueSnackbar } = useSnackbar()
    let location = useLocation()
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [addUserModal, setAddUserModal] = React.useState(false)
    const [roleDetails, setRoleDetails] = React.useState([])
    var locationdata = location.state ? location.state : null
    const [roleOrder, setRoleOrder] = React.useState({ type: -1, column: 'createdAt' })
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // console.log(locationdata, 'locationdatalocationdatalocationdata');
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const [searchTerm, setSearchTerm] = React.useState('')
    const [data, setData] = React.useState([])

    React.useEffect(() => {
        getCompanydata()
        getRoles()
    }, [roleOrder.type, searchTerm, page, rowsPerPage])


    const getCompanydata = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `companies/Userdata?company_id=${locationdata && locationdata._id}&search=${searchTerm}&size=${rowsPerPage}&page=${page}`,).then((res) => {
            // console.log(res.data.list[0].records,'res.data.list[0].recordsres.data.list[0].records');
            setData(res.data.list[0].records)
            setCount(res.data.list[0].total)
        }).catch((err) => console.log(err))
    }

    const handleadduser = () => {
        setAddUserModal(true)
    }


    React.useEffect(() => {
        // getCompanydata()
        getCompanydetails()
        getSortOrder()
    }, [])

    const [companydetails, setcompanydetails] = React.useState('')
    const getCompanydetails = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `companies/CompanyDetails/${locationdata && locationdata._id}`).then((res) => {
            setcompanydetails(res.data.user)
        }).catch((err) => console.log(err))
    }

    const [user, setUser] = React.useState({ firstname: '', lastname: '', email: '', role: '', gender: 'Male', jobtitle: '', phone: '', Imageprew: "",EmergencyEmail:"", Imageraw: "", });
    const [error, setError] = React.useState({ firstname: "", email: "" })
    let username, uservalue;
    const handleChange = (e) => {
        username = e.target.name;
        uservalue = e.target.value;
        setUser({ ...user, [username]: uservalue })
        setError({ ...error, [username]: '' })
    };

    const handleaddphoto = (e) => {
        setUser({ ...user, Imageprew: URL.createObjectURL(e.target.files[0]), Imageraw: e.target.files[0] })
    }
    const validation = () => {
        if (!user.firstname) {
            setError({ firstname: "First name can not be empty" })
            return false
        }
        else if (!user.email) {
            setError({ email: "Email cannot be empty" })
            return false
        }
        else if (user.email) {
            const pattern = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")
            var valid = pattern.test(user.email)
            if (!valid) {
                setError({ email: "Please enter valid Email" })
                return false
            }
        }
        return true
    }

    const handleClosemodal = () => {
        setAddUserModal(false)
    }
    const addUserDetails = () => {
        const validator = validation()
        if (validator == true) {
            const formData = new FormData();
            formData.append("Image", user.Imageraw ? user.Imageraw : "");
            formData.append("firstname", user.firstname);
            formData.append("lastname", user.lastname);
            formData.append("email", user.email);
            formData.append("jobtitle", user.jobtitle);
            formData.append("phone", user.phone ? user.phone : '');
            formData.append("role", userRole.user_role != undefined ? userRole.user_role : "-");
            formData.append("viewcomplaint", userRole.receive_complaint ? userRole.receive_complaint : false);
            formData.append("gender", user.gender);
            formData.append("userrole", 'User');
            formData.append("role_id", 3);
            formData.append("company_id", locationdata._id);
            formData.append("companyname", locationdata.companyname);
            formData.append("superAdminId", auth && auth.company_id);
            formData.append("EmergencyEmail", user.EmergencyEmail);
            formData.append("UserRole_id",userRole._id ? userRole._id:"" );
            for (const value of formData.values()) {
                console.log(value);
            }
            axios.post(process.env.REACT_APP_BASE_URL + `companies/addUser`, formData, { headers: headerformdata.Authorization=localStorage.getItem("token") }).then((res) => {
                setUser({ firstname: '', lastname: '', email: '', role: '', jobtitle: '', phone: '', Imageprew: "", Imageraw: "" });
                enqueueSnackbar("User added successfully", { variant: 'success' })
                getCompanydata()
            }).catch((err) => {
                console.log(err)
                enqueueSnackbar(err&&err.response&&err.response.data.message?err.response.data.message:"Somthing went wront", { variant: 'error' })

            })
        }
    }

    const saveAdduser = () => {
        const validator = validation()
        if (validator == true) {
            const formData = new FormData();
            formData.append("Image", user.Imageraw ? user.Imageraw : "");
            formData.append("firstname", user.firstname);
            formData.append("lastname", user.lastname);
            formData.append("email", user.email);
            formData.append("jobtitle", user.jobtitle);
            formData.append("phone", user.phone ? user.phone : '');
            formData.append("role", userRole.user_role != undefined ? userRole.user_role : "-");
            formData.append("viewcomplaint", userRole.receive_complaint ? userRole.receive_complaint : false);
            formData.append("gender", user.gender);
            formData.append("userrole", 'User');
            formData.append("role_id", 3);
            formData.append("company_id", locationdata._id);
            formData.append("companyname", locationdata.companyname);
            formData.append("superAdminId", auth && auth.company_id);
            formData.append("EmergencyEmail", user.EmergencyEmail);
            formData.append("UserRole_id",userRole._id ? userRole._id:"" );
            for (const value of formData.values()) {
                console.log(value);
            }
            axios.post(process.env.REACT_APP_BASE_URL + `companies/addUser`, formData, { headers: headerformdata.Authorization=localStorage.getItem("token") }).then((res) => {
                setUser({ firstname: '', lastname: '', email: '', role: '', jobtitle: '', phone: '', Imageprew: "", Imageraw: "" });
                enqueueSnackbar("User added successfully", { variant: 'success' })
                getCompanydata()
                setAddUserModal(false)
            }).catch((err) => {
                console.log(err)
                enqueueSnackbar(err&&err.response&&err.response.data.message?err.response.data.message:"Somthing went wront", { variant: 'error' })

            })
        }
    }
    const handleclosemodel = () => {
        setAddUserModal(false)
        setUser({ firstname: '', lastname: '', email: '', role: '', gender: "Male", jobtitle: '', phone: '', Imageprew: "", Imageraw: "" });
        setError({ firstname: "", email: "" })
        setEditmodel(false)
        setEditData({ id: "", firstname: '', lastname: '', email: '', role: '', gender: 'Male', jobtitle: '', phone: '', image: "", Imageprew: "", Imageraw: "", userrole: '',EmergencyEmail:'' })
    }

    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };
    const [userRole, setUserRole] = React.useState('')
    const handleChangeRole = (e) => {
        setUserRole(e.target.value)
    }
console.log(userRole,"userRoleuserRoleuserRoleuserRoleuserRole");
    const [editmodel, setEditmodel] = React.useState(false)
    const [editdata, setEditData] = React.useState({ id: "", firstname: '', lastname: '', email: '', role: '', gender: 'Male', jobtitle: '', phone: '', image: "", Imageprew: "", Imageraw: "", userrole: '',EmergencyEmail:"" })
    const OpeneditModal = (data) => {
      console.log(data, 'userdetails');
      roleDetails.map((item) => {
        if(data.role == item.user_role) {
            console.log('inside if')
            setEditRole({ userRole: data.role, receive_complaint: item.receive_complaint })
        }
    })
        setEditmodel(true)
        setEditData({ id: data._id, firstname: data.firstname, lastname: data.lastname, email: data.email, role: data.role, gender: data.gender, jobtitle: data.jobtitle, phone: data.phone, image: data.Image, userrole: data.userrole,EmergencyEmail:data.EmergencyEmail })
    }

    const handleprofilephoto = (e) => {
        setEditData({ ...editdata, Imageprew: URL.createObjectURL(e.target.files[0]), Imageraw: e.target.files[0] })
    }
    const [deletemodel, setDeletemodel] = React.useState(false)
    const [deleteid, setDeleteid] = React.useState(false)


    const handleEditchange = (e) => {
        setEditData({ ...editdata, [e.target.name]: e.target.value })
    }

    const submitEdit = () => {
        const formData = new FormData();
        if (editdata.userrole == 'Main Admin') {
            formData.append("Image", editdata.Imageraw ? editdata.Imageraw : "");
            formData.append("firstname", editdata.firstname);
            formData.append("lastname", editdata.lastname);
            formData.append("jobtitle", editdata.jobtitle != null ? editdata.jobtitle : '');
            formData.append("phone", editdata.phone != null ? editdata.phone : '');
            formData.append("role", 'Main Admin');
            formData.append("viewcomplaint", true);
            formData.append("gender", editdata.gender);
            formData.append("EmergencyEmail",editdata.EmergencyEmail != null ? editdata.EmergencyEmail : '');
        }
        if ( editdata.userrole == "AdminUser") {
            formData.append("Image", editdata.Imageraw ? editdata.Imageraw : "");
            formData.append("firstname", editdata.firstname);
            formData.append("lastname", editdata.lastname);
            formData.append("jobtitle", editdata.jobtitle != null ? editdata.jobtitle : '');
            formData.append("phone", editdata.phone != null ? editdata.phone : '');
            formData.append("role", 'AdminUser');
            formData.append("viewcomplaint", true);
            formData.append("gender", editdata.gender);
            formData.append("EmergencyEmail", editdata.EmergencyEmail != null ? editdata.EmergencyEmail : '');
        }
        if (editdata.userrole == 'User') {
            formData.append("Image", editdata.Imageraw ? editdata.Imageraw : "");
            formData.append("firstname", editdata.firstname);
            formData.append("lastname", editdata.lastname);
            formData.append("jobtitle", editdata.jobtitle != null ? editdata.jobtitle : '');
            formData.append("phone", editdata.phone != null ? editdata.phone : '');
            formData.append("role", editRole.userRole !== '' ? editRole.userRole : editdata.role);
            formData.append("viewcomplaint", editRole.userRole !== '' && editRole.receive_complaint);
            formData.append("gender", editdata.gender);
            formData.append("EmergencyEmail", editdata.EmergencyEmail != null ? editdata.EmergencyEmail : '');
            formData.append("UserRole_id",editRole._id ? editRole._id: editdata.id );
        }

        for (const value of formData.values()) {
            console.log(value);
        }
        axios.put(process.env.REACT_APP_BASE_URL + `companies/EditCompany/${editdata.id}`, formData, { headers: headerformdata.Authorization=localStorage.getItem("token") }).then((res) => {
            getCompanydata()
            setEditmodel(false)
            setEditData({ id: "", firstname: '', lastname: '', email: '', role: '', gender: 'Male', jobtitle: '', phone: '', image: "", Imageraw: "", userrole: '',EmergencyEmail:'' })
            enqueueSnackbar(`${editdata.userrole} updated successfully`, { variant: "success" })
        }).catch((err) => console.log(err))
    }
    const handleDeleteData = (id) => {
        setDeletemodel(true)
        setDeleteid(id)
    }

    const DeleteSubmit = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `companies/DeleteUser/${deleteid}`, ).then((res) => {
            setDeletemodel(false)
            getCompanydata()
            enqueueSnackbar("User deleted successfully", { variant: "error" })
        }).catch((err) => console.log(err))
    }


    const [editRole, setEditRole] = React.useState({ userRole: '', receive_complaint: '' })

    // const handleEditchangeUserRole = (e) => {
    //     setEditRole({ userRole: e.target.value.user_role, receive_complaint: e.target.value.receive_complaint })
    // }


    const handleEditchangeUserRole = (e) => {
        //   console.log("Complete event:", e);
        // console.log("e.target.value:", e.target.value);
        roleDetails.map((item) => {
            if(e.target.value == item.user_role) {
                console.log('inside if')
                setEditRole({ userRole: e.target.value, receive_complaint: item.receive_complaint })
            }
        })
        
     
    }
    const getSortOrder = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `order/GetOrder/${auth.company_id}`, ).then((res) => {
            setRoleOrder({ type: res.data.order.userrole_order, column: res.data.order.userrole_name })
        }).catch((err) => console.log(err))
    }

    const body = auth && auth.role_id == 1 ? [auth.superAdminId,locationdata._id] : [auth.superAdminId, auth.company_id]
    const getRoles = () => {
        axios.post(process.env.REACT_APP_BASE_URL + `userrole/rolesdropdown?sortColumn=${roleOrder.column}&sortType=${roleOrder.type}`, body, ).then((res) => {
            let abc = res.data.userrole.filter(item => item._id != "6593da44f681835b44699c1b")
                
            setRoleDetails(abc)
          //  setRoleDetails(res.data.userrole)
// console.log(res.data.userrole,"zzzzzzzzzzzzzzzzzzzzz");
//             if(auth.role_id != 1) {
//                 let abc = res.data.userrole.filter(item => item._id != "6593da44f681835b44699c1b")
                
//                 setRoleDetails(abc)
        
//             } else {
//                 setRoleDetails(res.data.userrole)
           
//             }
        }).catch((err) => console.log(err))
    }
   
    return (
        <Box sx={{ width: '100%' }} className='p-0 +
         '>
   <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading'>Users</Typography>
                </Box>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>
                <Toolbar
                    className='toolbar'
                >
                    <Box component='div' className='DTsearch m-0' >
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10' >Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                className='textfield search '
                                placeholder='Search Here...'
                                onChange={(e) => setSearchTerm(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'flex-end' }} >
                        <Button variant="contained" className='addbtn' onClick={handleadduser} >Add User</Button>
                    </Box>
                </Toolbar>
                <TableContainer style={{ border: '1px solid #E5EBF0', borderRadius: 2 }}>
                    <Table aria-label="customized table" className='table compdet_usertable'>
                        <TableHead className='table-head'>
                            <TableRow className='table-row'>
                                <StyledTableCell align="left" sx={{ width: 200, minWidth: 200 }} className='semibold'>Profile</StyledTableCell>
                                <StyledTableCell align="left" sx={{ width: 200, minWidth: 200 }} className='semibold'>Name</StyledTableCell>
                                <StyledTableCell align="left" className='semibold' style={{ minWidth: 150 }}>User Role</StyledTableCell>
                                <StyledTableCell align="left" className='semibold' style={{ minWidth: 150 }}>Job Title</StyledTableCell>
                                <StyledTableCell align="left" className='semibold' style={{ minWidth: 100 }}>Gender</StyledTableCell>
                                <StyledTableCell align="center" className='semibold' style={{ minWidth: 150 }}>Created On</StyledTableCell>
                                <StyledTableCell align="center" className='semibold' style={{ minWidth: 200 }}>Last Login</StyledTableCell>
                                <StyledTableCell align="center" className='semibold' style={{ minWidth: 150 }}># of Complains</StyledTableCell>
                                <StyledTableCell align="center" className='semibold' style={{ minWidth: 150 }}># of Open Complains</StyledTableCell>
                                <StyledTableCell align="center" className='semibold'>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.length > 0 ? data.map((item, i) => (
                                <StyledTableRow className='tbody-row' key={i}>
                                    <StyledTableCell align="left" component="th" scope="row" padding="none" className='DT-row' style={{ minWidth: 100 }}>
                                        <Avatar src={process.env.REACT_APP_BASE_URL + item.Image} alt={item && item.firstname}  >{item && item.firstname && item.firstname.split('')[0][0]}</Avatar>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" component="th" scope="row" padding="none" className='DT-row' >
                                        <Typography className='fz-14'>{item && item.firstname ? item.firstname : "-"}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" className='DT-row'>
                                        <Typography className='fz-14'>{item && item.role ? item.role : item.userrole ? item.userrole : "-"}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" className='DT-row' >
                                        <Typography className='fz-14'>{item && item.jobtitle ? item.jobtitle : '-'}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="left" className='DT-row' ><Typography className='fz-14'>{item && item.gender ? item.gender : '-'}</Typography></StyledTableCell>
                                    <StyledTableCell align="center" className='DT-row' > <Typography className='fz-14'>{moment(item.createdAt).format('MM/YY')}</Typography> </StyledTableCell>
                                    <StyledTableCell align="center" className='DT-row'>
                                    
                                        <Typography className='fz-14' > {item && item.logouttime ? moment(item.logouttime).format('DD/MM/YYYY, hh:mm A') : "-"}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className='DT-row'>
                                        <Typography className='fz-14'>{item && item.noofcomplains ? item.noofcomplains : "-"}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align="center" className='DT-row'>
                                        <Typography className='fz-14'>{item && item.open ? item.open.length : "-"}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className='DT-row' align="center" >
                                        <Box className='actionbtns'>

                                        <Tooltip
                                                    title="Edit"
                                                    arrow
                                                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                                                >
                                                   <BorderColorOutlined style={{cursor:'pointer'}} className='edit-icon mr-10' onClick={() => OpeneditModal(item)} />
                                                </Tooltip>
                                            
                                                <Tooltip
                                                    title="Delete"
                                                    arrow
                                                    
                                                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                                                >{item.userrole == 'Admin' ? <DeleteOutlineRounded style={{cursor:'pointer'}} className='delete-icon-disable' onClick={() => enqueueSnackbar("Company admin can not be deleted", { variant: "error" })} /> : <DeleteOutlineRounded style={{cursor:'pointer'}} className='delete-icon' onClick={() => handleDeleteData(item._id)} />}
                                                 </Tooltip>
                                            {/* <BorderColorOutlined className='edit-icon mr-10' onClick={() => OpeneditModal(item)} />
                                            {item.userrole == 'Admin' ? <DeleteOutlineRounded className='delete-icon-disable' onClick={() => enqueueSnackbar("Company admin can not be deleted", { variant: "error" })} /> : <DeleteOutlineRounded className='delete-icon' onClick={() => handleDeleteData(item._id)} />} */}
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : <StyledTableCell colSpan={8} className='nodata' > NO DATA FOUND </StyledTableCell>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />
                <BootstrapDialog
                    onClose={handleClosemodal}
                    aria-labelledby="customized-dialog-title"
                    open={addUserModal}
                    className='addForm-modal '
                    id='compAddUser'
                >
                    <BootstrapDialogTitle id="customized-dialog-title" className='modal-title'>Add User</BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Grid container rowSpacing={2}>
                            <Box className='content '>
                                <Grid container rowSpacing={2} >
                                    <Grid item sm={12} md={12} lg={12}>
                                        <Box className='personal-info-tab'>
                                            <Typography component='h2' className='profile-heading' >
                                                Profile Photo
                                            </Typography>
                                            <Box className='profile-photo'>
                                                <label htmlFor="icon-button-file">
                                                    <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={handleaddphoto} />
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                        {user.Imageprew ? <Avatar src={user.Imageprew}>
                                                        </Avatar> : <Avatar src={Avatarimage} />
                                                        }
                                                        <AddAPhoto />
                                                    </IconButton>
                                                </label>
                                            </Box>
                                            <Typography className='allow-filetypes'>
                                                Allowed *.jpeg, *.jpg, *.png<br />max size of 2 MB
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>First Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='firstname'
                                                className='textfield'
                                                placeholder='First Name'
                                                value={user.firstname}
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Last Name: </Typography>

                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='lastname'
                                                className='textfield'
                                                placeholder='Last Name'
                                                value={user.lastname}
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='email'
                                                className='textfield'
                                                value={user.email}
                                                placeholder='Email'
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth size="small">
                                            <Typography component='div' className='label'>User Role:</Typography>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                name='role'
                                                onChange={handleChangeRole}
                                                value={userRole.user_role}
                                                variant='outlined'
                                                className='select'
                                            >
                                                {
                                                    roleDetails && roleDetails.map((item, i) => (
                                                        item.isdelete ==false&&
                                                        <MenuItem value={item} className='menuitems'>{item.user_role}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Gender:</Typography>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-customized-radios"
                                                name="gender"
                                                onChange={handleChange}
                                                value={user.gender}
                                            >
                                                <FormControlLabel value="Male" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Male</Typography>} />
                                                <FormControlLabel value="Female" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Female</Typography>} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Job Title:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='jobtitle'
                                                value={user.jobtitle? user.jobtitle : ''}
                                                className='textfield'
                                                placeholder='Job Title'
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Phone:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='phone'
                                                className='textfield'
                                                value={user.phone}
                                                placeholder='Phone'
                                                onInput={(e) => onlyNumbers(e)}
                                                inputProps={{ maxLength: 14 }}
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                    {userRole.receive_complaint && 
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                           
                                            <FormControl variant="standard" fullWidth>

                                                <Typography component='div' className='label'>Emergency Email:</Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='EmergencyEmail'
                                                    value={user.EmergencyEmail && user.EmergencyEmail}
                                                    className='textfield'
                                                    placeholder='Emergency email'
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />

                                            </FormControl>
                             
                                        </Grid>
}

                                </Grid>

                            </Box>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus className='modalbtn' onClick={handleclosemodel} >Cancel</Button>
                        <Button autoFocus className='modalbtn' onClick={saveAdduser} >Save</Button>
                        <Button autoFocus className='modalbtn' onClick={addUserDetails} >Save + Add Users</Button>
                    </DialogActions>
                </BootstrapDialog>


                {/* EDIT MODEL */}
                <BootstrapDialog
                    onClose={handleClosemodal}
                    aria-labelledby="customized-dialog-title"
                    open={editmodel}
                    className='addForm-modal'
                    id='compAddUser'
                >
                    <BootstrapDialogTitle id="customized-dialog-title" className='modal-title'>Update User</BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Grid container rowSpacing={2}>
                            <Box className='content p-10'>
                                <Grid container rowSpacing={2} >
                                    <Grid item sm={12} md={12} lg={12}>
                                        <Box className='personal-info-tab'>
                                            <Typography component='h2' className='profile-heading' >
                                                Profile Photo
                                            </Typography>
                                            <Box className='profile-photo'>
                                                <label htmlFor="icon-button-file">
                                                    <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={handleprofilephoto} />
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                        {editdata.Imageprew ? <Avatar src={editdata.Imageprew}>
                                                        </Avatar> : editdata.image ? <React.Suspense fallback={<Spinner />}>
                                                            <Avatar src={process.env.REACT_APP_BASE_URL + editdata.image}> </Avatar>
                                                        </React.Suspense> : <Avatar src={Avatarimage} />
                                                        }
                                                        <AddAPhoto />
                                                    </IconButton>
                                                </label>
                                            </Box>
                                            <Typography className='allow-filetypes'>
                                                Allowed *.jpeg, *.jpg, *.png<br />max size of 2 MB
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>First Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='firstname'
                                                className='textfield'
                                                placeholder='First Name'
                                                value={editdata.firstname}
                                                onChange={handleEditchange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Last Name: </Typography>

                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='lastname'
                                                className='textfield'
                                                placeholder='Last Name'
                                                value={editdata.lastname}
                                                onChange={handleEditchange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                disabled
                                                className='textfield'
                                                value={editdata.email}
                                                placeholder='Email'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>
                                    </Grid>

                                    {
                                        editdata.userrole == 'Main Admin' || editdata.userrole == "AdminUser" ? <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth size="small">
                                                <Typography component='div' className='label'>Role:</Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='role'
                                                    value={'Main Admin'}
                                                    className='textfield'
                                                    disabled
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid> :
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'>User Role:</Typography>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        id="demo-select-small"
                                                        onChange={handleEditchangeUserRole}
                                                        defaultValue={editdata.role}
                                                        variant='outlined'
                                                        className='select'
                                                        MenuProps={MenuProps}
                                                    >
                                                      {
                                                     roleDetails && roleDetails.map((item, i) => (
                                                        item.isdelete==false&&
                                                         <MenuItem value={item.user_role} className='menuitems'>{item.user_role}</MenuItem>
                                                     ))
                                                 }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                    }
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Gender:</Typography>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-customized-radios"
                                                name="gender"
                                                onChange={handleEditchange}
                                                value={editdata.gender}
                                            >
                                                <FormControlLabel value="Male" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Male</Typography>} />
                                                <FormControlLabel value="Female" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Female</Typography>} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Job Title:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='jobtitle'
                                                value={editdata.jobtitle != 'undefined' || editdata.jobtitle!= "null" ? editdata.jobtitle : ''}
                                                className='textfield'
                                                placeholder='Job Title'
                                                onChange={handleEditchange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Phone:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='phone'
                                                onInput={(e) => onlyNumbers(e)}
                                                inputProps={{ maxLength: 14 }}
                                                className='textfield'
                                                value={editdata.phone != "null" ? editdata.phone : ''}
                                                placeholder='Phone'
                                                onChange={handleEditchange}                                                
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {editRole.receive_complaint && 
                                      
                                      <Grid item xs={12} sm={12} md={12} lg={12}>
                                         
                                          <FormControl variant="standard" fullWidth>

                                              <Typography component='div' className='label'>Emergency Email:</Typography>
                                              <TextField
                                                  id="filled-size-small"
                                                  variant="outlined"
                                                  size="small"
                                                  name='EmergencyEmail'
                                                  
                                                   value={editdata.EmergencyEmail != "null"  || editdata.EmergencyEmail != 'undefined' ? editdata.EmergencyEmail : ''}
                                                   onChange={handleEditchange}
                                                  className='textfield'
                                                  placeholder='Emergency email'
                                             
                                                  InputProps={{
                                                      startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                  }}
                                              />

                                          </FormControl>
                           
                                      </Grid>
}

                                </Grid>

                            </Box>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button className='modalbtn' onClick={handleclosemodel} >Cancel</Button>
                        <Button className='modalbtn' onClick={submitEdit} >Update</Button>
                    </DialogActions>
                </BootstrapDialog>

                {/* DELETE MODEL */}

                <BootstrapDialog
                    onClose={() => setDeletemodel(false)}
                    aria-labelledby="customized-dialog-title"
                    open={deletemodel}
                    className='addForm-modal'
                >
                    <DialogContent className='deleteConfirmModal'>
                        <HighlightOffRounded className='delIcon' />

                        <Typography component='div' className='text'>Are you sure want to delete?</Typography>

                    </DialogContent>
                    <DialogActions className='deleteModalactbtns'>
                        <Button autoFocus onClick={() => setDeletemodel(false)} className='modalbtn cancel'>
                            Cancel</Button>
                        <Button autoFocus onClick={DeleteSubmit} className='modalbtn delete'>
                            Delete</Button>
                    </DialogActions>
                </BootstrapDialog>

            </Paper>

        </Box>
    );
}