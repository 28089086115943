import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { Button, ClickAwayListener,Tooltip, FormControl, Grid, FormGroup, Grow, Select, InputAdornment, Strong, MenuItem, MenuList, Popper, TextField, FormControlLabel } from '@mui/material';
import { ArrowDropDown, BorderColorOutlined, DeleteOutlineRounded, PlaylistAddRounded, HighlightOffRounded, RepeatRounded, SearchOutlined, FastfoodOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { HexColorPicker } from 'react-colorful';
import useClickOutside from './useClickOutside';
import { useSnackbar } from 'notistack'
import { header, headerformdata } from "../../../components/axiousheader"

import axios from 'axios';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



const headCells = [
    {
        id: 'Complain',
        text: true,
        disablePadding: false,
        label: 'Complain Type',
        minWidth: 250
    },
    {
        id: 'Color',
        text: false,
        disablePadding: false,
        label: 'Color',
        minWidth: 120

    },
    {
        id: 'Actions',
        text: false,
        disablePadding: false,
        label: 'Actions',
        minWidth: 180

    },


];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } =
        props;

    return (
        <TableHead className='DT-head'>
            <TableRow>
                <TableCell padding="checkbox"
                    className='DT-checkbox'
                >
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.text ? 'left' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className='DT-headCell'
                        style={{ minWidth: headCell.minWidth }}
                    >

                        {headCell.label}

                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

// ----modal-----
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



export default function DataTable() {
    const useStyles = styled(theme => ({
        arrow: {
          "&:before": {
            border: "1px solid #E6E8ED"
          },
          color: '#4A4A4A',
          curser:"pointer"
        },
        tooltip: {
          backgroundColor:  "#4A4A4A",
          border: "1px solid #E6E8ED",
          color: "#4A4A4A"
        }
      }));
      let classes = useStyles();
    const auth = JSON.parse(localStorage.getItem("userData"))
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [editModal, seteditModal] = React.useState(false);
    const [color, setColor] = React.useState("#aabbcc");
    const popover = React.useRef();
    const [isOpen, toggle] = React.useState(false);
    const [count, setCount] = React.useState(0)
    const [addModal, setaddModal] = React.useState(false);
    const close = React.useCallback(() => toggle(false), []);
    useClickOutside(popover, close);
    const [editdata, setEditdada] = React.useState({})
    const [complainType, setComplainType] = React.useState('')
    const [complainName, setComplainName] = React.useState('')
    const [search, setSearch] = React.useState('')
    const [sortorder, setSortorder] = React.useState({ type: 1, column: "createdAt" })

    const OpenaddModal = () => {
        setaddModal(true);
    };

    const [error, setError] = React.useState({ complainType: "", complainName: "" })
    const validate = () => {
        if (!complainType) {
            setError({ ...error, complainType: "Please add complain type" })
            return false
        }
        if (!complainName) {
            setError({ ...error, complainName: "Please add complain Name" })
            return false
        }
        return true
    }
    React.useEffect(() => {
        console.log("insideeeeeeeeeeeeeeeee");
        getComplains()
        getSortOrder()
    }, [search, page, rowsPerPage, sortorder.type, sortorder.column])

    const submitComplain = () => {
        var valid = validate()
        if (valid == true) {
            const body = {
                complain_type: complainType,
                compain_name: complainName,
                color: color,
                corporateid: auth.company_id
            }

            axios.post(process.env.REACT_APP_BASE_URL + 'complaintype/addcomplaintype', body, ).then((res) => {
                getComplains()
                setaddModal(false);
            }).catch((err) => console.log(err))
        }
    }
    const CloseaddModal = () => {
        setaddModal(false);
    };

    const OpeneditModal = (data) => {
        setEditdada(data)
        seteditModal(true);
    };
    const CloseeditModal = () => {
        seteditModal(false);
        setDeletemodel(false)
    };

    const [datacom, setData] = React.useState([])
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // const[sortOrder1, setSortorder1] = React.useState({'status_order':-1, 'sortname':'status_title'})
    const getSortOrder = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `order/GetOrder/${auth.company_id}`, ).then((res) => {
           // console.log(res.data, 'gggg');
            setSortorder({ type: res.data.order.complain_order, column: res.data.order.complain_name })
        }).catch((err) => console.log(err))
    }
   // console.log(sortorder, 'sortorder');



    const body = auth && auth.role_id == 1 ? [auth.company_id] : [auth.superAdminId, auth.company_id]
    const getComplains = () => {
        axios.post(process.env.REACT_APP_BASE_URL + `complaintype/GetComplaintype?page=${page}
        &size=${rowsPerPage}&search=${search}&sortColumn=${sortorder.column}&sortType=${sortorder.type}`, body, ).then((res) => {
   //         setData(res.data.Complaint)
            setCount(res.data.totalcount)
            var value = { val: false }
            res.data.Complaint && res.data.Complaint.map((item, i) => {
                Object.assign(item, value)
            })
            setData(res.data.Complaint)
        }).catch((err) => console.log(err))
    }
   // console.log(datacom,'datacomdatacomdatacomdatacom');
    const [editcolor, setEditcolor] = React.useState('')
    const [editcomplain, setEditcomlain] = React.useState("")
    const [deletemodel, setDeletemodel] = React.useState(false)
    const [deleteid, setDeleteid] = React.useState(false)

    const submitEditdata = () => {
        var body = {
            complain_type: editcomplain ? editcomplain : editdata.complain_type,
            color: editcolor ? editcolor : editdata.color,
            compain_name: complainName ? complainName : editdata.compain_name,
        }

        axios.put(process.env.REACT_APP_BASE_URL + `complaintype/EditComplaintype/${editdata._id}`, body, ).then((res) => {
            seteditModal(false)
            getComplains()
            setEditcomlain('')
            enqueueSnackbar("Complain type updated successfully", { variant: "success" })
        }).catch((err) => console.log(err))
    }

    const handleDeleteData = (id) => {
        setDeletemodel(true)
        setDeleteid(id)
    }

    const DeleteSubmit = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `complaintype/DeleteComplaintype/${deleteid}`, ).then((res) => {
            setDeletemodel(false)
            getComplains()
            enqueueSnackbar("Complain type deleted successfully", { variant: "success" })
        }).catch((err) => console.log(err))
    }

    const [deleteid1, setDeleteid1] = React.useState([])
    const [deletearry, setDeleteArray] = React.useState([])
    const [isCheckAll, setIsCheckAll] = React.useState(false);

    const CheckChange = (e, id, index) => {
        var newarray = [];
        var newdata = []
        if (e.target.checked == true) {
            datacom && datacom.map((item, i) => {
                if (i == index) {
                    item.val = e.target.checked
                }
                if (item.corporateid !== auth.company_id) {
                    console.log(datacom.length);
                    newdata.push(item.corporateid)
                }
                if (i >= newdata.length) {
                    newarray.push(item.val)
                }
                if (newarray.includes(false)) {
                    setIsCheckAll(false)
                } else {
                    setIsCheckAll(true)
                }
            })
            setDeleteid1(pre => pre.concat({ id: id, }))
            deletearry.push(id);
        }
        else {
            datacom && datacom.map((item, i) => {
                if (i == index) {
                    item.val = e.target.checked
                }
            })
            var data = deleteid1.filter(item1 => item1.id != id)
            var data1 = deletearry.filter((ids) => ids != id)
            setDeleteid1(data)
            setDeleteArray(data1)
            setIsCheckAll(false)
        }
    }

   // console.log(datacom, "datacom");
    const checkAll = (e) => {
        setIsCheckAll(!isCheckAll)
        if (e.target.checked == true) {
            datacom && datacom.map((item, i) => {
                console.log(item);
                item.val = true
                // setDeleteid1(pre => pre.concat({ id: item._id, }))
                if (item.corporateid == auth.company_id) {
                    setDeleteid1(pre => pre.concat({ id: item._id, }))
                    deletearry.push(item._id)
                }
            })
            console.log("insssssssssssssss");
            setData(datacom)
        }
        else {
            datacom && datacom.map((item, i) => {
                item.val = e.target.checked
            })
            setDeleteid1([])
            setDeleteArray([])

        }
    }



    const [bulkmodel, setBulkmodel] = React.useState(false)
    const bulkDelete = () => {
        setBulkmodel(true)
    }

    const handlebulkDelete = () => {
        var body = {
            ids: deletearry
        }
        axios.post(process.env.REACT_APP_BASE_URL + `complaintype/multipleDelete`, body, ).then((res) => {
            setBulkmodel(false)
            getComplains()
            enqueueSnackbar("Complain type deleted successfully", { variant: "success" })
            setDeleteArray([])
        }).catch((err) => console.log(err))
    }
    const handleTostmessage = () => {
        enqueueSnackbar("Unable to delete default data", { variant: "warning" })
    }
    const handleTostforEdit=()=>{
        enqueueSnackbar("Unable to edit default data", { variant: "warning" })
    }
   // console.log(deletearry, 'deletearrydeletearry');
    const handleComplaintType = (e) => {
        setComplainType(e.target.value);
        setError({ ...error, complainType: '' });
        if (e.target.value == 'Verbal') {
            setColor('#004B8D')
        }
        if (e.target.value == 'Disrespectful') {
            setColor('#1F40C9')
        }
        if (e.target.value == 'Others') {
            setColor('#0258FF')
        }
        if (e.target.value == 'Harassment') {
            setColor('#CCDEFF')
        }
        if (e.target.value == 'Abusive') {
            setColor('#FFBF00')
        }
        if (e.target.value == 'Sexual') {
            setColor('#f50202')
        }
    }
    const handleEditComplaintType = (e) => {
        setEditcomlain(e.target.value);
        setError({ ...error, complainType: '' });
        if (e.target.value == 'Verbal') {
            setEditcolor('#004B8D')
        }
        if (e.target.value == 'Disrespectful') {
            setEditcolor('#1F40C9')
        }
        if (e.target.value == 'Others') {
            setEditcolor('#0258FF')
        }
        if (e.target.value == 'Harassment') {
            setEditcolor('#CCDEFF')
        }
        if (e.target.value == 'Abusive') {
            setEditcolor('#FFBF00')
        }
        if (e.target.value == 'Sexual') {
            setEditcolor('#f50202')
        }
    }

    const handleSavedOrder = () => {
        var body = {
            complain_order: -1,
            complain_name: 'createdAt',
            company_id: auth.company_id
        }
        axios.post(process.env.REACT_APP_BASE_URL + `order/addOrder`, body, ).then((res) => {
            
            getSortOrder()
        }).catch((err) => console.log(err))
    }

    const handleAlphabeticalOrder = () => {
        var body = {
            complain_order: 1,
            complain_name: 'compain_name',
            company_id: auth.company_id
        }
        axios.post(process.env.REACT_APP_BASE_URL + `order/addOrder`, body, ).then((res) => {
            
            getSortOrder()
        }).catch((err) => console.log(err))
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box className='headingBox'>
                <Typography component='h4' variant='h4' className='Submenu-page-heading'>Complain Type</Typography>
                <Box className='heading-btns'>
                    {sortorder && sortorder.type == -1 ? <Button variant="contained" className='addbtn  mr-10 mb-10' id='disablebtn' onClick={handleSavedOrder} disabled>Saved Order</Button> : <Button variant="contained" className='addbtn mr-10 mb-10' onClick={handleSavedOrder}>Saved Order</Button>}
                    {sortorder && sortorder.type == 1 ? <Button variant="contained" className='addbtn mr-10 mb-10' id='disablebtn' onClick={handleAlphabeticalOrder} disabled> Save in Alphabetical Order</Button> : <Button variant="contained" className='addbtn mr-10 mb-10' onClick={handleAlphabeticalOrder}> Save in Alphabetical Order</Button>}
                    {deleteid1 && deleteid1.length > 0 ? <Button variant="contained" className='mb-10' style={{
                        background: "#f64e60",  boxShadow: 'none',
                        height: 34,
                        textTransform: 'capitalize'
                    }} onClick={bulkDelete} >Delete</Button> : ""}
                </Box>
            </Box>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='settings-Datatable'>
                <Toolbar className='toolbar'>
                    <Button variant="text" className='addbtn-modal' onClick={OpenaddModal}><PlaylistAddRounded className='mr-10 fz-18' />Add Complain Type</Button>
                    <Box component='div' className='DTsearch'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10' >Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                className='textfield search '
                                placeholder='Search Here...'
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                                }}
                            />
                        </FormControl>
                    </Box>
                </Toolbar>
                <TableContainer component={Paper} >
                    <Table aria-label="customized table" className='table'>
                        <TableHead className='table-head'>
                            <TableRow className='table-row'>
                                <StyledTableCell align="center" width={150} className='checkbox'></StyledTableCell>
                                {/* <StyledTableCell align="center" width={150} className='checkbox'><FormControlLabel control={<Checkbox onChange={checkAll} checked={isCheckAll} />} /></StyledTableCell> */}
                                <StyledTableCell align="left" sx={{ width: 200, minWidth: 200 }} className='semibold'><strong>Complain name</strong></StyledTableCell>
                                <StyledTableCell align="left" sx={{ width: 200, minWidth: 200 }} className='semibold'><strong>Complain Type</strong></StyledTableCell>
                                <StyledTableCell align="center" className='semibold'><strong>Color</strong></StyledTableCell>
                                <StyledTableCell align="center" className='semibold'><strong>Actions</strong></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {datacom && datacom.length > 0 ? datacom.map((item, i) => (
                            //    console.log(item.val,"000000000000000000000"),
                                <StyledTableRow className='tbody-row' key={i}>
                                    <StyledTableCell align="center" width={50} className='checkbox'>
                                        {auth && auth.company_id === item.corporateid ? <FormGroup>
                                            <FormControlLabel style={{ justifyContent: "center" }} control={<Checkbox onChange={(e) => CheckChange(e, item._id, i)} checked={item && item.val} size='medium' />} />
                                        </FormGroup> : <FormGroup>
                                            <FormControlLabel style={{ justifyContent: "center" }} control={<Checkbox disabled size='medium' />} />
                                        </FormGroup>}
                                    </StyledTableCell>
                                    <StyledTableCell className='DT-row' align="left" >
                                        <Typography className='fz-14'>{item.compain_name ? item.compain_name : '-'}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className='DT-row' align="left" >
                                        <Typography className='fz-14'>{item.complain_type ? item.complain_type : '-'}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className='DT-row' align="center" >
                                        <Typography className='DT-row' ><Box className='colorCell' style={{ backgroundColor: item.color }}></Box></Typography>
                                    </StyledTableCell>

                                    <StyledTableCell className='DT-row' align="center" >
                                        {auth && auth.company_id === item.corporateid ? <Box className='actionbtns'>
                                        <Tooltip title="Update" arrow classes={{arrow: classes.arrow, tooltip: classes.tooltip }}><BorderColorOutlined style={{cursor:'pointer'}} className='edit-icon mr-10' onClick={() => OpeneditModal(item)} /></Tooltip>
                                        <Tooltip title="Delete" arrow classes={{arrow: classes.arrow, tooltip: classes.tooltip }}><DeleteOutlineRounded style={{cursor:'pointer'}} className='delete-icon' onClick={() => handleDeleteData(item._id)} /></Tooltip>
                                        </Box> : <Box className='actionbtns'>
                                            <Tooltip title="Update" arrow classes={{arrow: classes.arrow, tooltip: classes.tooltip }}><BorderColorOutlined style={{cursor:'pointer'}} className='edit-icon-disable mr-10' onClick={handleTostforEdit} /></Tooltip>
                                            <Tooltip title="Delete" arrow classes={{arrow: classes.arrow, tooltip: classes.tooltip }}><DeleteOutlineRounded style={{cursor:'pointer'}} className='delete-icon-disable' onClick={handleTostmessage} /></Tooltip>
                                        </Box>}
                                    </StyledTableCell>
                                </StyledTableRow>
                            )) : <StyledTableCell colSpan={4} className='nodata' > NO DATA FOUND </StyledTableCell>}

                        </TableBody>
                    </Table>

                    <BootstrapDialog
                        onClose={CloseaddModal}
                        aria-labelledby="customized-dialog-title"
                        open={addModal}
                        className='addForm-modal'
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={CloseaddModal} className='modal-title'>Add Complain Type</BootstrapDialogTitle>
                        <DialogContent dividers>
                            <Grid container columnSpacing={0} rowSpacing={2}>
                                <Grid item xs={12} sm={12} md={4} >
                                    <Typography component='div' className='label'>Complain name</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            size="small"
                                            className='textfield'
                                            placeholder='Complain name'
                                            onChange={(e) => setComplainName(e.target.value)}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            }}
                                        />
                                        {error.complainName && <p className='error-field'>{error.complainName}</p>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} >
                                    <Typography component='div' className='label'>Complain Type</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} >

                                    <FormControl variant="standard" fullWidth>
                                        <Select
                                            name='complainType'
                                            className='select'
                                            value={complainType}
                                            size='small'
                                            onChange={handleComplaintType}
                                            // endAdornment={<InputAdornment position="start"></InputAdornment>}
                                            MenuProps={MenuProps}
                                            variant='outlined'

                                        >
                                            <MenuItem value={'Verbal'}>Verbal </MenuItem>
                                            <MenuItem value={'Harassment'}>Harassment </MenuItem>
                                            <MenuItem value={'Disrespectful'}>Disrespectful</MenuItem>
                                            <MenuItem value={'Sexual'}>Sexual</MenuItem>
                                            <MenuItem value={'Abusive'}>Abusive</MenuItem>
                                            <MenuItem value={'Others'}>Others</MenuItem>
                                        </Select>
                                        {error.complainType && <p className='error-field'>{error.complainType}</p>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Typography component='div' className='label'>Color</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <Box className="picker">
                                        <Box
                                            className="swatch"
                                            style={{ backgroundColor: color, width: 30, height: 30 }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={submitComplain} className='modalbtn'>
                                Create
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>
                    <BootstrapDialog
                        onClose={CloseeditModal}
                        aria-labelledby="customized-dialog-title"
                        open={editModal}
                        className='addForm-modal'
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={CloseeditModal} className='modal-title'>Update Complain Type</BootstrapDialogTitle>
                        <DialogContent dividers>
                            <Grid container columnSpacing={2} rowSpacing={2}>
                                <Grid item xs={12} sm={12} md={4} >
                                    <Typography component='div' className='label'>Complain name</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            size="small"
                                            className='textfield'
                                            placeholder='Complain name'
                                            onChange={(e) => setComplainName(e.target.value)}
                                            defaultValue={editdata.compain_name}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            }}
                                        />
                                        <p className='error-field'>{error.complainName}</p>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} >
                                    <Typography component='div' className='label'>Complain Type</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <FormControl variant="standard" fullWidth>
                                        <Select
                                            name='complainName'
                                            className='select'
                                            defaultValue={editdata.complain_type}
                                            size='small'
                                            onChange={handleEditComplaintType}
                                            endAdornment={<InputAdornment position="start"></InputAdornment>}
                                            MenuProps={MenuProps}
                                            variant='outlined'
                                        >
                                            <MenuItem value={'Verbal'}>Verbal </MenuItem>
                                            <MenuItem value={'Harassment'}>Harassment </MenuItem>
                                            <MenuItem value={'Disrespectful'}>Disrespectful</MenuItem>
                                            <MenuItem value={'Sexual'}>Sexual</MenuItem>
                                            <MenuItem value={'Abusive'}>Abusive</MenuItem>
                                            <MenuItem value={'Others'}>Others</MenuItem>
                                        </Select>
                                        <p className='error-field'>{error.complainType}</p>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Typography component='div' className='label'>Color</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <Box className="picker">
                                        <Box
                                            className="swatch"
                                            style={{ backgroundColor: editcolor !== '' ? editcolor : editdata.color, width: 30, height: 30 }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>


                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={submitEditdata} className='modalbtn'>
                                <RepeatRounded className='fz-18' style={{ marginRight: 6 }} /> Update
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>

                    <BootstrapDialog
                        onClose={CloseeditModal}
                        aria-labelledby="customized-dialog-title"
                        open={deletemodel}
                        className='addForm-modal'
                    >
                        <DialogContent className='deleteConfirmModal'>
                            <HighlightOffRounded className='delIcon' />

                            <Typography component='div' className='text'>Are you sure want to delete?</Typography>

                        </DialogContent>
                        <DialogActions className='deleteModalactbtns'>
                            <Button autoFocus onClick={() => setDeletemodel(false)} className='modalbtn cancel'>
                                Cancel</Button>
                            <Button autoFocus onClick={DeleteSubmit} className='modalbtn delete'>
                                Delete</Button>
                        </DialogActions>
                    </BootstrapDialog>

                    {/* BULK DELETE */}
                    <BootstrapDialog
                        onClose={CloseeditModal}
                        aria-labelledby="customized-dialog-title"
                        open={bulkmodel}
                        className='addForm-modal'
                    >
                        {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={CloseeditModal} className='modal-title'> Delete</BootstrapDialogTitle> */}
                        <DialogContent className='deleteConfirmModal'>
                            <HighlightOffRounded className='delIcon' />

                            <Typography component='div' className='text'>Are you sure want to delete?</Typography>

                        </DialogContent>
                        <DialogActions className='deleteModalactbtns'>
                            <Button autoFocus onClick={() => setBulkmodel(false)} className='modalbtn cancel'>
                                Cancel</Button>
                            <Button autoFocus onClick={handlebulkDelete} className='modalbtn delete'>
                                Delete</Button>
                        </DialogActions>
                    </BootstrapDialog>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[ 10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />
            </Paper>

        </Box>
    );
}