import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from './../../../../components/drawer'
import BasicTabs from './Tabs'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import { header, headerformdata } from "../../../../components/axiousheader"

export default function CompanyDetail() {   
    let navigate = useNavigate()
    let location = useLocation()
    const [datacom, setData] = React.useState([])

    const AddCompany = () => {
        navigate('/AddCompany');
    }

    React.useEffect(() => {
        getCompanydata()
    }, [])

    const getCompanydata = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `companies/CompanyDetails?company_id=${location && location.state._id}`, ).then((res) => {    
            console.log(res.data.list,'company details');
            setData(res.data.list)
        }).catch((err) => console.log(err))
    }    


    return (
        <Box className='companyDetails'>
            <MiniDrawer />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading'>Company Details</Typography>
                </Box>
                <Box className='whiteContainer p-20'>
                    <Grid container rowSpacing={3}>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={2} className='leftcontent'>
                            <Box className='browse'>
                                {/* <Button variant="contained" component="label" className='browse-file'>
                                    <input hidden accept="image/*" multiple type="file" />
                                </Button> */}
                                <Box className='logobox'>
                                    {/* <img src={require('./../../../../assets/images/infomaze-logo.png')}></img> */}
                                    <img src={datacom.Image ? process.env.REACT_APP_BASE_URL+datacom.Image : require('./../../../../assets/images/Avatar.png')}></img>
                                </Box>
                                {/* <Typography component='span' className='help-block'>Accepted formats: gif, png, jpg. Max file size 2Mb</Typography> */}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                            <Box className="company-info">
                                <Typography component='h2' className=''>{datacom && datacom.companyname}</Typography>
                                <Box className="all-details">
                                    
                                    <Box>
                                        <Typography component='h6'> Industry</Typography>
                                        <Typography component='p'> {datacom && datacom.industry}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography component='h6'> Member Since</Typography>
                                        <Typography component='p'> {datacom && moment(datacom.createdAt).format('D MMM YY')}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography component='h6'> # of Complains</Typography>
                                        <Typography component='p'> {datacom && datacom.noofcomplains}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography component='h6'> Closed</Typography>
                                        <Typography component='p'> {datacom && datacom.resolved && datacom.resolved.length}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography component='h6'> Open</Typography>
                                        <Typography component='p'> {datacom && datacom.open && datacom.open.length} </Typography>
                                        {/* <Typography component='p' className="text-danger">(200 over 30 days)</Typography> */}
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <BasicTabs datacom={datacom} setData={setData} />
                </Box>
            </Box>
        </Box>
    )
}
