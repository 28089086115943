import { Box, Grid, Typography, Select, MenuItem, FormControl, TextField } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import * as React from 'react';
import MiniDrawer from '../../../components/drawer'
import ReactEcharts from "echarts-for-react";
import axios from 'axios';
import moment from 'moment'
import { header, headerformdata } from "../../../components/axiousheader"

const seriesLabel = {
    show: true,
    position: 'right',
};


export default function SuperAdmin() {
    const auth = JSON.parse(localStorage.getItem("userData"))
    const [piedata, setPiedata] = React.useState([])
    const [companyregister, setCompanyregiste] = React.useState([])
    const [complaitype, setComplaitype] = React.useState([])
    const [lessthansevendays, setLessthansevendays] = React.useState([])
    const [sevenTofourteen, setSevenTofourteen] = React.useState([])
    const [fifteenTotwentytwo, setFifteenTotwentytwo] = React.useState([])
    const [twentythreetothirty, setTwentythreetothirty] = React.useState([])
    const [notclosed, setNotclosed] = React.useState([])

    const [company, setCompanyregister] = React.useState([])

    //FILIER FOR COMPANIES
    const [selectdayforgroup, setSelectDayforgroup] = React.useState("all")
    const [fromdate, setFromdate] = React.useState(moment().startOf('year').toDate());
    const [todate, setTodate] = React.useState(moment().endOf('year').toDate());

    //FILIER FOR COMPLAINS
    const [selectdayforcompany, setSelectDayforcompany] = React.useState("all")
    const [fromdateforcompany, setFromdateforcompany] = React.useState(moment().startOf('year').toDate());
    const [todateforcompany, setTodateforcompany] = React.useState(moment().endOf('year').toDate());
    const [compnydate, setcompanydate] = React.useState(false)

    //For COPMPALINT TYPE
    const [dateforcomptype, setDateforcomptype] = React.useState('')
    const [dateforcomptypeto, setDateforcomptypeto] = React.useState('')

    //date filter for Resolve
    const [resolvedateFrom, setresolvedateFrom] = React.useState('')
    const [resolvedateTo, setresolvedateTo] = React.useState('')


    // console.log(companyregister, "companyregister");
    const gettest = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `complainpage/CompanyOwnership`,).then((res) => {
            setPiedata(res.data.data)
        }).catch((err) => console.log(err))

    }
    const getCompaniesRegistered = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `complainpage/complainResolverApi?fromdate=${resolvedateFrom}&todate=${resolvedateTo}`,).then((res) => {
            setCompanyregiste(res.data.data)
        }).catch((err) => console.log(err))
    }
    const getComplaintType = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `complaintype/Complains?fromdate=${dateforcomptype}&todate=${dateforcomptypeto}`,).then((res) => {
            setComplaitype(res.data.data)
        }).catch((err) => console.log(err))
    }

    const getDaysGraph = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `complainpage/daystoclosegraph`,).then((res) => {
            {
                res.data.series && res.data.series.map((item, i) => {
                    if (item.name == 'less than 7') {
                        setLessthansevendays(item.data)
                    }
                    if (item.name == '7-14') {
                        setSevenTofourteen(item.data)
                    }
                    if (item.name == '15-22') {
                        setFifteenTotwentytwo(item.data)
                    }
                    if (item.name == '23-30 days') {
                        setTwentythreetothirty(item.data)
                    }
                    if (item.name == 'Not Closed') {
                        setNotclosed(item.data)
                    }
                })
            }
        }).catch((err) => console.log(err))
    }

    React.useEffect(() => {
        gettest()
        getCompaniesRegistered()
        getComplaintType()
        getCompanyRegister()
        getComplaines()
        getmostComplaincompany()
        getDaysGraph()
    }, [fromdate, todate, fromdateforcompany, todateforcompany, dateforcomptype, dateforcomptypeto, resolvedateFrom, resolvedateTo])

    const closingComplainsOptions = {
        color: ['#004B8D', '#1F40C9', '#0258FF', '#CCDEFF', '#FFBF00'],
        legend: {
            data: ['Closed in < 7days', '7-14', '15-22', '23-30 days', 'Not Closed'],
            bottom: 0,
            icon: 'circle',
            "formatter": (name) => { return name + '   ' },
        },
        grid: {
            left: 85,
            bottom:100
        },
        xAxis: {
            type: 'value',
            // axisLabel: {
            //   formatter: '{value}'
            // }
            splitNumber: 2
        },
        yAxis: {
            type: 'category',
            inverse: true,
            data: ['Verbal', 'Abusive', 'Sexual', 'Harassment', 'Disrespectful', 'Others'],
        },
        series: [
            {
                name: 'Closed in < 7days',
                type: 'bar',
                data: lessthansevendays,
                label: seriesLabel,
                barGap: '35%',
                itemStyle: {
                    emphasis: {
                        barBorderRadius: [50, 50]
                    },
                    normal: {
                        barBorderRadius: [0, 6, 6, 0]
                    }
                }
            },
            {
                name: '7-14',
                type: 'bar',
                label: seriesLabel,
                barGap: '35%',
                data: sevenTofourteen,
                itemStyle: {
                    emphasis: {
                        barBorderRadius: [50, 50]
                    },
                    normal: {
                        barBorderRadius: [0, 6, 6, 0]
                    }
                }
            },
            {
                name: '15-22',
                type: 'bar',
                label: seriesLabel,
                barGap: '35%',
                data: fifteenTotwentytwo,
                itemStyle: {
                    emphasis: {
                        barBorderRadius: [50, 50]
                    },
                    normal: {
                        barBorderRadius: [0, 6, 6, 0]
                    }
                }
            },
            {
                name: '23-30 days',
                type: 'bar',
                data: twentythreetothirty,
                label: seriesLabel,
                barGap: '35%',
                itemStyle: {
                    emphasis: {
                        barBorderRadius: [50, 50]
                    },
                    normal: {
                        barBorderRadius: [0, 6, 6, 0]
                    }
                }
            },
            {
                name: 'Not Closed',
                type: 'bar',
                label: seriesLabel,
                barGap: '35%',
                data: notclosed,
                itemStyle: {
                    emphasis: {
                        barBorderRadius: [50, 50]
                    },
                    normal: {
                        barBorderRadius: [0, 6, 6, 0]
                    }
                }
            },

        ]
    };


    const handlecopmlainttypefrom = (e) => {
        setDateforcomptype(e)
    }
    const handlecopmlainttypeto = (e) => {
        setDateforcomptypeto(e)
    }
    const complainsTypeOptions = {
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            left: 'center',
            top: 'bottom',
            icon: 'pin',
            textStyle: {
                fontFamily: 'Nunito',
                lineHeight: 24,
            },
            "formatter": (name) => { return name + '   ' },
        },
     
        series: [
            {
                name: 'Complains Type',
                type: 'pie',
                radius: ['10%', '70%'],
                center: ['50%', '45%'],
                roseType: 'radius',
                itemStyle: {
                    borderRadius: 5
                },
                color: ['#004B8D', '#f50202', '#0258FF', '#CCDEFF', '#FFBF00', '#1F40C9'],
                label: {
                    show: true,
                    "formatter": (params) => params.value,
                    textStyle: {
                        fontFamily: 'Nunito',
                    }
                },
                emphasis: {
                    label: {
                        show: true
                    }
                },
                data: complaitype.sort(function (a, b) {
                    return a.value - b.value;
                }),
                labelLayout: {

                    moverOverlap: 'shiftY'
                },
                labelLine: {
                    length: 5
                },
                avoidLabelOverlap: true,
             
            },
        ]
    };


    const handleresolveFromcomplain = (e) => {
        setresolvedateFrom(e)

    }

    const handleresolveTocomplain = (e) => {
        setresolvedateTo(e)
    }

    const complainsResolvedOptions = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            bottom: 'left',

            icon: 'circle',
            textStyle: {
                fontWeight: 700,
                fontFamily: 'Nunito',
            },
        },
        series: [
            {
                name: 'Open Vs Resolved',
                type: 'pie',
                radius: ['40%', '60%'],
                // center: ['35%', '50%'],
                avoidLabelOverlap: false,
                color: ['#0258FF', '#CCDEFF'],
                itemStyle: {
                    borderRadius: 10,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '22',
                        fontWeight: 'bold',
                        fontFamily: 'Nunito',
                    }
                },
                labelLine: {
                    show: false
                },
                data: companyregister
            }
        ]
    };


    const ownershipChartOptions = {
        color: ['#004B8D', '#1F40C9', '#CCDEFF', '#FFBF00'],
        tooltip: {
            trigger: 'item',
        },

        legend: {
            orient: 'vertical',
            left: 'left',
            top: '20',
            itemStyle: {
            },
            textStyle: {
                fontWeight: 700,
                fontFamily: 'Nunito',
            },
            icon: 'pin',

        },
        series: [
            {
                name: 'Access From',
                type: 'pie',
                radius: ['50%', '70%'],
                data: piedata,
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: '22',
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },

            }
        ]
    };


    const selectDayforgroup = (e) => {
        setSelectDayforgroup(e.target.value)
        setcompanydate(false)
        switch (e.target.value) {
            case 'week':
                setFromdate(moment().startOf('week').toDate())
                setTodate(moment().endOf('week').toDate())
                break;
            case 'month':
                setFromdate(moment().startOf('month').toDate())
                setTodate(moment().endOf('month').toDate())
                break;

            case 'year':
                setFromdate(moment().startOf('year').toDate())
                setTodate(moment().endOf('year').toDate())
                break;

            default:
        }
    }
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    var values = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    const getCompanyRegister = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `complainpage/Registerdcompany?fromdate=${fromdate}&todate=${todate}`,).then((res) => {
            setCompanyregister(res.data.company)
        }).catch((err) => console.log(err))
    }

    for (let i = 0; i < company.length; i++) {
        for (let j = 0; j < months.length; j++) {
            if (company[i].date == months[j]) {
                // console.log(months.indexOf(months[j]),'index')
                var index = months.indexOf(months[j])
                values[j] = company[i].count
                // console.log(values[j],'values[j]');
            }
        }
    }


    const companiesChartOptions = {

        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: months,
            boundaryGap: false,
        },
        yAxis: {
            type: 'value',
            splitNumber: 1,

        },

        series: [
            {
                data: values,
                label: {
                    show: true,
                    position: 'top'
                },
                type: 'line',
                smooth: true,
                areaStyle: {
                    opacity: 0.8,
                    // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    //     {
                    //         offset: 0,
                    //         color: '#0258FF'
                    //     },
                    //     {
                    //         offset: 0.5,
                    //         color: '#CCDEFF'
                    //     },
                    //     {
                    //         offset: 1,
                    //         color: '#fff'
                    //     }
                    // ])
                },
            }
        ]
    };

    const selectDayforcompanies = (e) => {
        setSelectDayforcompany(e.target.value)
        setcompanydate(true)
        switch (e.target.value) {
            case 'week':
                setFromdateforcompany(moment().startOf('week').toDate())
                setTodateforcompany(moment().endOf('week').toDate())
                break;
            case 'month':
                setFromdateforcompany(moment().startOf('month').toDate())
                setTodateforcompany(moment().endOf('month').toDate())
                break;

            case 'year':
                setFromdateforcompany(moment().startOf('year').toDate())
                setTodateforcompany(moment().endOf('year').toDate())
                break;

            default:
        }
    }

    var complainsmonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
    var complainesvalues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    const [comlaines, setComlaines] = React.useState([])

    const getComplaines = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `complaintype/complaingraph?fromdate=${fromdateforcompany}&todate=${todateforcompany}`,).then((res) => {
            setComlaines(res.data.complains)
        }).catch((err) => console.log(err))
    }

    for (let i = 0; i < comlaines.length; i++) {
        for (let j = 0; j < complainsmonths.length; j++) {
            if (comlaines[i].date == months[j]) {
                // console.log(months.indexOf(months[j]))
                var index = months.indexOf(months[j])
                complainesvalues[j] = comlaines[i].count
            }
        }
    }


    const complainsChartOptions = {
        tooltip: {
            trigger: 'axis'
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: complainsmonths,
            boundaryGap: false,
            //boundaryGap: ['2%', '2%']
        },
        yAxis: {
            type: 'value',
            //splitNumber: 5
            //data: [0, 1, 2, 3, 4, 5, 6, 7, 8]
        },
        series: [
            {
                data: complainesvalues,
                label: {
                    show: true,
                    position: 'top'
                },
                type: 'line',
                //smooth: true,
                areaStyle: {
                    opacity: 0.8,
                    // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    // {
                    //     offset: 0,
                    //     color: '#0258FF'
                    // },
                    // {
                    //     offset: 0.5,
                    //     color: '#CCDEFF'
                    // },
                    // {
                    //     offset: 1,
                    //     color: '#fff'
                    // }
                    // ])
                },
            }
        ]
    };

    const [mostcolmplaincompany, setMostcoplaincompain] = React.useState([])

    const getmostComplaincompany = () => {

        axios.get(process.env.REACT_APP_BASE_URL + 'complainpage/mostcomplains',).then((res) => {
            // console.log(res.data, 'ghjggjgjh');
            setMostcoplaincompain(res.data.mostcomplains)
        }).catch((err) => console.log(err))
    }

    const mostComplainsOptions = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            // orient: 'vertical',
            // top: 20,
            bottom: 0,
            //left: 'middle',
            icon: 'circle',
        },
        series: [
            {
                name: 'Complained Against',
                type: 'pie',
                radius: '50%',
                center: ['50%', '35%'],
                color: ['#004B8D', '#1F40C9', '#0258FF', '#CCDEFF', '#FFBF00'],
                label: { "formatter": (params) => params.value },
                data: mostcolmplaincompany,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                },
                labelLayout: {

                    moverOverlap: 'shiftY'
                },
                labelLine: {
                    length: 5
                },
                avoidLabelOverlap: true
            }
        ],

    };


    React.useEffect(() => {
        getCount()
    }, [])

    const [countvaalues, setCount] = React.useState({ TotalCompanyCounts: 0, Totalcomplains: 0, resolvedcomplains: 0, verbalcomplains: 0, sexualcomplains: 0 })
    const getCount = () => {
        axios.get(process.env.REACT_APP_BASE_URL + 'companies/DashbordCount',).then((res) => {
            setCount({ TotalCompanyCounts: res.data.TotalCompanyCounts, Totalcomplains: res.data.Totalcomplains, resolvedcomplains: res.data.resolvedcomplains, verbalcomplains: res.data.verbalcomplains, sexualcomplains: res.data.sexualcomplains })
        }).catch((err) => console.log(err))
    }

    return (
        <Box className='dashboard'>
            <MiniDrawer type={0} />
            <Box component="section" className="contentWraper">
                <Typography component='h4' variant='h4' className='page-heading'>Dashboard</Typography>

                <Grid container columnSpacing={4} rowSpacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Box className='whiteContainer'>
                            <Grid container columnSpacing={4} rowSpacing={2}>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box component='div' className='cards'>
                                        <Box component='div' className='card-img'>
                                            <img src={require('./../../../assets/images/companies.png')} width='100%'></img>
                                        </Box>
                                        <Box component='div' className='card-content'>
                                            <Typography component='h3' variant='h3' className='card-value'>{countvaalues.TotalCompanyCounts}</Typography>
                                            <Typography component='h6' className='card-title'>Companies</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box component='div' className='cards'>
                                        <Box component='div' className='card-img'>
                                            <img src={require('./../../../assets/images/complains-vs-resolved.png')} width='100%'></img>
                                        </Box>
                                        <Box component='div' className='card-content'>
                                            <Typography component='h3' variant='h3' className='card-value'>
                                                <Typography component='span' variant='body1' className='mr-10'>{countvaalues.Totalcomplains} <span style={{ color: '#89979F', fontSize: 20 }}>Vs</span></Typography>
                                                <Typography component='span' variant='body1' >{countvaalues.resolvedcomplains}</Typography>
                                            </Typography>
                                            <Typography component='h6' className='card-title'>Complains Vs Resolved</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box component='div' className='cards'>
                                        <Box component='div' className='card-img'>
                                            <img src={require('./../../../assets/images/verbal-complains.png')} width='100%'></img>
                                        </Box>
                                        <Box component='div' className='card-content'>
                                            <Typography component='h3' variant='h3' className='card-value'>{countvaalues.verbalcomplains}</Typography>
                                            <Typography component='h6' className='card-title'>Verbal Complains</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box component='div' className='cards'>
                                        <Box component='div' className='card-img'>
                                            <img src={require('./../../../assets/images/sexual-complains.png')} width='100%'></img>
                                        </Box>
                                        <Box component='div' className='card-content'>
                                            <Typography component='h3' variant='h3' className='card-value'>{countvaalues.sexualcomplains}</Typography>
                                            <Typography component='h6' className='card-title'>Sexual Complains</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container columnSpacing={3} className='mt-20 dashboard_rows'>
                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                <Box className='whiteContainer h-100p'>
                                    <Box component='div' className='chart'>
                                        <Typography component='h3' variant='' className='chart-heading'>Companies Registered</Typography>
                                        <Box className='flexContainer'>

                                            <Box className='calendarBox'>
                                                <><Box className='flex-box alignItems_center mr-10'>
                                                    <Box style={{ width: '92%' }} className='label'>
                                                        Fetch From
                                                    </Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Box className='datepicker '>
                                                            <DesktopDatePicker
                                                                // label="From"
                                                                inputFormat="dd/MM/yyyy"
                                                                value={fromdate}
                                                                className='textfield'
                                                                // onChange={handleChangeFromdate}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                </Box>
                                                    <Box className='flex-box alignItems_center  toText'>
                                                        <Box className='label'>
                                                            To
                                                        </Box>
                                                        <LocalizationProvider dateAdapter={AdapterDateFns} style={{ alignItems: 'flex-start', marginLeft: '20px' }}>
                                                            <Box className='datepicker '>

                                                                <DesktopDatePicker
                                                                    // label="To"
                                                                    inputFormat="dd/MM/yyyy"
                                                                    value={todate}
                                                                    // onChange={handleChangeTodate}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                    className='textfield'

                                                                />
                                                            </Box>
                                                        </LocalizationProvider>
                                                    </Box></>
                                            </Box>
                                            <Box className='flex-box heading' >
                                                <Select style={{ maxHeight: '37px', width: '100%', height: '100%' }}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    className='select fz-14'
                                                    value={selectdayforgroup}
                                                    onChange={selectDayforgroup}
                                                >
                                                    <MenuItem value={'all'}>All </MenuItem>
                                                    <MenuItem value={'week'}>Week</MenuItem>
                                                    <MenuItem value={'month'}>Month</MenuItem>

                                                    <MenuItem value={'year'}>Year</MenuItem>
                                                </Select>
                                            </Box>
                                        </Box>
                                        <ReactEcharts option={companiesChartOptions} className='echarts' />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} >
                                <Box className='whiteContainer h-100p'>
                                    <Box component='div' className='chart  '>
                                        <Typography component='h3' variant='' className='chart-heading'>Companies Ownership</Typography>
                                        <ReactEcharts option={ownershipChartOptions} className='echarts chart600' />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={3} className='mt-20 dashboard_rows'>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box className='whiteContainer h-100p'>
                                    <Box component='div' className='chart '>
                                        <Typography component='h3' variant='' className='chart-heading'>Complains Type</Typography>
                                        <Box className='flexContainer'>

                                            <Box className='calendarBox'>
                                                <Box className='flex-box alignItems_center mr-10'>
                                                    <Box style={{ width: '92%' }} className='label'>
                                                        Fetch From
                                                    </Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Box className='datepicker '>
                                                            <DesktopDatePicker
                                                                // label="From"
                                                                inputFormat="dd/MM/yyyy"
                                                                value={dateforcomptype}
                                                                className='textfield'

                                                                onChange={handlecopmlainttypefrom}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                </Box>
                                                <Box className='flex-box alignItems_center  toText'>
                                                    <Box className='label'>
                                                        To
                                                    </Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} style={{ alignItems: 'flex-start', marginLeft: '20px' }}>
                                                        <Box className='datepicker '>

                                                            <DesktopDatePicker
                                                                // label="To"
                                                                inputFormat="dd/MM/yyyy"
                                                                value={dateforcomptypeto}
                                                                onChange={handlecopmlainttypeto}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                className='textfield'

                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                </Box>
                                            </Box>
                                        </Box>

                                        <ReactEcharts option={complainsTypeOptions} className='echarts chart600' />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box className='whiteContainer h-100p' >
                                    <Box component='div' className='chart'>
                                        <Typography component='h3' variant='' className='chart-heading'>Complains</Typography>

                                        <Box className='flexContainer'>

                                            <Box className='calendarBox'>
                                                <Box className='flex-box alignItems_center mr-10'>
                                                    <Box style={{ width: '92%' }} className='label'>
                                                        Fetch From
                                                    </Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Box className='datepicker '>
                                                            <DesktopDatePicker
                                                                // label="From"
                                                                inputFormat="dd/MM/yyyy"
                                                                value={fromdateforcompany}
                                                                className='textfield'

                                                                onChange={handlecopmlainttypefrom}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                </Box>
                                                <Box className='flex-box alignItems_center  toText'>
                                                    <Box className='label'>
                                                        To
                                                    </Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} style={{ alignItems: 'flex-start', marginLeft: '20px' }}>
                                                        <Box className='datepicker '>

                                                            <DesktopDatePicker
                                                                // label="To"
                                                                inputFormat="dd/MM/yyyy"
                                                                value={todateforcompany}
                                                                onChange={handlecopmlainttypeto}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                className='textfield'

                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                </Box>
                                            </Box>
                                            <Box className='flex-box heading' >
                                                <Select style={{ maxHeight: '37px', width: '100%', height: '100%' }}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    className='select fz-14'
                                                    value={selectdayforcompany}
                                                    onChange={selectDayforcompanies}
                                                >
                                                    <MenuItem value={'all'}>All </MenuItem>
                                                    <MenuItem value={'week'}>Week</MenuItem>
                                                    <MenuItem value={'month'}>Month</MenuItem>

                                                    <MenuItem value={'year'}>Year</MenuItem>
                                                </Select>
                                            </Box>
                                        </Box>


                                        <ReactEcharts option={complainsChartOptions} className='echarts' />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={3} className='mt-20 dashboard_rows'>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box className='whiteContainer h-100p'>
                                    <Box component='div' className='chart'>
                                        <Typography component='h3' variant='' className='chart-heading'>Complains Resolved</Typography>
                                        <Box className='flexContainer'>

                                            <Box className='calendarBox'>
                                                <Box className='flex-box alignItems_center mr-10'>
                                                    <Box style={{ width: '92%' }} className='label'>
                                                        Fetch From
                                                    </Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <Box className='datepicker '>
                                                            <DesktopDatePicker
                                                                // label="From"
                                                                inputFormat="dd/MM/yyyy"
                                                                value={resolvedateFrom}
                                                                className='textfield'

                                                                onChange={handleresolveFromcomplain}
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                </Box>
                                                <Box className='flex-box alignItems_center  toText'>
                                                    <Box className='label'>
                                                        To
                                                    </Box>
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} style={{ alignItems: 'flex-start', marginLeft: '20px' }}>
                                                        <Box className='datepicker '>

                                                            <DesktopDatePicker
                                                                // label="To"
                                                                inputFormat="dd/MM/yyyy"
                                                                value={resolvedateTo}
                                                                onChange={handleresolveTocomplain}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                className='textfield'

                                                            />
                                                        </Box>
                                                    </LocalizationProvider>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <ReactEcharts option={complainsResolvedOptions} className='echarts' />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Box className='whiteContainer h-100p'>
                                    <Box component='div' className='chart'>
                                        <Typography component='h3' variant='' className='chart-heading'>Most Complain Companies</Typography>
                                        <ReactEcharts option={mostComplainsOptions} className='echarts chart600' />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box className='whiteContainer mt-20'>
                            <Box component='div' className='chart closingcomplains'>
                                <Typography component='h3' variant='' className='chart-heading'>Days to close complains</Typography>
                                <ReactEcharts option={closingComplainsOptions} className='echarts ' />
                            </Box>
                        </Box>

                    </Grid>
                    {auth && auth.role_id != 1 ? <>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Box className='whiteContainer'>
                                <Box component='div' className='activity'>
                                    <Typography component='h3' variant='' className='recent-activity-heading'>Recent Activity
                                    </Typography>
                                    <Box component='div' className='recent-activity-container'>
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/complains-vs-resolved.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc">Recent complain raised by <Typography component='span' className="by">John Smith</Typography></Typography>
                                                <Typography className="activity-time">Sep 03 2022, 12:23PM</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/complains-vs-resolved.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc">New complain against  <Typography component='span' className="by">John Doe</Typography></Typography>
                                                <Typography className="activity-time">Sep 03 2022, 8:23PM</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/complains-vs-resolved.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc">Recent complain raised by <Typography component='span' className="by">John Smith</Typography></Typography>
                                                <Typography className="activity-time">Sep 03 2022, 12:23PM</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/complains-vs-resolved.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc">Sent review on complain by  <Typography component='span' className="by">John Smith</Typography></Typography>
                                                <Typography className="activity-time">Sep 03 2022, 12:23PM</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/complains-vs-resolved.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc">Sent review on complain by against <Typography component='span' className="by">John Doe</Typography></Typography>
                                                <Typography className="activity-time">Sep 03 2022, 12:23PM</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/complains-vs-resolved.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc">Recent complain raised by <Typography component='span' className="by">John Smith</Typography></Typography>
                                                <Typography className="activity-time">Sep 03 2022, 12:23PM</Typography>
                                            </Box>
                                        </Box>
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/complains-vs-resolved.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc">Sent review on complain by against <Typography component='span' className="by">John Doe</Typography></Typography>
                                                <Typography className="activity-time">Sep 03 2022, 12:23PM</Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className='whiteContainer mt-20' >
                                <Box component='div' className='activity'>
                                    <Typography component='h3' variant='' className='recent-activity-heading'>Recent Complains
                                    </Typography>
                                    <Box component='div' className='recent-activity-container'>
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/verbal-complains.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc"> By <Typography component='span' className="by">John Smith</Typography>
                                                    <Typography component='span' className="activity-time"> Sep 03 2022, 12:23PM</Typography>
                                                </Typography>
                                                <Typography component='p' className="complain-shortdesc">My Manager has been very disrespectful </Typography>

                                            </Box>
                                        </Box>
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/complains-vs-resolved.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc"> By <Typography component='span' className="by">John Doe</Typography>
                                                    <Typography component='span' className="activity-time">  Sep 03 2022, 8:23PM</Typography>
                                                </Typography>
                                                <Typography component='p' className="complain-shortdesc">I do not like my Boss to call me for a discussion, he has a bad eye</Typography>

                                            </Box>
                                        </Box>
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/complains-vs-resolved.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc"> By <Typography component='span' className="by">John Smith</Typography>
                                                    <Typography component='span' className="activity-time"> Sep 03 2022, 12:23PM</Typography>
                                                </Typography>
                                                <Typography component='p' className="complain-shortdesc">I feel insecured here </Typography>

                                            </Box>
                                        </Box>
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/verbal-complains.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc"> By <Typography component='span' className="by">John Smith</Typography>
                                                    <Typography component='span' className="activity-time"> Sep 03 2022, 12:23PM</Typography>
                                                </Typography>
                                                <Typography component='p' className="complain-shortdesc">I hate my cab driver </Typography>

                                            </Box>
                                        </Box>
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/complains-vs-resolved.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc"> By <Typography component='span' className="by">John Smith</Typography>
                                                    <Typography component='span' className="activity-time"> Sep 03 2022, 12:23PM</Typography>
                                                </Typography>
                                                <Typography component='p' className="complain-shortdesc">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In non tristique leo. Ut tincidunt eleifend. </Typography>

                                            </Box>
                                        </Box>
                                        <Box className="activity-list-item">
                                            <img src={require('./../../../assets/images/verbal-complains.png')} className="" height="40" alt="" />
                                            <Box className="activity-list-content">
                                                <Typography className="activity-desc"> By <Typography component='span' className="by">John Smith</Typography>
                                                    <Typography component='span' className="activity-time"> Sep 03 2022, 12:23PM</Typography>
                                                </Typography>
                                                <Typography component='p' className="complain-shortdesc">My Manager has been very disrespectful </Typography>

                                            </Box>
                                        </Box>

                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </> : ''}
                </Grid>
            </Box>
        </Box>

    )
}