import React, { useState } from "react"
import { Lock, Person } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography,OutlinedInput,IconButton } from '@mui/material'
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useSnackbar } from "notistack"
import  queryString  from 'query-string'
import { useNavigate } from "react-router-dom";
import axios from 'axios'

export default function ResetPassword() {
    let navigate=useNavigate()

    const {enqueueSnackbar}=useSnackbar();
    const {email} = queryString.parse(window.location.search);
    const [data,setData]=useState({password:"",confpassword:''})
    const [error,setError]=useState({password:"",confpassword:''})
    let name,value;

const [values, setValues] = React.useState({
    showPassword: false,
    showRePassword: false
  });
const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowRePassword = () => {
    setValues({
      ...values,
      showRePassword: !values.showRePassword,
    });
  };
    // e.currentTarget.classList.remove("footerDiv");
    const handleChange=(e)=>{
        name=e.target.name;
        value=e.target.value;
        setData({...data,[name]:value})
        setError({...error,[name]:""})
    }
    
    const validator=()=>{
        if(!data.password &&!data.confpassword ){
            setError({...error,password:"Password cannot be empty",confpassword:"Confirm password cannot be empty"})
         
            return false
        }
        else if(!data.password){
            setError({...error,password:"Password cannot be empty"})
         
            return false
        }
        else if(!data.confpassword){
            setError({...error,confpassword:"Confirm password cannot be empty"})
           
            return false
        }
        else  if(data.password!==data.confpassword){
            setError({...error,confpassword:"Password mismatch"})
          
            return false
        }
        return true
    }
    const handleSubmit=(e)=>{
        e.preventDefault();
        var validate = validator();
        if (validate) {
            var body = {
                password: data.password,
                email: email
            }
            axios.put(process.env.REACT_APP_BASE_URL + "companies/newPassword", body).then((res) => {
             console.log(res.data.setNewPassword);
                if(res.data.status==1){
                    navigate("/login",{state:res.data.setNewPassword})
                    enqueueSnackbar("Password has been set successfully",{variant:"success"})
                    setData()
                }                
            }).catch((err) => console.log(err))
        }
    }
    return (
        <Box className='login'>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='login_img'>
                    <Box className='logintext_box'>
                            <h1 className='heading'>Incidents are facts</h1>
                            <Typography component='p' variant='body1' className='subheading'>All incidents should be treated as opportunities to learn and improve, Here is the ledger to collect the facts.</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='Login_content'>
                        <Typography component='h4' variant='h4' className='mb-10'>Reset Password</Typography>
                        <Typography component='p' variant='p' className='mb-30 fz-14'>Enter your new password to reset.</Typography>
                        <Box className='form'>
                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Password</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    className='textfield'
                                    placeholder='Password'
                                    name="password"                                   
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Lock className='start_icon' /></InputAdornment>,
                                    }}
                                    type="password"
                                />
                                   <p className='error-field'>{error.password}</p>
                            </FormControl>
                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Confirm Password</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    type={values.showRePassword ? 'text' : 'password'}
                                    className='textfield'
                                    name="confpassword"
                                    onChange={handleChange}
                                    placeholder='Confirm Password'
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Lock className='start_icon' /></InputAdornment>,
                                         endAdornment:
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowRePassword}
                                            edge="end">
                                            {values.showRePassword ? <VisibilityOff /> : <Visibility />}
                                          </IconButton>
                                        </InputAdornment>                                     
                                          }}
                                   

                                />
                                 <p className='error-field'>{error.confpassword}</p>

                            </FormControl>
                        </Box>
                        <Button className='loginbtn' onClick={handleSubmit}  >Reset Password</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}
