import React, { useState } from "react"
import { Person } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import axios from "axios";
 import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom';



export default function SuperdminForgotPassword() {
    let navigate = useNavigate();

    const ResetPassword = () => {        
        navigate("/reset-password");
    }

	const [logindata,setLogindata]=useState({username:""})
	const [error,setError]=useState({username:""})
	 const {enqueueSnackbar}=useSnackbar()
	
	let name,value;
	const handleChange=(e)=>{
		name=e.target.name;
		value=e.target.value;
		setLogindata({...logindata,[name]:value})
		setError({...error,[name]:""})
	}
	const validator=()=>{
		if(!logindata.username){
			setError({username:"Email cannot be empty"});
			//  enqueueSnackbar("Email cannot be empty",{variant:"error"})
			return false
		}
		return true
	}

	const handleLogin=(e)=>{
		e.preventDefault();
		var validate=validator();
		if(validate){
			var body={
				email:logindata.username,
			}
			axios.post(process.env.REACT_APP_BASE_URL+"companies/forgotpasswordforSuperadmin",body).then((res)=>{
                if(res.data.status==1){
                   enqueueSnackbar("Password reset link has been sent to registered email address",{variant:"success"})
                    navigate("/superadminlogin")
                }
                else{
                     enqueueSnackbar(`${res.data.message}`,{variant:"error"})
                     setError({username:`${res.data.message}`});
                }          
			}).catch((err)=>console.log(err))
		}
	}

    return (
        <Box className='login'>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='login_img'>
                    <Box className='logintext_box'>
                            <h1 className='heading'>Incidents are facts</h1>
                            <Typography component='p' variant='body1' className='subheading'>All incidents should be treated as opportunities to learn and improve, Here is the ledger to collect the facts.</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='Login_content'>
                        <Typography component='h4' variant='h4' className='mb-10'>Forgot Password</Typography>
                        <Typography component='p' variant='p' className='mb-30 fz-14'>Enter your email to reset the password.</Typography>
                        <Box className='form'>
                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Email</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    className='textfield'
                                    placeholder='Email'
                                    name="username"
								    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Person className='start_icon' /></InputAdornment>,
                                    }}
                                />
                                <p className='error-field'>{error.username}</p>
                            </FormControl>
                        </Box>
                        <Button className='loginbtn' onClick={ handleLogin}>Submit</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}
