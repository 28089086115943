import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { Button, Tooltip, ClickAwayListener, FormControl, Grid, Select, FormGroup, Grow, InputAdornment, MenuItem, MenuList, Popper, TextField, FormControlLabel } from '@mui/material';
import { ArrowDropDown, BorderColorOutlined, DeleteOutlineRounded, PlaylistAddRounded, RepeatRounded, HighlightOffRounded, SearchOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { HexColorPicker } from 'react-colorful';
import useClickOutside from '../useClickOutside';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import { logDOM } from '@testing-library/react';
import { useSnackbar } from 'notistack'
import { header, headerformdata } from "../../../../components/axiousheader"

function createData(ComplainType, Color, Action) {
    return {
        ComplainType,
        Color,
        Action,
    };
}

const rows = [
    createData('Disrespectful',),
    createData('Harassment',),
    createData('Others',),
];

// ----modal-----
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



export default function DataTable() {
    const useStyles = styled(theme => ({
        arrow: {
            "&:before": {
                border: "1px solid #E6E8ED"
            },
            color: '#4A4A4A',
            curser: "pointer"
        },
        tooltip: {
            backgroundColor: "#4A4A4A",
            border: "1px solid #E6E8ED",
            color: "#4A4A4A"
        }
    }));
    let classes = useStyles();
    const auth = JSON.parse(localStorage.getItem("userData"))
    const { enqueueSnackbar } = useSnackbar()

    const [page, setPage] = React.useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [editModal, seteditModal] = React.useState(false);

    const popover = React.useRef();
    const [isOpen, toggle] = React.useState(false);
    const [count, setCount] = React.useState(0)
    const [addModal, setaddModal] = React.useState(false);

    const close = React.useCallback(() => toggle(false), []);
    useClickOutside(popover, close);
    const [editdata, setEditdada] = React.useState({})
    const [complain, setComplain] = React.useState({ status: "", type: "" })
    const OpenaddModal = () => {
        setaddModal(true);
    };

    const [error, setError] = React.useState({ complain: "" })
    const validate = () => {
        if (!complain.status) {
            setError({ ...error, complain: "Please add industry" })
            return false
        }
        return true

    }



    const submitComplain = () => {
        var valid = validate()
        if (valid == true) {
            const body = {
                description: complain.type,
                industry_type: complain.status,
                role: 'superadmin',
                corporateid: auth.company_id,
                role_id: 1
            }
            axios.post(process.env.REACT_APP_BASE_URL + 'industry/AddIndustry', body, { headers: header.Authorization = localStorage.getItem("token") }).then((res) => {
                getComplains()
                setaddModal(false);
                enqueueSnackbar("Industry type added successfully", { variant: 'success' })
            }).catch((err) => {
                console.log(err)
                enqueueSnackbar(err.response.data.message, { variant: 'error' })
            })
        }
    }
    const CloseaddModal = () => {
        setaddModal(false);
    };

    const OpeneditModal = (data) => {
        setEditdada(data)
        seteditModal(true);
    };
    const CloseeditModal = () => {
        seteditModal(false);
        setDeletemodel(false)
    };

    const [datacom, setData] = React.useState([])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [sortorder, setSortorder] = React.useState({ type: -1, column: 'createdAt' })
    const [search, setSearch] = React.useState('')
    React.useEffect(() => {
        getComplains()
    }, [search, page, rowsPerPage, sortorder.type, sortorder.column])

    const getSortOrder = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `order/GetOrder/${auth.company_id}`,).then((res) => {
            setSortorder({ type: res.data.order.industry_order, column: res.data.order.industry_name })
      
        }).catch((err) => console.log(err))
    }

    const getComplains = () => {

        axios.get(process.env.REACT_APP_BASE_URL + `industry/GetIndustry?page=${page}&size=${rowsPerPage}&search=${search}&sortColumn=${sortorder.column}&sortType=${sortorder.type}`).then((res) => {

            console.log(res.data, 'res.datares.data');
            setData(res.data.industry)
            setCount(res.data.totalcount)
            var value = { val: false }
            res.data.industry && res.data.industry.map((item, i) => {
                Object.assign(item, value)
            })
            setData(res.data.industry)
        }).catch((err) => console.log(err))
    }





    const [editcomplain, setEditcomlain] = React.useState({ status: "", type: "" })
    const [deletemodel, setDeletemodel] = React.useState(false)
    const [deleteid, setDeleteid] = React.useState(false)


    const submitEditdata = () => {
        var body = {
            industry_type: editcomplain && editcomplain.status ? editcomplain.status : editdata.industry_type,
            description: editcomplain && editcomplain.type ? editcomplain.type : editdata.description,
            role: 'superadmin',
            role_id: 1
        }
        axios.put(process.env.REACT_APP_BASE_URL + `industry/EditIndustry/${editdata._id}`, body, { headers: header.Authorization = localStorage.getItem("token") }).then((res) => {
            seteditModal(false)
            getComplains()
            enqueueSnackbar("Industry type updated successfully", { variant: "success" })

        }).catch((err) => console.log(err))
    }

    const handleDeleteData = (id) => {
        setDeletemodel(true)
        setDeleteid(id)
    }

    const DeleteSubmit = () => {
        axios.put(process.env.REACT_APP_BASE_URL + `industry/DeleteIndustry/${deleteid}`, { headers: header.Authorization = localStorage.getItem("token") }).then((res) => {
            setDeletemodel(false)
            getComplains()
            enqueueSnackbar("Industry type deleted successfully", { variant: "success" })
        }).catch((err) => console.log(err))
    }

    const [deleteid1, setDeleteid1] = React.useState([])
    const [deletearry, setDeleteArray] = React.useState([])
    const [isCheckAll, setIsCheckAll] = React.useState(false);
    const [state, setStete] = React.useState(false)
    const CheckChange = (e, id, index) => {
        var newarray = [];
        var newdata = []
        setStete(true)
        if (e.target.checked == true) {
            datacom && datacom.map((item, i) => {
                if (i == index) {
                    item.val = e.target.checked
                }
                if (item.corporateid !== auth.company_id) {
                    console.log(datacom.length);
                    newdata.push(item.corporateid)
                    // if (datacom.length == deleteid1.length + 1) {
                    //     setIsCheckAll(true)
                    //    }

                }
                if (i > newdata.length) {
                    newarray.push(item.val)
                }
                if (newarray.includes(false)) {
                    setIsCheckAll(false)
                } else {
                    setIsCheckAll(true)
                }
            })
            setStete(true)
            setDeleteid1(pre => pre.concat({ id: id, }))
            deletearry.push(id)

        }
        else {
            datacom && datacom.map((item, i) => {
                if (i == index) {
                    item.val = e.target.checked
                }
            })
            var data = deleteid1.filter(item1 => item1.id != id)
            var data1 = deletearry.filter((ids) => ids != id)
            setDeleteid1(data)
            setDeleteArray(data1)
            setIsCheckAll(false)
        }
    }
    React.useEffect(() => {
        if (state == true) {
            if (datacom.length === deleteid1.length) {
                setIsCheckAll(true)
            } else {
                setIsCheckAll(false)
            }
        }
    })


    const checkAll = (e) => {
        setIsCheckAll(!isCheckAll)
        setStete(false)
        var all = []
        // if (e.target.checked == true) {
        //     datacom && datacom.map((item, i) => {
        //         item.val = e.target.checked
        //         if (item.corporateid==auth.company_id) {
        //             setDeleteid1(pre => pre.concat({ id: item._id, }))
        //             deletearry.push(item._id)
        //         }
        //     })

        // }
        // else {
        //     datacom && datacom.map((item, i) => {
        //         item.val = e.target.checked
        //     })
        //     setDeleteid1([])
        //     setDeleteArray([])

        // }
        if (e.target.checked == true) {
            // setIsCheckAll(true)
            datacom && datacom.map((item, i) => {
                item.val = e.target.checked
                setDeleteid1(pre => pre.concat({ id: item._id, }))
                deletearry.push(item._id)
                // if (item.corporateid==auth.company_id) {
                //     setDeleteid1(pre => pre.concat({ id: item._id, }))
                //     deletearry.push(item._id)
                // }
            })

        }
        else {
            // setIsCheckAll(false)
            datacom && datacom.map((item, i) => {
                item.val = e.target.checked
            })
            setDeleteid1([])
            setDeleteArray([])

        }
    }
    console.log(isCheckAll, "hhh");
    const [bulkmodel, setBulkmodel] = React.useState(false)
    const bulkDelete = () => {
        setBulkmodel(true)
    }

    const handlebulkDelete = () => {
        var body = {
            ids: deletearry
        }
        axios.post(process.env.REACT_APP_BASE_URL + `industry/multipleDelete`, body, { headers: header.Authorization = localStorage.getItem("token") }).then((res) => {
            setBulkmodel(false)
            setIsCheckAll(false)
            getComplains()
            enqueueSnackbar("Selected industry types deleted successfully", { variant: "success" })
            setDeleteArray([])
            setDeleteid1([])
        }).catch((err) => console.log(err))
    }


    const handleSavedOrder = () => {
        var body = {
            industry_order: -1,
            industry_name: 'createdAt',
            company_id: auth.company_id
        }
        axios.post(process.env.REACT_APP_BASE_URL + `order/addOrder`, body, { headers: header.Authorization = localStorage.getItem("token") }).then((res) => {
            console.log(res.data);
            getSortOrder()
        }).catch((err) => console.log(err))
    }

    const handleAlphabeticalOrder = () => {
        var body = {
            industry_order: 1,
            industry_name: 'industry_type',
            company_id: auth.company_id
        }
        axios.post(process.env.REACT_APP_BASE_URL + `order/addOrder`, body, { headers: header.Authorization = localStorage.getItem("token") }).then((res) => {
            console.log(res.data);
            getSortOrder()
        }).catch((err) => console.log(err))
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Box className='headingBox'>
                <Typography component='h4' variant='h4' className='Submenu-page-heading'>Industry Type</Typography>
                <Box className='heading-btns'>
                    {sortorder && sortorder.type == -1 ? <Button variant="contained" className='addbtn mr-10 mb-10' id='disablebtn' onClick={handleSavedOrder} disabled>Saved Order</Button> : <Button variant="contained" className='addbtn mr-10 mb-10' onClick={handleSavedOrder}>Saved Order</Button>}
                    {sortorder && sortorder.type == 1 ? <Button variant="contained" className='addbtn mr-10 mb-10' id='disablebtn' onClick={handleAlphabeticalOrder} disabled> Save in Alphabetical Order</Button> : <Button variant="contained" className='addbtn mr-10 mb-10' onClick={handleAlphabeticalOrder}> Save in Alphabetical Order</Button>}
                    {deleteid1 && deleteid1.length > 0 ? <Button variant="contained" className='mb-10' style={{
                         background: "#f64e60",  boxShadow: 'none',
                        height: 34,
                        textTransform: 'capitalize'
                    }} onClick={bulkDelete} >Delete</Button> : ""}
                </Box>
            </Box>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='settings-Datatable'>
                <Toolbar className='toolbar'>
                    <Button variant="text" className='addbtn-modal' onClick={OpenaddModal}>
                        <PlaylistAddRounded className='mr-10 fz-18' /> Add Industry Type
                    </Button>
                    <Box component='div' className='DTsearch'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10' >Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                className='textfield search '
                                placeholder='Search Here...'
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                                }}
                            />
                        </FormControl>

                    </Box>
                </Toolbar>


                <TableContainer component={Paper} >
                    <Table aria-label="customized table" className='table'>
                        <TableHead className='table-head'>
                            <TableRow className='table-row'>
                                <StyledTableCell align="center" width={150} className='checkbox'><FormControlLabel control={<Checkbox onChange={checkAll} checked={isCheckAll} />} /></StyledTableCell>
                                <StyledTableCell align="left" sx={{ width: 200, minWidth: 200 }} className='semibold'><strong>Industry type</strong></StyledTableCell>
                                <StyledTableCell align="left" className='semibold'><strong>Description</strong></StyledTableCell>
                                <StyledTableCell align="center" className='semibold'><strong>Actions</strong></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {datacom && datacom.length > 0 ? datacom.map((item, i) => (
                                <StyledTableRow className='tbody-row' key={i} >
                                    <StyledTableCell align="center" width={50} className='checkbox'>
                                        <FormGroup>
                                            <FormControlLabel style={{ justifyContent: "center" }} control={<Checkbox onChange={(e) => CheckChange(e, item._id, i)} checked={item && item.val} size='medium' />} />
                                        </FormGroup>
                                    </StyledTableCell>

                                    <StyledTableCell className='DT-row' align="left" >
                                        <Typography className='fz-14'>{item.industry_type}</Typography>
                                    </StyledTableCell>

                                    <StyledTableCell className='DT-row' align="left" >
                                        <Typography className='fz-14'>{item.description}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell className='DT-row' align="center" >
                                        <Box className='actionbtns'>
                                            <Tooltip title="Update" arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}><BorderColorOutlined style={{ cursor: 'pointer' }} className='edit-icon mr-10' onClick={() => OpeneditModal(item)} /></Tooltip>
                                            <Tooltip title="Delete" arrow classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}><DeleteOutlineRounded style={{ cursor: 'pointer' }} className='delete-icon' onClick={() => handleDeleteData(item._id)} /></Tooltip>
                                        </Box>
                                    </StyledTableCell>

                                </StyledTableRow>
                            )) : <StyledTableCell colSpan={5} className='nodata' > NO DATA FOUND </StyledTableCell>}

                        </TableBody>
                    </Table>
                    {/* ADD COMPLAINT TYPE */}
                    <BootstrapDialog
                        onClose={CloseaddModal}
                        aria-labelledby="customized-dialog-title"
                        open={addModal}
                        className='addForm-modal'
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={CloseaddModal} className='modal-title'>
                            Add Industry Type
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                            <Grid container columnSpacing={2} rowSpacing={2}>
                                <Grid item xs={12} sm={12} md={4} >
                                    <Typography component='div' className='label'>Industry type </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            size="small"
                                            className='textfield'
                                            placeholder='Industry type'
                                            onChange={(e) => setComplain({ ...complain, status: e.target.value })}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            }}
                                        />
                                        {error.complain && <p className='error-field'>{error.complain}</p>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Typography component='div' className='label'>Description</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            size="small"
                                            className='textfield'
                                            placeholder='Description'
                                            onChange={(e) => setComplain({ ...complain, type: e.target.value })}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={submitComplain} className='modalbtn'>
                                Create
                            </Button>
                        </DialogActions>

                    </BootstrapDialog>
                    <BootstrapDialog
                        onClose={CloseeditModal}
                        aria-labelledby="customized-dialog-title"
                        open={editModal}
                        className='addForm-modal'
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" onClose={CloseeditModal} className='modal-title'>
                            Change Status Type
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                            <Grid container columnSpacing={2} rowSpacing={2}>
                                <Grid item xs={12} sm={12} md={4} >
                                    <Typography component='div' className='label'>Industry Type</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            size="small"
                                            className='textfield'
                                            placeholder='Ex. Abusive'

                                            onChange={(e) => setEditcomlain({ ...editcomplain, status: e.target.value })}
                                            defaultValue={editdata.industry_type}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Typography component='div' className='label'>Description</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            size="small"
                                            className='textfield'
                                            placeholder='Ex. Abusive'

                                            onChange={(e) => setEditcomlain({ ...editcomplain, type: e.target.value })}
                                            defaultValue={editdata.description}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"></InputAdornment>,
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>


                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={submitEditdata} className='modalbtn'>
                                <RepeatRounded className='fz-18' style={{ marginRight: 6 }} />Update
                            </Button>
                        </DialogActions>
                    </BootstrapDialog>

                    <BootstrapDialog
                        onClose={CloseeditModal}
                        aria-labelledby="customized-dialog-title"
                        open={deletemodel}
                        className='addForm-modal'
                    >
                        <DialogContent className='deleteConfirmModal'>
                            <HighlightOffRounded className='delIcon' />

                            <Typography component='div' className='text'>Are you sure want to delete?</Typography>

                        </DialogContent>
                        <DialogActions className='deleteModalactbtns'>
                            <Button autoFocus onClick={() => setDeletemodel(false)} className='modalbtn cancel'>
                                Cancel</Button>
                            <Button autoFocus onClick={DeleteSubmit} className='modalbtn delete'>
                                Delete</Button>
                        </DialogActions>
                    </BootstrapDialog>

                    {/* BULK DELETE */}
                    <BootstrapDialog
                        onClose={CloseeditModal}
                        aria-labelledby="customized-dialog-title"
                        open={bulkmodel}
                        className='addForm-modal'
                    >
                        {/* <BootstrapDialogTitle id="customized-dialog-title" onClose={CloseeditModal} className='modal-title'> Delete</BootstrapDialogTitle> */}
                        <DialogContent className='deleteConfirmModal'>
                            <HighlightOffRounded className='delIcon' />

                            <Typography component='div' className='text'>Are you sure want to delete selected Industry Type?</Typography>

                        </DialogContent>
                        <DialogActions className='deleteModalactbtns'>
                            <Button autoFocus onClick={() => setBulkmodel(false)} className='modalbtn cancel'>
                                Cancel</Button>
                            <Button autoFocus onClick={handlebulkDelete} className='modalbtn delete'>
                                Delete</Button>
                        </DialogActions>
                    </BootstrapDialog>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className='DT-pagination'
                />
            </Paper>

        </Box>
    );
}