import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { PlaylistAddRounded } from '@mui/icons-material'
import Box from '@mui/material/Box';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Button,ClickAwayListener, FormControl, Grow, Grid, InputAdornment, MenuItem, MenuList,Select, Avatar, RadioGroup, Radio, Input, TextareaAutosize, TextField,Tooltip } from '@mui/material';
import { ArrowDropDown, DeleteOutlined, AddAPhoto, SearchOutlined, Check, DeleteOutlineRounded, BorderColorOutlined, HighlightOffRounded } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import moment from 'moment';
import MiniDrawer from '../../../../components/drawer'
import { useSnackbar } from 'notistack'
import Avatarimage from "../../../../assets/images/Avatar.png"
import Spinner from '../../../../components/spinner'
import { header, headerformdata } from "../../../../components/axiousheader"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

// ----modal-----
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    {/* <CloseIcon className='fz-18' /> */}
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));
const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#091865',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

export default function Users() {
    const useStyles = styled(theme => ({
        arrow: {
          "&:before": {
            border: "1px solid #E6E8ED"
          },
          color: '#4A4A4A',
          curser:"pointer"
        },
        tooltip: {
          backgroundColor:  "#4A4A4A",
          border: "1px solid #E6E8ED",
          color: "#4A4A4A"
        }
      }));
      let classes = useStyles();
    const auth = JSON.parse(localStorage.getItem("userData"))
    const Permission = JSON.parse(localStorage.getItem("Permissions"))
    const { enqueueSnackbar } = useSnackbar()
    let location = useLocation()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [addUserModal, setAddUserModal] = React.useState(false)  
    const [roleDetails, setRoleDetails] = React.useState([])
    const [count, setCount] = React.useState(0)
    const [search, setSearch] = React.useState('')
    const [roleOrder, setRoleOrder] = React.useState({ type: -1, column: 'createdAt' })
    const [role, setRole] = React.useState('')
    const [selectedRole, setSelectedRole] = React.useState('User')

    const handleChangeRoles = (e) => {
        setSelectedRole(e.target.value)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [data, setData] = React.useState([])
    React.useEffect(() => {
        getCompanydata()
        getRoles()
        getSortOrder()
    }, [search, page, rowsPerPage, roleOrder.type])
    // console.log(auth,'authauth');
    const getCompanydata = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `companies/Userdata?company_id=${auth && auth.company_id}&page=${page}
        &size=${rowsPerPage}&search=${search}`, ).then((res) => {
            console.log(res.data.list[0].records, 'userdatauserpage');
            setData(res.data.list[0].records)
            setCount(res.data.list[0].total)
        }).catch((err) => console.log(err))
    }


      // const getCompanydata = () => {
    //     axios.get(process.env.REACT_APP_BASE_URL + `companies/Userdata?company_id=${locationdata && locationdata._id}&search=${searchTerm}&size=${rowsPerPage}&page=${page}`).then((res) => {
    //         // console.log(res.data.list[0].records,'res.data.list[0].recordsres.data.list[0].records');
    //         setData(res.data.list[0].records)
    //         setCount(res.data.list[0].total)
    //     }).catch((err) => console.log(err))
    // }

    const handleadduser = () => {
        setAddUserModal(true)
    }
   

    const [user, setUser] = React.useState({ firstname: '', lastname: '', email: '', role: '', gender: 'Male', jobtitle: '', phone: '', Imageprew: "",EmergencyEmail:"", Imageraw: "" });
    const[EnableEmergency,setEnableEmergency]=React.useState(false)
    const [error, setError] = React.useState({ firstname: "", email: "" })
    let username, uservalue;
    const handleChange = (e) => {
        username = e.target.name;
        uservalue = e.target.value;
        setUser({ ...user, [username]: uservalue })
        setError({ ...error, [username]: '' })
    };

    const handleaddphoto = (e) => {
        setUser({ ...user, Imageprew: URL.createObjectURL(e.target.files[0]), Imageraw: e.target.files[0] })
    }
    const validation = () => {
        if (!user.firstname) {
            setError({ firstname: "First name can not be empty" })
            return false
        }
        else if (!user.email) {
            setError({ email: "Email cannot be empty" })
            return false
        }
        else if (user.email) {
            const pattern = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")
            var valid = pattern.test(user.email)
            if (!valid) {
                setError({ email: "Please enter valid Email" })
                return false
            }
        }
        return true
    }

    const handleClosemodal = () => {
        setAddUserModal(false)
        
    }
              

    const addUserDetails = () => {
        console.log(role,"role00000000000000000");
        const validator = validation()
        if (validator == true) {
            const formData = new FormData();
            console.log(role.user_role,"eeeeeeeeeeeeeeee");
            if(selectedRole == 'User'){                
                formData.append("Image", user.Imageraw ? user.Imageraw : "");
                formData.append("firstname", user.firstname);
                formData.append("lastname", user.lastname);
                formData.append("email", user.email);
                formData.append("jobtitle", user.jobtitle);
                formData.append("phone", user.phone);
                formData.append("role", role.user_role ? role.user_role : "-");
             
                formData.append("viewcomplaint", role.receive_complaint ? role.receive_complaint : false);


                formData.append("UserRole_id",role._id ? role._id:"" );
        
         

                formData.append("gender", user.gender);
                formData.append("userrole", 'User');
                formData.append("role_id", 3);
                formData.append("company_id", auth && auth.company_id);
                formData.append("companyname", auth && auth.companyname);
                formData.append("superAdminId", auth && auth.superAdminId);
                formData.append("EmergencyEmail", user.EmergencyEmail);
            }

            if(selectedRole == 'Main Admin'){                
                formData.append("Image", user.Imageraw ? user.Imageraw : "");
                formData.append("firstname", user.firstname);
                formData.append("lastname", user.lastname);
                formData.append("email", user.email);
                formData.append("jobtitle", user.jobtitle);
                formData.append("phone", user.phone);
                formData.append("role", 'Main Admin');
                formData.append("viewcomplaint", true);
                formData.append("gender", user.gender);
                formData.append("userrole", 'AdminUser');
                formData.append("role_id", 2);
                formData.append("company_id", auth && auth.company_id);
                formData.append("companyname", auth && auth.companyname);
                formData.append("superAdminId", auth && auth.superAdminId);
                formData.append("UserRole_id","656193b01840b3f20cbf8444");
            
              formData.append("EmergencyEmail", user.EmergencyEmail);
              
            } 
            for (const value of formData.values()) {
                console.log(value);
            }
            console.log(formData,"formData");
            axios.post(process.env.REACT_APP_BASE_URL + `companies/addUser`, formData, { headers: headerformdata.Authorization=localStorage.getItem("token") }).then((res) => {
                setUser({ firstname: '', lastname: '', email: '', role: '', gender: 'Male', jobtitle: '', phone: '', Imageprew: "", Imageraw: "" });
                enqueueSnackbar(`${selectedRole} added successfully`, { variant: 'success' })
                getCompanydata()
            }).catch((err) => {
                console.log(err)
                enqueueSnackbar(err&&err.response&&err.response.data.message?err.response.data.message:"Somthing went wront", { variant: 'error' })

            })
        }
    }

    const saveAdduser = () => {
        console.log(role.user_role,"000000000000000000000000000000");
        const validator = validation()
        if (validator == true) {
            const formData = new FormData();           
            if(selectedRole == 'User'){                
                formData.append("Image", user.Imageraw ? user.Imageraw : "");
                formData.append("firstname", user.firstname);
                formData.append("lastname", user.lastname);
                formData.append("email", user.email);
                formData.append("jobtitle", user.jobtitle);
                formData.append("phone", user.phone);
                formData.append("role", role.user_role ? role.user_role : "-");
                formData.append("viewcomplaint",role.receive_complaint ? role.receive_complaint : false);
                formData.append("gender", user.gender);
                formData.append("userrole", 'User');
                formData.append("role_id", 3);
                formData.append("company_id", auth && auth.company_id);
                formData.append("companyname", auth && auth.companyname);
                formData.append("superAdminId", auth && auth.superAdminId);
                formData.append("EmergencyEmail", user.EmergencyEmail);


                // formData.append("addComplaints", role.Complaints.addComplaints != undefined ? role.Complaints.addComplaints: false);
                // formData.append("viewComplaintsOne", role.Complaints.viewComplaints != undefined ?role.Complaints.viewComplaints :false );
                // formData.append("deleteComplaints", role.Complaints.deleteComplaints != undefined ? role.Complaints.deleteComplaints :false);
                // formData.append("addUserMangement", role.UserMangement.addUserMangement  != undefined ? role.UserMangement.addUserMangement :false );
                // formData.append("viewUserMangement", role.UserMangement.viewUserMangement != undefined ?  role.UserMangement.viewUserMangement :false  );
                // formData.append("deleteUserMangement", role.UserMangement.deleteUserMangement  != undefined ? role.UserMangement.deleteUserMangement :false  );
                // formData.append("ViewNotification", role.ViewNotification  != undefined ? role.ViewNotification:false );

                // formData.append("Dashboard", role.Dashboard  != undefined ? role.Dashboard :false  );
                // formData.append("Settings", role.Settings  != undefined ? role.Settings:false );

                formData.append("UserRole_id",role._id ? role._id:"" );
               



            }
            if(selectedRole == 'Main Admin'){                
                formData.append("Image", user.Imageraw ? user.Imageraw : "");
                formData.append("firstname", user.firstname);
                formData.append("lastname", user.lastname);
                formData.append("email", user.email);
                formData.append("jobtitle", user.jobtitle);
                formData.append("phone", user.phone);
                formData.append("role", 'Main Admin');
                formData.append("viewcomplaint", true);
                formData.append("gender", user.gender);
                formData.append("userrole", 'AdminUser');
                formData.append("role_id", 2);
                formData.append("company_id", auth && auth.company_id);
                formData.append("companyname", auth && auth.companyname);
                formData.append("superAdminId", auth && auth.superAdminId);
                // formData.append("addComplaints",true);
                // formData.append("viewComplaintsOne",true);
                // formData.append("deleteComplaints",true);
                // formData.append("addUserMangement",true);
                // formData.append("viewUserMangement",true);
                // formData.append("deleteUserMangement",true);
                // formData.append("ViewNotification",true);
                // formData.append("Dashboard",true),
                // formData.append("Settings",true)

                formData.append("UserRole_id","656193b01840b3f20cbf8444");
                formData.append("EmergencyEmail", user.EmergencyEmail);

               
            } 
            for (const value of formData.values()) {
                console.log(value);
            }
            axios.post(process.env.REACT_APP_BASE_URL + `companies/addUser`, formData, { headers: headerformdata.Authorization=localStorage.getItem("token") }).then((res) => {
                setUser({ firstname: '', lastname: '', email: '', role: '', gender: 'Male', jobtitle: '', phone: '', Imageprew: "", Imageraw: "" });
                enqueueSnackbar(`${selectedRole} added successfully`, { variant: 'success' })
                getCompanydata()
                setAddUserModal(false)
            }).catch((err) => {
                console.log(err)
                enqueueSnackbar(err&&err.response&&err.response.data.message?err.response.data.message:"Somthing went wront", { variant: 'error' })

            })
        }
    }

    const handleclosemodel = () => {
        setAddUserModal(false)
        setUser({ firstname: '', lastname: '', email: '', role: '', jobtitle: '', phone: '' });
        setError({ firstname: "", email: "" })
        setEditmodel(false)
        setEditData({ firstname: '', lastname: '', email: '', role: '', gender: 'Male', jobtitle: '', phone: '', image: "", Imageprew: "", Imageraw: "" })       
    }

    const body = auth && auth.role_id == 1 ? [auth.company_id] : [auth.superAdminId, auth.company_id]
 
 
    const getRoles = () => {
        axios.post(process.env.REACT_APP_BASE_URL + `userrole/rolesdropdown?sortColumn=${roleOrder.column}&sortType=${roleOrder.type}`, body, ).then((res) => {
            console.log(res.data, 'rolesdataaaaa');
            let abc = res.data.userrole.filter(item => item._id != '6593da44f681835b44699c1b')
                
            setRoleDetails(abc)
            // setRoleDetails(res.data.userrole)
            // if(auth.role_id != 1) {
            //     let abc = res.data.userrole.filter(item => item._id != '6593da44f681835b44699c1b')
                
            //     setRoleDetails(abc)
        
            // } else {
            //     setRoleDetails(res.data.userrole)
           
            // }
        }).catch((err) => console.log(err))
    }
    // console.log(roleDetails,'roleDetailsroleDetails');
    const [editmodel, setEditmodel] = React.useState(false)
    const [editdata, setEditData] = React.useState({ id: "", firstname: '', lastname: '', email: '', role: '', gender: 'Male', jobtitle: '', phone: '', image: "", Imageprew: "", Imageraw: "", userrole:"",EmergencyEmail:""})
    const OpeneditModal = (data) => {
        console.log(data,"datadatadatadata");
        roleDetails.map((item) => {
            if(data.role == item.user_role) {
                console.log('inside if')
                setEditRole({ userRole: data.role, receive_complaint: item.receive_complaint })
            }
        })
        console.log(data,"data");
        setEditmodel(true)
        // setEditRole({ ...editRole,userRole: data.role })
        setEditData({ id: data._id, firstname: data.firstname, lastname: data.lastname, email: data.email, role: data.role, gender: data.gender, jobtitle: data.jobtitle, phone: data.phone, image: data.Image, userrole:data.userrole , EmergencyEmail:data.EmergencyEmail})
    }

    const [profileedit, setProfileedit] = React.useState({
        firstname: "", lastname: "", username: '', phone: "", address: "", city: "", zip: "", state: "", Imagepreview: "", Imageraw: ""
    })

    const handleprofilephoto = (e) => {
        setEditData({ ...editdata, Imageprew: URL.createObjectURL(e.target.files[0]), Imageraw: e.target.files[0] })
    }
    const [deletemodel, setDeletemodel] = React.useState(false)
    const [deleteid, setDeleteid] = React.useState(false)

    const handleEditchange = (e) => {
        setEditData({ ...editdata, [e.target.name]: e.target.value })
    }

    const submitEdit = () => {
        const formData = new FormData();
        // formData.append("Image", editdata.Imageraw ? editdata.Imageraw : "");
        // formData.append("firstname", editdata.firstname);
        // formData.append("lastname", editdata.lastname);
        // formData.append("jobtitle", editdata.jobtitle);
        // formData.append("phone", editdata.phone);
        // formData.append("role", editRole.userRole !== '' ? editRole.userRole : editdata.role);
        // formData.append("viewcomplaint", editRole.userRole !== '' && editRole.receive_complaint);
        // formData.append("gender", editdata.gender);
        
        if (editdata.userrole == 'Main Admin') {
            formData.append("Image", editdata.Imageraw ? editdata.Imageraw : "");
            formData.append("firstname", editdata.firstname);
            formData.append("lastname", editdata.lastname);
            formData.append("jobtitle", editdata.jobtitle);
            formData.append("phone", editdata.phone);
            formData.append("role", 'Main Admin');
            formData.append("viewcomplaint", true);
            formData.append("gender", editdata.gender);
            formData.append("EmergencyEmail", editdata.EmergencyEmail);
        }
        if ( editdata.userrole == "AdminUser") {
            formData.append("Image", editdata.Imageraw ? editdata.Imageraw : "");
            formData.append("firstname", editdata.firstname);
            formData.append("lastname", editdata.lastname);
            formData.append("jobtitle", editdata.jobtitle);
            formData.append("phone", editdata.phone);
            formData.append("role", 'AdminUser');
            formData.append("viewcomplaint", true);
            formData.append("gender", editdata.gender);
            formData.append("EmergencyEmail", editdata.EmergencyEmail);
        }
        if (editdata.userrole == 'User') {
            formData.append("Image", editdata.Imageraw ? editdata.Imageraw : "");
            formData.append("firstname", editdata.firstname);
            formData.append("lastname", editdata.lastname);
            formData.append("jobtitle", editdata.jobtitle);
            formData.append("phone", editdata.phone);
            formData.append("role", editRole.userRole !== '' ? editRole.userRole : editdata.role);
            formData.append("viewcomplaint", editRole.userRole !== '' && editRole.receive_complaint);
            formData.append("gender", editdata.gender);
            formData.append("EmergencyEmail", editdata.EmergencyEmail);
            formData.append("UserRole_id",editRole._id ? editRole._id: editdata.id );
        }
        for (const value of formData.values()) {
            console.log(value);
        }
      
        axios.put(process.env.REACT_APP_BASE_URL + `companies/EditCompany/${editdata.id}`, formData, { headers: headerformdata.Authorization=localStorage.getItem("token") }).then((res) => {
            getCompanydata()
            setEditmodel(false)
            setEditData({ id: "", firstname: '', lastname: '', email: '', role: '', gender: 'Male', jobtitle: '', phone: '', image: "", Imageraw: "", userrole: '' ,EmergencyEmail:''})
            enqueueSnackbar("User Updated successfully", { variant: "success" })
        }).catch((err) => console.log(err))

    }

    const handleDeleteData = (id) => {
        setDeletemodel(true)
        setDeleteid(id)
    }


    const DeleteSubmit = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `companies/DeleteUser/${deleteid}`, ).then((res) => {
            setDeletemodel(false)
            getCompanydata()
            enqueueSnackbar("User deleted successfully", { variant: "success" })
        }).catch((err) => console.log(err))
    }

    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };

    const handleChangeRole = (e) => {
        setRole(e.target.value)
    }
console.log(role,"rollllllllllllllllllllllllllllll");
    const [editRole, setEditRole] = React.useState({ userRole: '', receive_complaint: '' })
    const handleEditchangeUserRole = (e) => {
        //   console.log("Complete event:", e);
        // console.log("e.target.value:", e.target.value);
        roleDetails.map((item) => {
            if(e.target.value == item.user_role) {
                console.log('inside if')
                setEditRole({ userRole: e.target.value, receive_complaint: item.receive_complaint })
            }
        })
        
     
    }



    const getSortOrder = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `order/GetOrder/${auth.company_id}`, ).then((res) => {
            // console.log(res.data, 'roledetausadbjbjbjbjb');
            setRoleOrder({ type: res.data.order.userrole_order, column: res.data.order.userrole_name })
        }).catch((err) => console.log(err))
    }

 //console.log(editRole,'rollllllllll9999999');
 console.log(moment("2024-02-06T03:43:22.000Z").format("DD/MM/YYYY hh:mm:ss"),'timeeeeeeeeeeeeeeeeeeeeeeee123');
    return (
         

        <Box sx={{ width: '100%' }} className='p-0'>
            <MiniDrawer type={3}/>
            <Box component="section" className="contentWraper">

                <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>
                    <Toolbar
                        className='toolbar'
                    >
                        <Box className='headingBox settingUsers_heading'>
                            <Box component='div' className='DTsearch m-0' >
                                <FormControl variant="standard">
                                    <Typography component='div' className='label mr-10' >Search :</Typography>
                                    <TextField
                                        id="filled-size-small"
                                        variant="outlined"
                                        size="small"
                                        onChange={(e) => setSearch(e.target.value)}
                                        className='textfield search '
                                        placeholder='Search Here...'
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                                        }}
                                    />
                                </FormControl>
                            </Box>     
                            {Permission.UserMangement.addUserMangement &&                  
                            <Button variant="contained" className='addbtn' onClick={handleadduser}> <PlaylistAddRounded className='fz-18 mr-5' /> Add User</Button>
}
                        </Box>
                    </Toolbar>
                    <TableContainer style={{ border: '1px solid #E5EBF0', borderRadius: 2 }}>
                        <Table aria-label="customized table" className='table usertable'>
                            <TableHead className='table-head'>
                                <TableRow className='table-row usertable'>
                                    <StyledTableCell align="left" sx={{ minWidth: 130 }} className='semibold'>Profile</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ minWidth: 130 }} className='semibold'>Name</StyledTableCell>
                                    <StyledTableCell align="left" className='semibold' style={{ minWidth: 200 }}>User Role</StyledTableCell>
                                    <StyledTableCell align="left" className='semibold'>Job Title</StyledTableCell>
                                    <StyledTableCell align="left" className='semibold'>Gender</StyledTableCell>
                                    <StyledTableCell align="center" className='semibold'>Created On</StyledTableCell>
                                    <StyledTableCell align="center" className='semibold'>Last Login</StyledTableCell>
                                    <StyledTableCell align="center" className='semibold'># of Complains</StyledTableCell>
                                    <StyledTableCell align="center" className='semibold'># of Open Complains</StyledTableCell>
                                    <StyledTableCell align="center" className='semibold'>Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data && data.length > 0 ? data.map((item, i) => (
                                    <StyledTableRow className='tbody-row' key={i}>
                                        <StyledTableCell align="left" component="th" scope="row" padding="none" className='DT-row' style={{ minWidth: 100 }}>
                                            <Avatar src={process.env.REACT_APP_BASE_URL + item.Image} alt={item && item.firstname}  >{item && item.firstname && item.firstname.split('')[0][0]}</Avatar>
                                        </StyledTableCell>
                                        <StyledTableCell align="left" component="th" scope="row" padding="none" className='DT-row' style={{ minWidth: 100 }}>
                                            <Typography className='fz-14'>{item && item.firstname ? item.firstname : "-"}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className='DT-row' style={{ minWidth: 150 }}>
                                            <Typography className='fz-14'>{item.role ? item.role : item.userrole ? item.userrole : "-"}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className='DT-row' style={{ minWidth: 150 }}>
                                            <Typography className='fz-14'>{item && item.jobtitle ? item.jobtitle : '-'}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className='DT-row' style={{ minWidth: 80 }}><Typography className='fz-14'>{item && item.gender ? item.gender : '-'}</Typography></StyledTableCell>
                                        <StyledTableCell align="center" className='DT-row' style={{ minWidth: 130 }} > <Typography className='fz-14'>{moment(item.createdAt).format('DD/MM/YYYY')}</Typography> </StyledTableCell>
                                        <StyledTableCell align="center" className='DT-row' style={{ minWidth: 200 }}>
                                       
                                            <Typography className='fz-14'> {item && item.logouttime ? moment(item.logouttime).format('DD/MM/YYYY, hh:mm A') : "-"}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" className='DT-row' style={{ minWidth: 140 }}>
                                            <Typography className='fz-14'>{item && item.noofcomplains ? item.noofcomplains : "-"}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell align="center" className='DT-row' style={{ minWidth: 180 }}>
                                            <Typography className='fz-14'>{item && item.open ? item.open.length : "-"}</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell className='DT-row' align="center" >
                                            <Box className='actionbtns'>
                                                <Tooltip
                                                    title="Edit"
                                                    arrow
                                                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                                                >{Permission.UserMangement.addUserMangement ? 

                                                   <BorderColorOutlined style={{cursor:'pointer'}} pointer="curser" className='edit-icon mr-10' onClick={() => OpeneditModal(item)} />
                                                   :
                                                  <BorderColorOutlined style={{ cursor: 'pointer' }} className='edit-icon-disable mr-10' onClick={()=>console.log("hii")} />
                                                }
                                                </Tooltip>
                                            
                                                <Tooltip
                                                    title="Delete"
                                                    arrow
                                                    
                                                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                                                >{item.userrole == 'Main Admin' || !Permission.UserMangement.deleteUserMangement  ?<DeleteOutlineRounded style={{cursor:'pointer'}} className='delete-icon-disable' onClick={() => enqueueSnackbar(" can not be deleted", { variant: "error" })} />: <DeleteOutlineRounded style={{cursor:'pointer'}} className='delete-icon' onClick={() => handleDeleteData(item._id)} />}
                                                 </Tooltip>
                                            </Box>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )) : <StyledTableCell colSpan={8} className='nodata' > NO DATA FOUND </StyledTableCell>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[ 10, 25, 50]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='DT-pagination '
                    />
                    <BootstrapDialog
                        onClose={handleClosemodal}
                        aria-labelledby="customized-dialog-title"
                        open={addUserModal}
                        className='addForm-modal addusermodal'
                        id='compAddUser'
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" className='modal-title'>Add User</BootstrapDialogTitle>
                        <DialogContent dividers>
                            <Grid container rowSpacing={2}>
                                <Box className='content '>
                                    <Grid container rowSpacing={2} className="justify-content-center">
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-customized-radios"
                                                    name="gender"
                                                    onChange={handleChangeRoles}
                                                    value={selectedRole}
                                                    className='adduserOption'
                                                >
                                                {/* <FormControlLabel value="Admin" className='radio-btns ' control={<BpRadio />} label={<Typography className='fz-16'>Admin</Typography>} /> */}
                                                    <FormControlLabel value="User" className='radio-btns ' control={<BpRadio />} label={<Typography className='fz-16'>User</Typography>} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>

                                        <Grid item sm={12} md={12} lg={12}>
                                            <Box className='personal-info-tab'>
                                                <Typography component='h2' className='profile-heading'>Profile Photo </Typography>
                                                <Box className='profile-photo'>
                                                    <label htmlFor="icon-button-file">
                                                        <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={handleaddphoto} />
                                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                                            {user.Imageprew ? <Avatar src={user.Imageprew}>
                                                            </Avatar> : <Avatar src={Avatarimage} />
                                                            }
                                                            <AddAPhoto />
                                                        </IconButton>
                                                    </label>
                                                </Box>
                                                <Typography className='allow-filetypes'>
                                                    Allowed *.jpeg, *.jpg, *.png<br />max size of 2 MB
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>First Name: <Typography component='span' className='star'>*</Typography></Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='firstname'
                                                    className='textfield'
                                                    placeholder='First Name'
                                                    value={user.firstname}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                            <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Last Name: </Typography>

                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='lastname'
                                                    className='textfield'
                                                    placeholder='Last Name'
                                                    value={user.lastname}
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='email'
                                                    className='textfield'
                                                    value={user.email}
                                                    placeholder='Email'
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                            <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>
                                        </Grid>
                                   {console.log(user.role,"uuuuuuserrrrrrrr")}
                                        { selectedRole == 'User' ?
                                         <Grid item xs={12} sm={12} md={12} lg={12}>
                                         <FormControl variant="standard" fullWidth size="small">
                                             <Typography component='div' className='label'>User Role:</Typography>
                                             <Select
                                             
                                                 labelId="demo-select-small"
                                                 id="demo-select-small"
                                                 name='role'
                                                 onChange={handleChangeRole}
                                                 value={role}
                                              //   defaultValue={editdata}
                                                 variant='outlined'
                                                 className='select'
                                                 MenuProps={MenuProps}
                                                 title="Add" placement="top-start"
                                             >
                                                 {
                                                     roleDetails && roleDetails.map((item, i) => (
                                                        item.isdelete==false&&
                                                         <MenuItem value={item} className='menuitems'>{item.user_role}</MenuItem>
                                                     ))
                                                 }

                                             </Select>
                                         </FormControl>
                                     </Grid>:
                                     <Grid item xs={12} sm={12} md={12} lg={12}>
                                     <FormControl variant="standard" fullWidth size="small">
                                         <Typography component='div' className='label'>Role:</Typography>
                                         <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='role'
                                                    className='textfield'
                                                    value={'Admin'}   
                                                    disabled                                          
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                     </FormControl>
                                 </Grid>}
                                       
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Gender:</Typography>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-customized-radios"
                                                    name="gender"
                                                    onChange={handleChange}
                                                    value={user.gender}
                                                >
                                                    <FormControlLabel value="Male" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Male</Typography>} />
                                                    <FormControlLabel value="Female" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Female</Typography>} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                        
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Job Title:</Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='jobtitle'
                                                    value={user.jobtitle}
                                                    className='textfield'
                                                    placeholder='Job Title'
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>


                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Phone:</Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='phone'
                                                    onInput={(e) => onlyNumbers(e)}
                                                    inputProps={{ maxLength: 10 }}
                                                    className='textfield'
                                                    value={user.phone}
                                                    placeholder='Phone'
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                             {role.receive_complaint && 
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                           
                                            <FormControl variant="standard" fullWidth>

                                                <Typography component='div' className='label'>Emergency Email:</Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='EmergencyEmail'
                                                     value={user.EmergencyEmail}
                                                    className='textfield'
                                                    placeholder='Emergency email'
                                                    onChange={handleChange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />

                                            </FormControl>
                             
                                        </Grid>
}
                                    </Grid>
                                </Box>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus className='modalbtn' onClick={handleclosemodel} >Cancel</Button>
                            <Button autoFocus className='modalbtn' onClick={saveAdduser} >Save</Button>
                            <Button autoFocus className='modalbtn' onClick={addUserDetails} >Save + Add Users</Button>
                        </DialogActions>
                    </BootstrapDialog>

                    {/* EDIT MODEL */}
                    <BootstrapDialog
                        onClose={handleClosemodal}
                        aria-labelledby="customized-dialog-title"
                        open={editmodel}
                        className='addForm-modal'
                        id='compAddUser'
                    >
                        <BootstrapDialogTitle id="customized-dialog-title" className='modal-title'>Update</BootstrapDialogTitle>
                        <DialogContent dividers>
                            <Grid container rowSpacing={2}>
                                <Box className='content p-10'>
                                    <Grid container rowSpacing={2} className="justify-content-center">
                                        <Grid item sm={12} md={12} lg={12}>
                                            <Box className='personal-info-tab'>
                                                <Typography component='h2' className='profile-heading' >
                                                    Profile Photo
                                                </Typography>
                                                <Box className='profile-photo'>
                                                    <label htmlFor="icon-button-file">
                                                        <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={handleprofilephoto} />
                                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                                            {editdata.Imageprew ? <Avatar src={editdata.Imageprew}>
                                                            </Avatar> : editdata.image ? <React.Suspense fallback={<Spinner />}>
                                                                <Avatar src={process.env.REACT_APP_BASE_URL + editdata.image}> </Avatar>
                                                            </React.Suspense> : <Avatar src={Avatarimage} />
                                                            }
                                                            <AddAPhoto />
                                                        </IconButton>
                                                    </label>
                                                </Box>
                                                <Typography className='allow-filetypes'>
                                                    Allowed *.jpeg, *.jpg, *.png<br />max size of 2 MB
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>First Name: <Typography component='span' className='star'>*</Typography></Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='firstname'
                                                    className='textfield'
                                                    placeholder='First Name'
                                                    value={editdata.firstname}
                                                    onChange={handleEditchange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                            {error.firstname && <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Last Name: </Typography>

                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='lastname'
                                                    className='textfield'
                                                    placeholder='Last Name'
                                                    value={editdata.lastname}
                                                    onChange={handleEditchange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    disabled
                                                    className='textfield'
                                                    value={editdata.email}
                                                    placeholder='Email'

                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                            {error.email && <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>}
                                        </Grid>
                                        
                                        {
                                        editdata.userrole == 'Main Admin' || editdata.userrole == "AdminUser" ? <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth size="small">
                                                <Typography component='div' className='label'>Role:</Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='role'
                                                    value={'Main Admin'}
                                                    className='textfield'
                                                    disabled
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid> :
                                            <Grid item xs={12} sm={12} md={12} lg={12}>

                                              <FormControl variant="standard" fullWidth size="small">
                                             <Typography component='div' className='label'>User Role:</Typography>
                                             {console.log(editRole.userRole,"lkgkjgkjgjkgjkgkjgkjgjkgjkgkjgkjgjkgjk")}
                                             <Select
                                                 labelId="demo-select-small"
                                                 id="demo-select-small"
                                                 name='role'
                                                 onChange={handleEditchangeUserRole}
                                                 defaultValue={editRole.userRole != '' ? editRole.userRole : editdata.role}
                                                 variant='outlined'
                                                 className='select'
                                                 MenuProps={MenuProps}
                                                 title="Add" placement="top-start"
                                             >
                                                 {
                                                     roleDetails && roleDetails.map((item, i) => (
                                                        item.isdelete==false&&
                                                         <MenuItem value={item.user_role} className='menuitems'>{item.user_role}</MenuItem>
                                                     ))
                                                 }






                                             </Select>
                                         </FormControl>
                                        </Grid>
                                    }


                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Gender:</Typography>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-customized-radios"
                                                    name="gender"
                                                    onChange={handleEditchange}
                                                    value={editdata.gender}
                                                >
                                                    <FormControlLabel value="Male" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Male</Typography>} />
                                                    <FormControlLabel value="Female" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Female</Typography>} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Job Title:</Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='jobtitle'
                                                    value={editdata.jobtitle != 'null' ? editdata.jobtitle : ''}
                                                    className='textfield'
                                                    placeholder='Job Title'
                                                    onChange={handleEditchange}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControl variant="standard" fullWidth>
                                                <Typography component='div' className='label'>Phone:</Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='phone'                                                    
                                                    inputProps={{ maxLength: 10 }}
                                                    className='textfield'
                                                    value={editdata.phone != "null" ? editdata.phone : ''}
                                                    placeholder='Phone'
                                                    onChange={handleEditchange}
                                                    onInput={(e) => onlyNumbers(e)}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        {console.log(editRole.receive_complaint,"tttttttttttttttttttttt")}
                                        {editRole.receive_complaint && 
                                      
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                           
                                            <FormControl variant="standard" fullWidth>

                                                <Typography component='div' className='label'>Emergency Email:</Typography>
                                                <TextField
                                                    id="filled-size-small"
                                                    variant="outlined"
                                                    size="small"
                                                    name='EmergencyEmail'
                                                    
                                                     value={editdata.EmergencyEmail != 'null' ? editdata.EmergencyEmail : ''}
                                                     onChange={handleEditchange}
                                                    className='textfield'
                                                    placeholder='Emergency email'
                                               
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                    }}
                                                />

                                            </FormControl>
                             
                                        </Grid>
}
                                    </Grid>
                                </Box>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button className='modalbtn' onClick={handleclosemodel} >Cancel</Button>
                            <Button className='modalbtn' onClick={submitEdit} >Update</Button>
                        </DialogActions>
                    </BootstrapDialog>

                    {/* DELETE MODEL */}
                    <BootstrapDialog
                        onClose={() => setDeletemodel(false)}
                        aria-labelledby="customized-dialog-title"
                        open={deletemodel}
                        className='addForm-modal'
                    >
                        <DialogContent className='deleteConfirmModal'>
                            <HighlightOffRounded className='delIcon' />
                            <Typography component='div' className='text'>Are you sure want to delete?</Typography>
                        </DialogContent>
                        <DialogActions className='deleteModalactbtns'>
                            <Button autoFocus onClick={() => setDeletemodel(false)} className='modalbtn cancel'>
                                Cancel</Button>
                            <Button autoFocus onClick={DeleteSubmit} className='modalbtn delete'>
                                Delete</Button>
                        </DialogActions>
                    </BootstrapDialog>
                </Paper>
            </Box>
        </Box>
    );
}