import React from 'react'
import MiniDrawer from '../../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, TextField, FormControlLabel, RadioGroup, Radio, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SearchOutlined } from '@mui/icons-material';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { header, headerformdata } from "../../../../components/axiousheader"


function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#091865',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});


export default function AddUser() {
    const { enqueueSnackbar } = useSnackbar()
	let navigate = useNavigate()
    let location = useLocation()
    const auth = JSON.parse(localStorage.getItem("userData"))
    const [user, setUser] = React.useState({ firstname: '', lastname: '', email: '', gender: 'Male', role: 'User', jobtitle: '', phone: '' });
    const [error, setError] = React.useState({ firstname: "", email: "" })
    let username, uservalue;
    const handleChange = (e) => {
        username = e.target.name;
        uservalue = e.target.value;
        console.log(username , '==>',uservalue);
        setUser({ ...user, [username]: uservalue })
        setError({ ...error, [username]: '' })
    };
    console.log(auth,'auth');
    const validation = () => {
        if (!user.firstname) {
            setError({ firstname: "First name can not be empty" })
            return false
        }
        else if (!user.email) {
            setError({ email: "Email cannot be empty" })
            return false
        }
        else if (user.email) {
            const pattern = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")
            var valid = pattern.test(user.email)
            if (!valid) {
                setError({ email: "Please enter valid Email" })
                return false
            }
        }
        return true
    }

    const addUserDetails = () => {
        const validator = validation()
        if (validator == true) {
            var userDetails = {
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                gender:user.gender,
                role: user.role,
                jobtitle: user.jobtitle,
                phone: user.phone,
                userrole: 'User',
                roleid: 3,
                company_id: auth.company_id,
                companyname: auth.companyname
            }
            navigate("/CompanyDetail", {state:userDetails.company_id})
            
            // axios.post(process.env.REACT_APP_BASE_URL + `companies/addUser`, userDetails).then((res) => {
            //     setUser({ firstname: '', lastname: '', email: '', role: '', jobtitle: '', phone: '' });
            //     enqueueSnackbar("User added successfully", { variant: 'success' })
            //     navigate("/CompanyDetail", {state:userDetails.company_id})
            // }).catch((err) => {
            //     console.log(err)
            //     enqueueSnackbar(err.response.data.message, { variant: 'error' })
            // })
        }
    }

    return (
        <Box className='Addcompany'>
            <MiniDrawer />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add User</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>First Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='firstname'
                                                className='textfield'
                                                placeholder='First Name'
                                                value={user.firstname}
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Last Name: </Typography>

                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='lastname'
                                                className='textfield'
                                                placeholder='Last Name'
                                                value={user.lastname}
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='email'
                                                className='textfield'
                                                value={user.email}
                                                placeholder='Email'
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth size="small">
                                            <Typography component='div' className='label'>User Role:</Typography>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                name='role'
                                                onChange={handleChange}
                                                value={user.role}
                                                variant='outlined'
                                                className='select'
                                            >
                                                <Box className='p-10'>
                                                    <FormControl variant="standard" fullWidth>
                                                        <TextField
                                                            id="filled-size-small"
                                                            variant="outlined"
                                                            size="small"
                                                            className='textfield search '
                                                            placeholder='Search Here...'
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end"><SearchOutlined className='fz-18' /></InputAdornment>,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Box>
                                                <MenuItem value={20} className='menuitems'>Government</MenuItem>
                                                <MenuItem value={30} className='menuitems'>Law</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} sm={3} md={3} lg={3} className='radiobtnGrid'>
                                        <Typography component='div' className='label '>Gender:</Typography>

                                    </Grid>
                                    <Grid item xs={8} sm={9} md={9} lg={9}>
                                        <Box className=" ">
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-customized-radios"
                                                name="gender"
                                                onChange={handleChange}
                                                value={user.gender}
                                            >
                                                <FormControlLabel value="Male" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Male</Typography>} />
                                                <FormControlLabel value="Female" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Female</Typography>} />
                                            </RadioGroup>
                                        </Box>
                                    </Grid>                                   

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Job Title:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='jobtitle'
                                                value={user.jobtitle}
                                                className='textfield'
                                                placeholder='Job Title'
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Phone:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='phone'
                                                className='textfield'
                                                value={user.phone}
                                                placeholder='Phone'
                                                onChange={handleChange}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    {/* <Button variant="contained" className='addbtn mr-10' onClick={addUserDetails}>Save</Button> */}
                                    <Button variant="contained" className='addbtn' onClick={addUserDetails}> Save + Add Users</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}
